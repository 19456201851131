import React, { useContext, useEffect, useState } from 'react'
import { PiAlignLeftFill, PiAlignCenterHorizontalFill, PiAlignRightFill, PiAlignTopFill, PiAlignCenterVerticalFill, PiAlignBottomFill } from 'react-icons/pi'
import { useRecoilValue } from 'recoil';
import CanvasContext from '../../../../context/CanvasContext';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import { classesToObj } from '../../../../helpers/helpers';
import classNames from 'classnames';

const Alignment = () => {
  // selectedState is the recoil state of the selected comp on the Canvas
  // selectedCompStyle is the computed style of the same selected comp after render through window.getComputedStyle(compRef.current)
  const {pageState, selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const state = useRecoilValue(selectedState);
  const [rowAlignment, setRowAlignment] = useState(null);
  const [colAlignment, setColAlignment] = useState(null);

  const tailwindJustifyClasses = [
    "justify-normal", "justify-start", "justify-end", "justify-center", "justify-between",
    "justify-content", "justify-around", "justify-evenly", "justify-stretch"
  ];
  const tailwindItemsClasses = [
    "items-start", "items-end", "items-center", "items-baseline"
  ];
  const tailwindJustifyClassesResetObj = tailwindJustifyClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});
  const tailwindItemsClassesResetObj = tailwindItemsClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{
    if (selectedState == pageState) {
      setColAlignment(null);
      setRowAlignment(null);
      return;
    }
    if ((selectedCompStyle?.display=='flex' || selectedCompStyle?.flex=='inline-flex')) {
      if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.justifyContent=='flex-start') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.alignItems=='flex-start') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.alignItems=='normal') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.justifyContent=='flex-end') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.alignItems=='flex-start') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.alignItems=='normal')
      ) {
        setRowAlignment('left');
      } else if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.justifyContent=='center') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.alignItems=='center') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.justifyContent=='center') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.alignItems=='center')
      ) {
        setRowAlignment('row-center')
      } else if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.justifyContent=='flex-end') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.alignItems=='flex-end') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.justifyContent=='flex-start') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.alignItems=='flex-end')
      ) {
        setRowAlignment('right')
      }
      
      if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.alignItems=='normal') ||
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.alignItems=='flex-start') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.justifyContent=='flex-start') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.alignItems=='normal') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.alignItems=='flex-start') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.justifyContent=='flex-end')
      ) {
        setColAlignment('top')
      } else if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.alignItems=='center') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.justifyContent=='center') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.alignItems=='center') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.justifyContent=='center')
      ) {
        setColAlignment('col-center')
      } else if (
        (selectedCompStyle.flexDirection=='row' && selectedCompStyle.alignItems=='flex-end') ||
        (selectedCompStyle.flexDirection=='column' && selectedCompStyle.justifyContent=='flex-end') ||
        (selectedCompStyle.flexDirection=='row-reverse' && selectedCompStyle.alignItems=='flex-end') ||
        (selectedCompStyle.flexDirection=='column-reverse' && selectedCompStyle.justifyContent=='flex-start')
      ) {
        setColAlignment('bottom')
       }
    } else {
      setRowAlignment(null);
      setColAlignment(null);
    }
  },[selectedCompStyle])

  const updateCompAlignment = (align) => {
    setState((prevState) => {
      let classObj = {...classesToObj(prevState.className)};
      if (!classObj['flex']) {
        classObj['flex'] = true;
        classObj['flex-col'] = true;
      }

      if (align == 'left') {
        if (selectedCompStyle.flexDirection=='row') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-start':true}
        }
        if (selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-end':true}
        }
        if (selectedCompStyle.flexDirection=='column' || selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-start':true}
        }
      } else if (align == 'row-center') {
        if (selectedCompStyle.flexDirection=='row' || selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-center':true}
        }
        if (selectedCompStyle.flexDirection=='column' || selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-center':true}
        }
      } else if (align == 'right') {
        if (selectedCompStyle.flexDirection=='row') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-end':true}
        }
        if (selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-start':true}
        }
        if (selectedCompStyle.flexDirection=='column' || selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-end':true}
        }
      } else if (align == 'top') {
        if (selectedCompStyle.flexDirection=='row'||selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-start':true}
        }
        if (selectedCompStyle.flexDirection=='column') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-start':true}
        }
        if (selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-end':true}
        }
      } else if (align == 'col-center') {
        if (selectedCompStyle.flexDirection=='row' || selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-center':true}
        }
        if (selectedCompStyle.flexDirection=='column' || selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-center':true}
        }
      } else {
        if (selectedCompStyle.flexDirection=='row'||selectedCompStyle.flexDirection=='row-reverse') {
          classObj = {...classObj, ...tailwindItemsClassesResetObj, 'items-end':true}
        }
        if (selectedCompStyle.flexDirection=='column') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-end':true}
        }
        if (selectedCompStyle.flexDirection=='column-reverse') {
          classObj = {...classObj, ...tailwindJustifyClassesResetObj, 'justify-start':true}
        }
        
      }
      if (align === 'left' || align == 'row-center' || align == 'right') {
        setRowAlignment(align);
      } else {
        setColAlignment(align);
      }
      return {...prevState, className: classNames(classObj)}
    });

    

    
  }

  return (
    <div className='flex flex-col gap-3 tracking-wider border-b border-zinc-800 pb-5' title='alignment properties'>
      <p className='text-zinc-200 font-semibold text-xs'>Alignment</p>
      <div className="flex gap-2 text-stone-400 ">
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(rowAlignment == 'left')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('left')}} title='align left'><PiAlignLeftFill className='w-5 h-5 '/></button>
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(rowAlignment == 'row-center')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('row-center')}} title='align row-center'><PiAlignCenterHorizontalFill className='w-5 h-5'/></button>
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(rowAlignment == 'right')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('right')}} title='align right'><PiAlignRightFill className='w-5 h-5'/></button>
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(colAlignment == 'top')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('top')}} title='align top'><PiAlignTopFill className='w-5 h-5'/></button>
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(colAlignment == 'col-center')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('col-center')}} title='align col-center'><PiAlignCenterVerticalFill className='w-5 h-5'/></button>
        <button disabled={selectedState==pageState} className={'disabled:text-zinc-600 p-1'+`${(colAlignment == 'bottom')? ' text-lime-500':''}`} onClick={()=>{updateCompAlignment('bottom')}} title='align bottom'><PiAlignBottomFill className='w-5 h-5'/></button>
      </div>
    </div>
  )
}

export default Alignment