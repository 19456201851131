import { useContext, useEffect} from 'react';
import CanvasContext from '../context/CanvasContext';
import useRecoilStateToValues from './useRecoilStateToValues';

const useCopy = () => {
  const {setCopiedStateJson, pageState, selectedState} = useContext(CanvasContext);
  const recoilStateToJsonObj = useRecoilStateToValues();

  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
      // event.preventDefault();
      if (selectedState?.key !== pageState?.key) {
        setCopiedStateJson(recoilStateToJsonObj(selectedState))
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedState]); // this dependency array ensures the handleKeyDown is updated with correct `setState` at every change of state
};

export default useCopy;