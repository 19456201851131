import React, { useRef, useState } from 'react'
import { MdOpenInNew } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { IoIosPlay } from 'react-icons/io';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { BASE_URL } from '../../../api/axios';
import { BiLoaderAlt } from 'react-icons/bi';
import { LuCopyPlus } from 'react-icons/lu';
import { IoEyeSharp } from "react-icons/io5";



const TemplateTile = ({site}) => {

  const [tileMenuAppear, setTileMenuAppear] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const nameRef = useRef(null);

  const [isCreating, setIsCreating] = useState(false);
  const create = async (websiteId) => {
    setIsCreating(true);
    try {
      const resp = await axiosPrivate.post("/website/template", {"websiteId":websiteId});
      if (resp.status == 201) {
        const newPage = resp.data;
        setIsCreating(false);
        navigate(`/workspace/${newPage._id}`);
      } else {
        throw Error("Unexpected response from server");
      }
    } catch(err) {
      // setPagesError(err.mesaage);
      console.log(err);
    } finally {
      setIsCreating(false);
    }
  }
  
  return (
    <>
      {site && 
        <div className={`flex flex-col gap-3 group md:w-96 w-full`} title='template'>
          <div className="overflow-hidden bg-zinc-700 bg-opacity-50 group-hover:bg-opacity-60 border border-zinc-700 group-hover:border-zinc-600 duration-200 rounded-lg w-full h-48 md:h-60 text-3xl text-white relative flex justify-center pt-8">
            {site.publishedTheme.thumbnail?
              <div className='w-52 md:w-80 h-auto overflow-hidden group-hover:scale-105 group-hover:translate-y-[0px] duration-200'>
                <img src={site.publishedTheme.thumbnail} className='w-full h-auto object-cover'/>
              </div>
              :
              <div className='w-52 md:w-80 h-32 md:h-40 bg-white group-hover:scale-105 duration-200'></div>
            }

            <div className={`opacity-0 group-hover:opacity-100 flex transition-opacity duration-200 flex-col justify-center rounded-b-xl gap-4 absolute bottom-0 bg-zinc-700 bg-opacity-70 h-full px-6 w-full text-sm text-zinc-200 text-left`}>
              {/* <span className='text-zinc-100 font-semibold'>Use this template?</span> */}
              <div className='flex flex-col w-full gap-4 items-center'>
                <button className={`w-40 py-2 bg-lime-600  text-white flex-1 rounded-full flex justify-center items-center font-semibold hover:scale-105 duration-200 ${isCreating && 'disabled'}`} onClick={()=>{create(site._id)}}>
                  {isCreating? 
                    <BiLoaderAlt className='w-4 h-4 animate-spin'/>
                    :
                    <div className='flex justify-center items-center gap-2'><LuCopyPlus className='w-4 h-auto '/> Create</div>
                  }
                </button>
                <a className='w-40 py-2 bg-zinc-100 border border-lime-600 text-lime-600 flex-1 rounded-full flex justify-center font-semibold hover:scale-105 duration-200' href={`${process.env.REACT_APP_SITE_PUBLISH_URL.replace("://", `://${site.subdomain}.`)}`} target='_blank'>
                <div className='flex justify-center items-center gap-2'><IoEyeSharp className='w-4 h-auto '/> Preview</div>
                </a>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-0 w-full'>
            <div className="text-md text-zinc-200 font-semibold text-left">
              {site.name}
            </div>
            {/* <div className="text-sm text-zinc-500 font-secondary text-left">
              Used {site.templateUsedCount} times
            </div> */}
          </div>
        </div>
      }
    </>
    
    
  )
}

export default TemplateTile