import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { FaAngleDown } from "react-icons/fa6";
import classNames from 'classnames';

import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import DialogBox from './DialogBox';
import { classesToObj } from '../../../../helpers/helpers';
import { useRecoilValue } from 'recoil';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';


const Height = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [heightType, setHeightType] = useState('');
  const [height, setHeight] = useState('');
  const [tailwindDropdown, setTailwindDropdown] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const [customType, setCustomType] = useState('fixed');
  const [customHeight, setCustomHeight] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  
  
  const tailwindHeightClasses = [
    "h-auto", "h-full", "h-screen", "h-min", "h-max", "h-fit", "h-0",
    "h-px", "h-0.5", "h-1", "h-1.5", "h-2", "h-2.5", "h-3", "h-3.5",
    "h-4", "h-5", "h-6", "h-7", "h-8", "h-9", "h-10", "h-11", "h-12", "h-14",
    "h-16", "h-20", "h-24", "h-28", "h-32", "h-36", "h-40", "h-44", "h-48",
    "h-52", "h-56", "h-60", "h-64", "h-72", "h-80", "h-96",  "h-1/2",
    "h-1/3", "h-2/3", "h-1/4", "h-2/4", "h-3/4", "h-1/5", "h-2/5", "h-3/5",
    "h-4/5", "h-1/6", "h-2/6", "h-3/6", "h-4/6", "h-5/6", 
    "h-svh", "h-lvh", "h-dvh"
  ];
  const tailwindHeightClassesResetObj = tailwindHeightClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{
    const customHeight = state.style?.height;
    if (parseInt(customHeight) >= 0) {
      setHeightType('custom');
      if (customHeight.endsWith("%")) {
        setCustomType('rel');
      } else {
        setCustomType('fixed');
      }
      setCustomHeight(parseInt(customHeight));
    } else {
      const classNameObj = classesToObj(state.className)
      let found = '';
      tailwindHeightClasses.forEach((cls)=> {
        if (classNameObj[cls]) {
          found=cls;
        }
      })
      if (found) {
        setHeightType('tailwind');
        setHeight(found);
      } else {
        setHeightType('');
        setHeight('');
      }
      if (parseInt(selectedCompStyle.height) >=0) {
        setCustomHeight(parseInt(selectedCompStyle.height));
      }
      // ignoring w-[value]
    }
  },[state, selectedCompStyle])

  const updateState =  (height, heightType)=>{
    if (heightType === 'tailwind') {
      setState((prevState) => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bh-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindHeightClassesResetObj};
        classObj[height] = true;
        return {...prevState, className: classNames(classObj), style:{...prevState.style, height:undefined}}
      });
    } else if (heightType === 'custom') {
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bh-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindHeightClassesResetObj}; // removing all other height classes
        return {...prevState, style: {...prevState.style, height: `${height}${customType==='rel'? '%':'px'}`}, className: classNames(classObj)}
      })
    } else { // remove height setting
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bh-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindHeightClassesResetObj}; // removing all other height classes
        return {...prevState, style: {...prevState.style, height: undefined}, className: classNames(classObj)};
      })
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>Height</p>
      <div className="col-span-2 flex flex-col gap-0 relative h-8">
        <button 
          className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full pl-3 pr-2"
          onClick={(e)=>{setTailwindDropdown(!tailwindDropdown)}}
        >
          <span className='h-8 flex items-center flex-grow text-left'>
            {heightType === '' && <span className="text-zinc-500">Select</span>}
            {heightType !== '' &&
            <div className='flex items-center justify-between pr-1 gap-1 w-full group'>
              <span>{heightType==='tailwind'? height : 'custom'}</span>
              <RxCross2 className='w-3 h-3 rounded-full bg-zinc-600 hidden group-hover:block' onClick={(e)=>{e.stopPropagation(); setHeightType('');setHeight('');setCustomHeight(0);updateState(0, '')}}/>
            </div> 
            }
          </span>
          <FaAngleDown className='w-3 h-3'/>
        </button>
        <DialogBox appear={tailwindDropdown} setAppear={setTailwindDropdown}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center border border-zinc-800'>
            <button onClick={()=>{setHeightType('custom');setTailwindDropdown(false)}} className={`hover:bg-zinc-700 ${height == 'custom' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 `}>
              custom
            </button>
            {
              tailwindHeightClasses.map(heightCls => 
                <button
                  key={heightCls}
                  className={`hover:bg-zinc-700 ${height == heightCls && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2`}
                  onClick={()=>{updateState(heightCls, 'tailwind')}}
                >
                  {heightCls}
                </button>
              )
            }
          </div>
        </DialogBox>
      </div>
      {heightType === 'custom' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className="h-8 flex flex-col text-white propUnitBox w-1/2">
              <button 
                className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full px-1"
                onClick={(e)=>{setCustomDropdown(!customDropdown)}}
              >
                <span className='h-8 flex items-center flex-grow text-left pl-1 capitalize'>
                  {customType}
                </span>
                <FaAngleDown className='w-3 h-3'/>
              </button>
              <DialogBox appear={customDropdown} setAppear={setCustomDropdown}>
                <div className='absolute bg-zinc-900 text-zinc-400 text-sm py-0 mt-1 max-h-32 overflow-y-auto rounded-md flex flex-col items-center z-50 border border-zinc-800'>
                  <button onClick={()=>{setCustomType('fixed'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'fixed' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    fixed
                  </button>
                  <button onClick={()=>{setCustomType('rel'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'rel' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    rel
                  </button>
                </div>
              </DialogBox>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox w-1/2 pl-1"+`${isFocused? ' border border-lime-600' : ''}`}>
              <input type="text" className='w-3/4 bg-transparent outline-none h-3 px-1 text-xs tracking-widest text-zinc-400'
                value={customHeight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateState(parseInt(e.target.value), 'custom');
                  } else {
                    updateState(0, 'custom');
                  }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateState(customHeight+1,'custom')}} />
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (customHeight > 0) updateState(customHeight-1,'custom')}} />
              </div>
            </div>
          </div>
        </> 
      }
      
    </>
  )
}

export default Height