import React from 'react';
import useDocTitle from '../hooks/useDocTitle';

const MissingPage = () => {
  useDocTitle('404 Not Found!');
  return (
    <div className='text-zinc-300 flex min-h-screen w-screen justify-center items-center'>
      <div>Page not found</div>
    </div>
  );
}

export default MissingPage;