import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { TbBorderSides } from "react-icons/tb";
import { FaRegSquare } from"react-icons/fa6";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';


const Padding = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const [type, setType] = useState('all');
  const [padding, setPadding] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedT, setIsFocusedT] = useState(false);
  const [isFocusedR, setIsFocusedR] = useState(false);
  const [isFocusedB, setIsFocusedB] = useState(false);
  const [isFocusedL, setIsFocusedL] = useState(false);
  
  useEffect(()=>{
    if (selectedCompStyle?.paddingTop) {
      setPaddingTop(parseInt(selectedCompStyle.paddingTop));
    } else {
      setPaddingTop(0);
    }
    if (selectedCompStyle?.paddingRight) {
      setPaddingRight(parseInt(selectedCompStyle.paddingRight));
    } else {
      setPaddingRight(0);
    }
    if (selectedCompStyle?.paddingBottom) {
      setPaddingBottom(parseInt(selectedCompStyle.paddingBottom));
    } else {
      setPaddingBottom(0);
    }
    if (selectedCompStyle?.paddingLeft) {
      setPaddingLeft(parseInt(selectedCompStyle.paddingLeft));
    } else {
      setPaddingLeft(0);
    }
    if (
      selectedCompStyle.paddingTop == selectedCompStyle.paddingRight
      && selectedCompStyle.paddingBottom == selectedCompStyle.paddingLeft
      && selectedCompStyle.paddingBottom == selectedCompStyle.paddingTop
    ) {
      setType('all');
      if (selectedCompStyle?.paddingTop) {
        setPadding(parseInt(selectedCompStyle.paddingTop));
      } else {
        setPadding(0);
      }
    } else {
      setPadding(0);
      setType('ind');
    }
  },[selectedCompStyle])

  const updateCompPadding = (params) => {
    if (!selectedState) {
      return;
    }
    const {p, pt, pr, pb, pl} = params;
    if (p || p===0) {
      setPadding(p);
      setPaddingTop(p);
      setPaddingRight(p);
      setPaddingBottom(p);
      setPaddingLeft(p);
      setState((prevState) => ({...prevState, style: {...prevState.style, paddingTop: p+"px", paddingRight: p+"px", paddingBottom: p+"px", paddingLeft: p+"px"}}));
    } else if (pt || pt===0) {
      setPaddingTop(pt);
      setState((prevState) => ({...prevState, style: {...prevState.style, paddingTop: pt+"px"}}));
    } else if (pr || pr===0) {
      setPaddingRight(pr);
      setState((prevState) => ({...prevState, style: {...prevState.style, paddingRight: pr+"px"}}));
    } else if (pb || pb===0) {
      setPaddingBottom(pb);
      setState((prevState) => ({...prevState, style: {...prevState.style, paddingBottom: pb+"px"}}));
    } else if (pl || pl===0) {
      setPaddingLeft(pl);
      setState((prevState) => ({...prevState, style: {...prevState.style, paddingLeft: pl+"px"}}));
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16'>Padding</p>
      <div className="col-span-2 flex h-8 gap-3">
        <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
          <button onClick={()=>{setType('all')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='all'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-400 hover:scale-110'/>
          </button>
          <button onClick={()=>{setType('ind')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='ind'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <TbBorderSides className='w-4 h-4 text-zinc-400 hover:scale-110'/>
          </button>
        </div>
      </div>
      
      { type=='all' &&
        <>
          <div></div>
          <div className={"col-span-2 flex items-center gap-1 text-white propUnitBox pl-2 w-full"+`${isFocused? ' border border-lime-600' : ''}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-500'/>
            <input type="text" className='bg-transparent outline-none h-3 w-[70%] px-2 text-xs tracking-widest text-zinc-400'
              value={padding}
              onChange={(e)=>{
                if(e.target.value) {
                  updateCompPadding({p:parseInt(e.target.value)});
                } else {
                  updateCompPadding({p:0});
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}  
            />
            <div className='flex flex-col gap-0 justify-center items-center h-5 px-1'>
              <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompPadding({p:padding+1});}}/>
              <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (padding>0) updateCompPadding({p:padding-1});}}/>
            </div>
          </div>
        </>
      }
      { type=='ind' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedT? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">T</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={paddingTop}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompPadding({pt:parseInt(e.target.value)});
                  } else {
                    updateCompPadding({pt:0});
                  }
                }}
                onFocus={() => setIsFocusedT(true)}
                onBlur={() => setIsFocusedT(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompPadding({pt:paddingTop+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if(paddingTop>0) updateCompPadding({pt:paddingTop-1})}}/>
              </div>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedR? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">R</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={paddingRight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompPadding({pr:parseInt(e.target.value)});
                  } else {
                    updateCompPadding({pr:0});
                  }
                }}
                onFocus={() => setIsFocusedR(true)}
                onBlur={() => setIsFocusedR(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompPadding({pr:paddingRight+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (paddingRight > 0) updateCompPadding({pr:paddingRight-1})}}/>
              </div>
            </div>
          </div>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedB? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">B</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={paddingBottom}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompPadding({pb:parseInt(e.target.value)});
                  } else {
                    updateCompPadding({pb:0});
                  }
                }}
                onFocus={() => setIsFocusedB(true)}
                onBlur={() => setIsFocusedB(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompPadding({pb:paddingBottom+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (paddingBottom > 0) updateCompPadding({pb:paddingBottom-1})}}/>
              </div>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedL? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">L</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={paddingLeft}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompPadding({pl:parseInt(e.target.value)});
                  } else {
                    updateCompPadding({pl:0});
                  }
                }}
                onFocus={() => setIsFocusedL(true)}
                onBlur={() => setIsFocusedL(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompPadding({pl:paddingLeft+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (paddingLeft > 0) updateCompPadding({pl:paddingLeft-1})}}/>
              </div>
            </div>
          </div>
        </>
        
      }

    </>
  )
}

export default Padding