import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { TbBorderSides } from "react-icons/tb";
import { FaRegSquare } from"react-icons/fa6";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { AiOutlineRadiusUpleft, AiOutlineRadiusUpright, AiOutlineRadiusBottomright, AiOutlineRadiusBottomleft } from "react-icons/ai";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const Radius = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const [type, setType] = useState('all');
  const [radius, setRadius] = useState(0);
  const [radiusTopLeft, setRadiusTopLeft] = useState(0);
  const [radiusTopRight, setRadiusTopRight] = useState(0);
  const [radiusBottomRight, setRadiusBottomRight] = useState(0);
  const [radiusBottomLeft, setRadiusBottomLeft] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedTL, setIsFocusedTL] = useState(false);
  const [isFocusedTR, setIsFocusedTR] = useState(false);
  const [isFocusedBL, setIsFocusedBL] = useState(false);
  const [isFocusedBR, setIsFocusedBR] = useState(false);
  
  useEffect(()=>{
    if (selectedCompStyle?.borderTopLeftRadius) {
      setRadiusTopLeft(parseInt(selectedCompStyle.borderTopLeftRadius));
    } else {
      setRadiusTopLeft(0);
    }
    if (selectedCompStyle?.borderTopRightRadius) {
      setRadiusTopRight(parseInt(selectedCompStyle.borderTopRightRadius));
    } else {
      setRadiusTopRight(0);
    }
    if (selectedCompStyle?.borderBottomRightRadius) {
      setRadiusBottomRight(parseInt(selectedCompStyle.borderBottomRightRadius));
    } else {
      setRadiusBottomRight(0);
    }
    if (selectedCompStyle?.borderBottomLeftRadius) {
      setRadiusBottomLeft(parseInt(selectedCompStyle.borderBottomLeftRadius));
    } else {
      setRadiusBottomLeft(0);
    }
    if (
      selectedCompStyle.borderTopLeftRadius == selectedCompStyle.borderTopRightRadius
      && selectedCompStyle.borderBottomRightRadius == selectedCompStyle.borderBottomLeftRadius
      && selectedCompStyle.borderTopRightRadius == selectedCompStyle.borderBottomRightRadius
    ) {
      setType('all');
      if (selectedCompStyle?.borderTopLeftRadius) {
        setRadius(parseInt(selectedCompStyle.borderTopLeftRadius));
      } else {
        setRadius(0);
      }
    } else {
      setRadius(0);
      setType('ind');
    }
  },[selectedCompStyle])

  const updateCompRadius = (params) => {
    if (!selectedState) {
      return;
    }
    const {r, rtl, rtr, rbr, rbl} = params;
    if (r || r===0) {
      setRadius(r);
      setRadiusTopLeft(r);
      setRadiusTopRight(r);
      setRadiusBottomRight(r);
      setRadiusBottomLeft(r);
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopLeftRadius: r+"px", borderTopRightRadius: r+"px", borderBottomRightRadius: r+"px", borderBottomLeftRadius: r+"px"}}));
    } else if (rtl || rtl===0) {
      setRadiusTopLeft(rtl);
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopLeftRadius: rtl+"px"}}));
    } else if (rtr || rtr===0) {
      setRadiusTopRight(rtr);
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopRightRadius: rtr+"px"}}));
    } else if (rbr || rbr===0) {
      setRadiusBottomRight(rbr);
      setState((prevState) => ({...prevState, style: {...prevState.style, borderBottomRightRadius: rbr+"px"}}));
    } else if (rbl) {
      setRadiusBottomLeft(rbl || rbl===0);
      setState((prevState) => ({...prevState, style: {...prevState.style, borderBottomLeftRadius: rbl+"px"}}));
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16'>Radius</p>
      <div className="col-span-2 flex h-8 gap-3">
        <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
          <button onClick={()=>{setType('all')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='all'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-400 hover:scale-110'/>
          </button>
          <button onClick={()=>{setType('ind')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='ind'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <TbBorderSides className='w-4 h-4 text-zinc-400 hover:scale-110'/>
          </button>
        </div>
      </div>
      
      { type=='all' &&
        <>
          <div></div>
          <div className={"col-span-2 flex items-center gap-1 text-white propUnitBox pl-2 w-full"+`${isFocused? ' border border-lime-600' : ''}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-500'/>
            <input type="text" className='bg-transparent outline-none h-3 w-[70%] px-2 text-xs tracking-widest text-zinc-400'
              value={radius}
              onChange={(e)=>{
                if(e.target.value) {
                  updateCompRadius({r:parseInt(e.target.value)});
                } else {
                  updateCompRadius({r:0});
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}  
            />
            <div className='flex flex-col gap-0 justify-center items-center h-5 px-1'>
              <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompRadius({r:radius+1});}}/>
              <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (radius>0) updateCompRadius({r:radius-1});}}/>
            </div>
          </div>
        </>
      }
      { type=='ind' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedTL? ' border border-lime-600' : ''}`}>
              <AiOutlineRadiusUpleft className='w-4 h-4'/>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={radiusTopLeft}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompRadius({rtl:parseInt(e.target.value)});
                  } else {
                    updateCompRadius({rtl:0});
                  }
                }}  
                onFocus={() => setIsFocusedTL(true)}
                onBlur={() => setIsFocusedTL(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompRadius({rtl:radiusTopLeft+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if(radiusTopLeft>0) updateCompRadius({rtl:radiusTopLeft-1})}}/>
              </div>
            </div>
            <div className={"flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedTR? ' border border-lime-600' : ''}`}>
              <AiOutlineRadiusUpright className='w-4 h-4'/>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={radiusTopRight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompRadius({rtr:parseInt(e.target.value)});
                  } else {
                    updateCompRadius({rtr:0});
                  }
                }}
                onFocus={() => setIsFocusedTR(true)}
                onBlur={() => setIsFocusedTR(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompRadius({rtr:radiusTopRight+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (radiusTopRight > 0) updateCompRadius({rtr:radiusTopRight-1})}}/>
              </div>
            </div>
          </div>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedBL? ' border border-lime-600' : ''}`}>
              <AiOutlineRadiusBottomleft className='w-4 h-4'/>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={radiusBottomLeft}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompRadius({rbl:parseInt(e.target.value)});
                  } else {
                    updateCompRadius({rbl:0});
                  }
                }}
                onFocus={() => setIsFocusedBL(true)}
                onBlur={() => setIsFocusedBL(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompRadius({rbl:radiusBottomLeft+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (radiusBottomLeft > 0) updateCompRadius({rbl:radiusBottomLeft-1})}}/>
              </div>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedBR? ' border border-lime-600' : ''}`}>
              <AiOutlineRadiusBottomright className='w-4 h-4'/>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={radiusBottomRight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompRadius({rbr:parseInt(e.target.value)});
                  } else {
                    updateCompRadius({rbr:0});
                  }
                }}
                onFocus={() => setIsFocusedBR(true)}
                onBlur={() => setIsFocusedBR(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompRadius({rbr:radiusBottomRight+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (radiusBottomRight > 0) updateCompRadius({rbr:radiusBottomRight-1})}}/>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Radius