import React, { useContext } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { IoIosPlay } from "react-icons/io";
import CanvasContext from '../../../../context/CanvasContext'
import UserAvatarMenu from '../comps/UserAvatarMenu';
import PublishButton from '../comps/PublishButton';
import { SiStackblitz } from "react-icons/si";


const AppHeader = () => {
  const {aiMode, setAiMode, siteRecord} =  useContext(CanvasContext);
  const [searchParams] = useSearchParams();
  return (
    <div className="fixed top-0 w-[512px] z-30 select-none">
      <div className="flex p-4 justify-between items-center bg-black h-14 border-b border-r border-zinc-800">
        <div className='flex gap-8'>
          <Link to="/" className='flex items-center justify-center relative'>
            <img src="/icon.webp" className='h-8'/>
            {/* <div className='absolute top-[-3px] left-[70%] bg-white text-lime-500 text-[8px] font-semibold px-1 rounded-md'>beta</div> */}
          </Link>      
        </div>
        
        <div className="flex gap-3 items-center relative">
          <div
            className={"flex text-zinc-200 gap-2 bg-zinc-900 hover:bg-zinc-800 duration-200 rounded-full p-1 w-[78px] h-8 border cursor-pointer " + (aiMode? "border-lime-600" : "border-zinc-800")}
            onClick={()=>{setAiMode(!aiMode)}}
          >
            {aiMode &&
              <>
                <div className=' flex-grow text-zinc-300 text-xs flex justify-center items-center gap-3 font-bold'> 
                  AI
                </div>
                <div className='opacity-0'>s</div>
              </>
              
            }
            <div className={'absolute top-1 text-zinc-200 font-semibold py-1 w-6 h-6 rounded-full text-xs flex justify-center items-center transition-all duration-200  ' + (aiMode? "bg-lime-600 translate-x-11":"translate-x-0 bg-zinc-600")}>
              <SiStackblitz className='w-4 h-4 text-white'/>
            </div>
            {!aiMode &&
              <>
              <div className='opacity-0'>s</div>
              <div className=' flex-grow text-zinc-300 text-xs flex justify-center items-center gap-3 font-bold'> 
                Self
              </div>
              </>
            }
          </div>
          <div className='flex flex-col relative'>
            <UserAvatarMenu/>
          </div>
          
          <a href={`/preview/${siteRecord._id}?page=${searchParams.get('page')||""}`} target="_blank" className="flex items-center gap-1 text-zinc-300 text-xs bg-zinc-800 font-semibold px-2 py-2 rounded-lg hover:bg-zinc-700 hover:border-zinc-600 duration-200">
            <IoIosPlay className='w-4 h-4'/> Preview 
          </a>
          <PublishButton/>
        </div>
      </div>
    </div>
  )
}

export default AppHeader