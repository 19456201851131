import axios from "axios";

export const BASE_URL = process.env.REACT_APP_SERVER_URL;

export default axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
});