import React, { useCallback, useContext, useEffect, useState } from 'react'
import DialogBox from './DialogBox';
import { jsonToCode } from '../../../../componentStates/codeParser';
import CanvasContext from '../../../../context/CanvasContext';
import useRecoilStateToValues from '../../../../hooks/useRecoilStateToValues';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { BiLoaderAlt } from 'react-icons/bi';
import { MdSettings } from "react-icons/md";
import { BsRocket } from "react-icons/bs";
import { IoGlobeOutline } from "react-icons/io5";
import { SiStarship } from "react-icons/si";
import { RxCrossCircled } from "react-icons/rx";
import { faker } from '@faker-js/faker';
import { BASE_URL } from '../../../../api/axios';


const PublishBox = ({publishBoxAppear, setPublishBoxAppear}) => {
  const {siteRecord, pageState, setSettingsBoxAppear} =  useContext(CanvasContext);

  const [inputSubdomain, setInputSubdomain] = useState(faker.word.faker.internet.domainWord());
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishErr, setPublishErr] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  const [customDomain, setCustomDomain] = useState("");

  const recoilStateToJsonObj = useRecoilStateToValues();
  const axiosPrivate = useAxiosPrivate();

  const publishPage = async () => {
    if (!inputSubdomain) {
      return;
    }
    const jsonObj = recoilStateToJsonObj(pageState);
    const code = jsonToCode(jsonObj);
    setIsPublishing(true);
    setPublishErr("");
    try {
      const resp = await axiosPrivate.post("/website/publish", {websiteId: siteRecord._id, subdomain: inputSubdomain})
      if (resp && resp.status == 200) {
        setIsPublished(true);
      } else {
        throw Error("Unexpected response from server");
      }
    } catch(err) {
      console.log(err);
      if (err?.response?.status == 400 && err?.response?.data?.message == 'Subdomain already in use') {
        setPublishErr("Domain unavailable");
      }
    } finally {
      setIsPublishing(false);
    }
  }

  const updatePage = async () => {
    if (!inputSubdomain) {
      return;
    }
    const jsonObj = recoilStateToJsonObj(pageState);
    const code = jsonToCode(jsonObj);
    setIsPublishing(true);
    setPublishErr("");
    try {
      const resp = await axiosPrivate.post("/website/publish", {websiteId: siteRecord._id})
      if (resp.status == 200) {
        
      } else {
        throw Error("Unexpected response from server");
      }
    } catch(err) {
      console.log(err);
    } finally {
      setIsPublishing(false);
    }
  }

  useEffect(()=>{
    if (siteRecord._id && siteRecord.subdomain) {
      setInputSubdomain(siteRecord.subdomain);
      setIsPublished(true);
      if (siteRecord.customDomain) {
        setCustomDomain(siteRecord.customDomain);
      }
    }
  },[siteRecord])
  const regex = /^[a-zA-Z0-9-]+$/;

  return (
    <DialogBox appear={publishBoxAppear} setAppear={setPublishBoxAppear}>
      <div className='absolute '>
        <div className='bg-black border border-zinc-800 flex flex-col gap-3 text-zinc-300 fixed left-[515px] top-1 z-10 p-4 rounded-xl text-xs w-80'>
          <p className='font-semibold text-base flex items-center justify-between border-b border-zinc-800 pb-2'>
            Publish
            {publishErr &&
              <p className='text-red-900 opacity-100 text-xs flex font-semibold gap-1'>
                <RxCrossCircled className='w-4 h-auto'/>
                {publishErr}
              </p>
            }
            
          </p>
          <div className='text-zinc-500 w-full flex flex-col gap-2 items-center my-4 text-sm'>
            {isPublished?
              <>
                <SiStarship className={`w-12 h-auto ${isPublishing? 'animate-bounce':''}`}/>
                <p>{isPublishing? "Updating... ":"Your website is live!"}</p>
              </>
              :
              <>
                <BsRocket className={`w-12 h-auto ${isPublishing? 'animate-bounce':''}`}/>
                <p>Launch your website to the world!</p>
              </>
            }
          </div>
          {isPublished?
            <div className=''>
              {customDomain &&
                <a href={`https://${customDomain}`} target="_blank" className='underline text-zinc-300 text-sm w-full flex justify-center items-center gap-1 mb-2'>
                  <IoGlobeOutline className='w-4 h-auto'/>https://{customDomain}
                </a>
              }
              <a href={`${process.env.REACT_APP_SITE_PUBLISH_URL.replace("://", `://${inputSubdomain}.`)}`} target="_blank" className='underline text-zinc-300 text-sm w-full flex justify-center items-center gap-1 mb-2'>
                <IoGlobeOutline className='w-4 h-auto'/>{inputSubdomain}.magicweb.live
              </a>
            </div>
            :
            <div className='flex border text-[14px] border-zinc-800 text-md rounded-md'>
              <div className='w-fit flex-grow'>
                <input value={inputSubdomain} onChange={(e)=>{
    if (regex.test(e.target.value)) setInputSubdomain(e.target.value)}} className='w-full outline-none bg-zinc-900 py-2 px-3 rounded-l'/>
              </div>
              <span className='rounded-r-md bg-zinc-800 py-2 pr-4 pl-1 text-zinc-300'>.magicweb.live</span>
            </div>
          }
          <div className='w-full text-[14px] flex gap-3'>
            <button onClick={()=>{setSettingsBoxAppear(true);setPublishBoxAppear(false)}} className="flex-1 flex gap-1 items-center justify-center bg-zinc-800 text-zinc-300 font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-zinc-600">
              <MdSettings className='w-4 h-auto'/>Settings
            </button>
            {isPublished?
              <button onClick={updatePage} disabled={isPublishing} className="flex-1 flex justify-center bg-lime-600 text-white font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-lime-500">
                {!isPublishing? "Update" : <BiLoaderAlt className='w-4 h-4 animate-spin'/>}
              </button>
              :
              <button onClick={publishPage} disabled={isPublishing} className="flex-1 flex justify-center bg-lime-600 text-white font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-lime-500">
                {!isPublishing? "Publish" : <BiLoaderAlt className='w-4 h-4 animate-spin'/>}
              </button>
            }
          </div>
          
        </div>
      </div>
    </DialogBox>
  )
}

export default PublishBox