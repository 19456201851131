import React, { useContext, useEffect, useRef, useState } from 'react'
import { TbSquareRoundedArrowUpFilled } from "react-icons/tb";
import { LuImage } from "react-icons/lu";
import { RxCross2 } from 'react-icons/rx';
import CanvasContext from '../../../../context/CanvasContext';
import useNewChat from '../../../../hooks/useNewChat';
import GptModelSelector from './GptModelSelector';

const ChatInput = () => {
  const {pageState, selectedState, setSelectedState} = useContext(CanvasContext);
  const handleNewChat = useNewChat();
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const textareaRef = useRef(null);
  
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
        textarea.style.height = 'auto'; // Reset height to recalculate
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [inputText]); 

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  useEffect(()=>{
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
          setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  }, [selectedFile])

  const handlePaste = (event) => {
    const items = (event.clipboardData.items || event.clipboardData);
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const blob = item.getAsFile();
        setSelectedFile(blob);
      }
    }
  };

  return (
    <div className={`relative border ${isDragOver? 'border-lime-400': isFocused? 'border-lime-600' : 'border-zinc-700'} p-1 rounded-xl px-2 mr-8`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    > 
      {/* <GptModelSelector/> */}
      {(previewUrl || selectedState.key !== pageState.key) && 
        <span className='text-sm text-zinc-400 px-1'>Attached:</span>
      }
      <div className='flex items-center'>
        {previewUrl &&
          <div className='h-16 w-fit my-2 mb-4 mx-1 relative group'>
            <img src={previewUrl} alt="upload preview" className='h-16 w-auto rounded-lg border-[0.5px] border-lime-600 hover:border-lime-500 duration-200'/>
            <button
              className='absolute top-[-5px] right-[-5px] rounded-full border border-zinc-600 bg-zinc-800 hidden group-hover:block'
              onClick={()=>{setSelectedFile(null)}}
            >
              <RxCross2 className='w-4 h-4 text-zinc-300'/>
            </button>
          </div>
        }
        {selectedState.key !== pageState.key &&
          <div className='my-2 mx-1 relative group w-fit flex items-center gap-2'>
            {/* <span className='text-zinc-300 text-sm'>Selected:</span> */}
            <div className='rounded-md text-sm py-1 px-2 text-zinc-300 bg-zinc-900 font-bold tracking-tighter border border-[#40e0d0] hover:border-lime-600'>
              {selectedState.key.split("_")[0]}
            </div>
            <button
              className='absolute top-[-5px] right-[-5px] rounded-full border border-zinc-600 bg-zinc-800 hidden group-hover:block'
              onClick={()=>{setSelectedState(pageState)}}
            >
              <RxCross2 className='w-4 h-4 text-zinc-300'/>
            </button>
          </div>
        }
      </div>
      
      <div className='flex items-end'>
        <input ref={fileInputRef} type="file" accept='image/*' onChange={handleFileInput} style={{ display: 'none' }} />
        <button className='mb-2' onClick={()=>{fileInputRef.current.click()}}>
          <LuImage className='w-6 h-6 text-zinc-400 mx-1'/>
        </button>
        <textarea
          ref={textareaRef}
          rows={1}
          value={inputText}
          onChange={(e)=> { if(e.target.value !== '\n') setInputText(e.target.value); }}
          onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) handleNewChat(inputText, setInputText, selectedFile, setSelectedFile); }}
          className='outline-none bg-transparent text-md p-2 px-5 text-zinc-100 flex-grow font-secondary placeholder:text-zinc-500 placeholder:text-sm resize-none max-h-32 customScroll'
          type='text'
          placeholder='Type your instruction here..'
          onPaste={handlePaste}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <button className='disabled:text-zinc-500 text-lime-600 mb-1' onClick={()=>handleNewChat(inputText, setInputText, selectedFile, setSelectedFile)} disabled={!inputText? true:false}>
          <TbSquareRoundedArrowUpFilled className='w-8 h-8 '/>
        </button>
      </div>
      
    </div>
  )
}

export default ChatInput