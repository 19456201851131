import { atom } from 'recoil';
import { v4 as uuid } from 'uuid';

export const cState = ({compType, compName, compProps, eventProps, className, style, script, bodyStyle, children}) => atom({
  key: `${compName+'_'+uuid()}`,
  default: {
    compType: compType? compType: 'html',
    compName: compName? compName: 'div', 
    compProps: compProps? compProps : {}, // component props as props given directly to the comp
    eventProps: eventProps? eventProps : {}, // all the props which starts with "on"
    className: className? className: "", // this is an object containing all the style clases
    style: style? style : {}, // style is the style object directly provided to the compa as the style={style}
    script: script? script : "",
    bodyStyle: bodyStyle? bodyStyle : "",
    children: children? [...children] : [] // this contains all the children states which will be rendered inside the comp
  }
});

export const jsonObjToRecoilState = (obj) => {
  if (!obj) {
    return "";
  }
  if (typeof obj === 'string') {
    return obj;
  }
  // console.log(obj);
  return cState({...obj, children: obj?.children?.map(objChild => jsonObjToRecoilState(objChild))})
}

