import React, { useState } from 'react'
import { MdPublish } from "react-icons/md";
import PublishBox from './PublishBox';


const PublishButton = () => {
  const [publishBoxAppear, setPublishBoxAppear] = useState(false);

  return (
    <div className='flex relative'>
      <button onClick={()=>{setPublishBoxAppear(!publishBoxAppear);}} className="flex items-center gap-1 bg-lime-600 text-xs text-white font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-lime-500">
        <MdPublish className='w-4 h-4'/>
        Publish
      </button>
      <PublishBox publishBoxAppear={publishBoxAppear} setPublishBoxAppear={setPublishBoxAppear} />
    </div>
  )
}

export default PublishButton