import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import DialogBox from "./DialogBox";
import CanvasContext from "../../../../context/CanvasContext";
import { getHex, setColorProperty } from "../../../../helpers/helpers";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const ThemeColors = () => {
  const {palette, setPalette, siteRecord} = useContext(CanvasContext);
  
  const [dialogBoxAppear, setDialogBoxAppear] = useState(false);
  const primaryColorInputRef = useRef(null);
  const secondaryColorInputRef = useRef(null);
  const backgroundColorInputRef = useRef(null);
  const foregroundColorInputRef = useRef(null);
  const grayshadeColorInputRef = useRef(null);

  const palettes = [
    {primary:'teal-500', secondary: 'orange-400', background: '#F7FAFC', foreground: 'gray-800', grayshade: 'gray-400'},
    {primary:'red-600', secondary: 'yellow-500', background: '#FFF5F5', foreground: 'gray-700', grayshade: 'gray-500'},
    {primary:'indigo-600', secondary: 'zinc-600', background: 'black', foreground: 'zinc-200', grayshade: 'zinc-500'},
    {primary:'fuchsia-600', secondary: 'indigo-600', background: 'white', foreground: 'black', grayshade: 'blue-500'},
    {primary:'blue-600', secondary: 'amber-400', background: '#14181F', foreground: 'gray-100', grayshade: 'gray-500'},
    {primary:'#1E3A8A', secondary: '#FF6B6B', background: '#FAF9F6', foreground: '#333333', grayshade: '#888888'},
    {primary:'#E5DF38', secondary: '#6CD327', background: '#121212', foreground: '#E4E4E7', grayshade: '#71717A'},
    { background: '#F8F9FA', foreground: '#2F4F4F', primary: '#2053AF', secondary: '#87CEEB', grayshade: '#B0BEC5' },
    { background: '#FFF8E7', foreground: '#36454F', primary: '#EA7E5D', secondary: '#FFDAB9', grayshade: '#BDBDBD' },
    { background: '#000000', foreground: '#FFFFFF', primary: '#65A30C', secondary: '#40E0D0', grayshade: '#C0C0C0' },
    { background: '#ECEFF1', foreground: '#000080', primary: '#FF6F61', secondary: '#FFD700', grayshade: '#708090' },
    { primary: '#6B3074', secondary: '#DAA520', background: '#FFFDF3', foreground: '#503B31', grayshade: '#CCCCCC' },
    { primary: '#38B000', secondary: '#FFD700', background: '#FFFFFF', foreground: '#000000', grayshade: '#707070' },
    { primary: '#00796B', secondary: '#81D4FA', background: '#ECEFF1', foreground: '#212121', grayshade: '#616161' },
    { primary: '#00BCD4', secondary: '#CDDC39', background: '#F9F9F9', foreground: '#2E2E2E', grayshade: '#9E9E9E' },
    { primary: '#EC407A', secondary: '#FB8C00', background: '#FCE4EC', foreground: '#424242', grayshade: '#BDBDBD' },
    { primary: '#2196F3', secondary: '#76FF03', background: '#E0F7FA', foreground: '#1A237E', grayshade: '#E0E0E0' },
    { primary: '#F44336', secondary: '#263238', background: '#ECEFF1', foreground: '#000000', grayshade: '#757575' },
    { primary: '#800000', background: '#FFF8E7', foreground: '#505050', secondary: '#B76E79', grayshade: '#CCCCCC' },
    { primary: '#008080', background: '#FAF0E6', foreground: '#000080', secondary: '#FF7F50', grayshade: '#D3D3D3' },
    { primary: '#00FF00', background: '#FFFFFF', foreground: '#333333', secondary: '#FFFF00', grayshade: '#B2BEB5' },
    { primary: '#FF0000', background: '#282828', foreground: '#FFFFFF', secondary: '#0000FF', grayshade: '#606060' },
    { primary: '#D07D62', background: '#F4F1DE', foreground: '#228B22', secondary: '#DAA520', grayshade: '#B0A8B9' },
    { primary: '#40E0D0', background: '#263238', foreground: '#C0C0C0', secondary: '#FF7F50', grayshade: '#808080' },
    { primary: '#D7566B', background: '#FAFAFA', foreground: '#36454F', secondary: '#AAF0D1', grayshade: '#D3D3D3' },
    { primary: '#007FFF', background: '#121212', foreground: '#FFFFFF', secondary: '#FFD700', grayshade: '#585858' },
    { primary: '#794D8D', background: '#FFFFF0', foreground: '#000080', secondary: '#F7CAC9', grayshade: '#778899' },
  ]

  const axiosPrivate = useAxiosPrivate();
  const handleColorChange = async (colorType, colorValue) => {
    setPalette(curr => {
      const newTheme = {...curr};
      newTheme[colorType] = colorValue;
      updateColorPalette(newTheme);
      return newTheme;
    })
  };

  const updateColorPalette = async (colorPalette)=>{
    try {
      const resp = await axiosPrivate.patch("/website", {id: siteRecord._id, colorPalette});
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(()=>{
    Object.entries(palette).forEach(([colorType, color]) => setColorProperty(colorType, color))
  },[palette])

  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>Colors</p>
      <div className="col-span-2 flex relative">
        <button className="propUnitBox p-3 w-full flex justify-center items-center" onClick={()=>setDialogBoxAppear(!dialogBoxAppear)}>
          {palette?.primary?
            <div className="w-full bg-black rounded overflow-hidden">
              <div className="grid grid-cols-5 h-2">
                <div className="bg-primary h-full duration-200"></div>
                <div className="bg-secondary h-full duration-200"></div>
                <div className="bg-background h-full duration-200"></div>
                <div className="bg-foreground h-full duration-200"></div>
                <div className="bg-grayshade-500 h-full duration-200"></div>
              </div>
            </div>
            :
            <div className="text-xs text-zinc-300" onClick={()=>setDialogBoxAppear(true)}>
              Set Color Palette
            </div>
          }
          
        </button>
        <DialogBox appear={dialogBoxAppear} setAppear={setDialogBoxAppear}>
          <div className='propBoxDialog fixed border border-zinc-800'>
            <p className='flex items-center border-b border-zinc-800 pb-2'>Color Palletes</p>
            <div className="h-6 gap-2 grid grid-cols-5">
              <div title="primary" className={`border-[1px] border-zinc-400 rounded bg-primary h-full cursor-pointer hover:opacity-70 duration-200`} onClick={()=>primaryColorInputRef.current.click()}>
                <input
                  ref={primaryColorInputRef}
                  type="color"
                  value={palette?.primary && getHex(palette.primary)}
                  onChange={(e) => handleColorChange('primary', e.target.value, palette)}
                  className="opacity-0 absolute cursor-pointer"
                />
              </div>
              <div title="secondary" className={`border-[1px] border-zinc-400 rounded bg-secondary h-full cursor-pointer hover:opacity-70 duration-200`} onClick={()=>secondaryColorInputRef.current.click()}>
                <input
                  ref={secondaryColorInputRef}
                  type="color"
                  value={palette?.secondary && getHex(palette.secondary)}
                  onChange={(e) => handleColorChange('secondary', e.target.value)}
                  className="opacity-0 absolute w-0 cursor-pointer"
                />
              </div>
              <div title="background" className={`border-[1px] border-zinc-400 rounded bg-background h-full cursor-pointer hover:opacity-70 duration-200`} onClick={()=>backgroundColorInputRef.current.click()}>
                <input
                  ref={backgroundColorInputRef}
                  type="color"
                  value={palette?.background && getHex(palette.background)}
                  onChange={(e) => handleColorChange('background', e.target.value)}
                  className="opacity-0 absolute w-0 cursor-pointer"
                />
              </div>
              <div title="foreground" className={`border-[1px] border-zinc-400 rounded bg-foreground h-full cursor-pointer hover:opacity-70 duration-200`} onClick={()=>foregroundColorInputRef.current.click()}>
                <input
                  ref={foregroundColorInputRef}
                  type="color"
                  value={palette?.foreground && getHex(palette.foreground)}
                  onChange={(e) => handleColorChange('foreground', e.target.value)}
                  className="opacity-0 absolute w-0 cursor-pointer"
                />
              </div>
              <div title="grayshade" className={`border-[1px] border-zinc-400 rounded bg-grayshade-500 h-full cursor-pointer hover:opacity-70 duration-200`} onClick={()=>grayshadeColorInputRef.current.click()}>
                <input
                  ref={grayshadeColorInputRef}
                  type="color"
                  value={palette?.grayshade && getHex(palette.grayshade)}
                  onChange={(e) => handleColorChange('grayshade', e.target.value)}
                  className="opacity-0 absolute w-0 cursor-pointer"
                />
              </div>
            </div>
            <p className='flex items-center border-b border-zinc-800 pb-2'>AI Picked Palletes</p>
            <div className="flex flex-col gap-3 overflow-auto h-36">
              {
                palettes.map((th, i) => (
                  <button key={i} className="rounded-lg w-full p-2 bg-zinc-900 hover:bg-zinc-700 duration-200" onClick={async ()=>{setPalette(th);await updateColorPalette(th)}}>
                    <div className="w-full bg-black rounded overflow-hidden">
                      <div className="grid grid-cols-5 h-2">
                        <div className={`bg-[${getHex(th.primary)}] h-full`}></div>
                        <div className={`bg-[${getHex(th.secondary)}] h-full`}></div>
                        <div className={`bg-[${getHex(th.background)}] h-full`}></div>
                        <div className={`bg-[${getHex(th.foreground)}] h-full`}></div>
                        <div className={`bg-[${getHex(th.grayshade)}] h-full`}></div>
                      </div>
                    </div>
                  </button>
                ))
              }
            </div>
          </div>
        </DialogBox>
      </div>
    </>
  );
};

export default ThemeColors;
