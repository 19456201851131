import React, { useCallback, useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { FaAngleDown } from "react-icons/fa6";
import classNames from 'classnames';

import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import DialogBox from './DialogBox';
import { classesToObj } from '../../../../helpers/helpers';
import { useRecoilValue } from 'recoil';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';


const MinWidth = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [minWidthType, setMinWidthType] = useState('');
  const [minWidth, setMinWidth] = useState('');
  const [tailwindDropdown, setTailwindDropdown] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const [customType, setCustomType] = useState('fixed');
  const [customMinWidth, setCustomMinWidth] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  
  
  const tailwindMinWidthClasses = [
    "min-w-0", "min-w-1", "min-w-2", "min-w-3", "min-w-4", "min-w-5",
    "min-w-6", "min-w-7", "min-w-8", "min-w-9", "min-w-10", "min-w-11",
    "min-w-12", "min-w-14", "min-w-16", "min-w-20", "min-w-24", "min-w-28",
    "min-w-32", "min-w-36", "min-w-40", "min-w-44", "min-w-48", "min-w-52",
    "min-w-56", "min-w-60", "min-w-64", "min-w-72", "min-w-80", "min-w-96",
    "min-w-px", "min-w-0.5", "min-w-1.5", "min-w-2.5", "min-w-3.5",
    "min-w-full", "min-w-min", "min-w-max", "min-w-fit"
  ];
  const tailwindMinWidthClassesResetObj = tailwindMinWidthClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{
    const customMinWidth = state.style?.minWidth;
    if (parseInt(customMinWidth) >= 0) {
      setMinWidthType('custom');
      if (customMinWidth.endsWith("%")) {
        setCustomType('rel');
      } else {
        setCustomType('fixed');
      }
      console.log(selectedCompStyle?.minWidth);
      setCustomMinWidth(parseInt(customMinWidth));
    } else {
      const classNameObj = classesToObj(state.className)
      let found = '';
      tailwindMinWidthClasses.forEach((cls)=> {
        if (classNameObj[cls]) {
          found=cls;
        }
      })
      if (found) {
        setMinWidthType('tailwind');
        setMinWidth(found);
      } else {
        setMinWidthType('');
        setMinWidth('');
      }
      if (parseInt(selectedCompStyle.minWidth) >=0) {
        setCustomMinWidth(parseInt(selectedCompStyle.minWidth));
      }
      // ignoring w-[value]
    }
  },[state, selectedCompStyle])

  const updateState =  (minWidth, minWidthType)=>{
    if (minWidthType === 'tailwind') {
      setState((prevState) => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-w-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinWidthClassesResetObj};
        classObj[minWidth] = true;
        return {...prevState, className: classNames(classObj), style:{...prevState.style, minWidth:undefined}}
      });
    } else if (minWidthType === 'custom') {
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-w-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinWidthClassesResetObj}; // removing all other minWidth classes
        return {...prevState, style: {...prevState.style, minWidth: `${minWidth}${customType==='rel'? '%':'px'}`}, className: classNames(classObj)}
      })
    } else { // remove minWidth setting
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-w-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinWidthClassesResetObj}; // removing all other minWidth classes
        return {...prevState, style: {...prevState.style, minWidth: undefined}, className: classNames(classObj)};
      })
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>MinWidth</p>
      <div className="col-span-2 flex flex-col gap-0 relative h-8">
        <button 
          className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full pl-3 pr-2"
          onClick={(e)=>{setTailwindDropdown(!tailwindDropdown)}}
        >
          <span className='h-8 flex items-center flex-grow text-left'>
            {minWidthType === '' && <span className="text-zinc-500">Select</span>}
            {minWidthType !== '' &&
            <div className='flex items-center justify-between pr-1 gap-1 w-full group'>
              <span>{minWidthType==='tailwind'? minWidth : 'custom'}</span>
              <RxCross2 className='w-3 h-3 rounded-full bg-zinc-600 hidden group-hover:block' onClick={(e)=>{e.stopPropagation(); setMinWidthType('');setMinWidth('');setCustomMinWidth(0);updateState(0, '')}}/>
            </div> 
            }
          </span>
          <FaAngleDown className='w-3 h-3'/>
        </button>
        <DialogBox appear={tailwindDropdown} setAppear={setTailwindDropdown}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center border border-zinc-800'>
            <button onClick={()=>{setMinWidthType('custom');setTailwindDropdown(false)}} className={`hover:bg-zinc-700 ${minWidth == 'custom' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 `}>
              custom
            </button>
            {
              tailwindMinWidthClasses.map(minWidthCls => 
                <button
                  key={minWidthCls}
                  className={`hover:bg-zinc-700 ${minWidth == minWidthCls && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2`}
                  onClick={()=>{updateState(minWidthCls, 'tailwind')}}
                >
                  {minWidthCls}
                </button>
              )
            }
          </div>
        </DialogBox>
      </div>
      {minWidthType === 'custom' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className="h-8 flex flex-col text-white propUnitBox w-1/2">
              <button 
                className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full px-1"
                onClick={(e)=>{setCustomDropdown(!customDropdown)}}
              >
                <span className='h-8 flex items-center flex-grow text-left pl-1 capitalize'>
                  {customType}
                </span>
                <FaAngleDown className='w-3 h-3'/>
              </button>
              <DialogBox appear={customDropdown} setAppear={setCustomDropdown}>
                <div className='absolute bg-zinc-900 text-zinc-400 text-sm py-0 mt-1 max-h-32 overflow-y-auto rounded-md flex flex-col items-center z-50 border border-zinc-800'>
                  <button onClick={()=>{setCustomType('fixed'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'fixed' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    fixed
                  </button>
                  <button onClick={()=>{setCustomType('rel'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'rel' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    rel
                  </button>
                </div>
              </DialogBox>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox w-1/2 pl-1"+`${isFocused? ' border border-indigo-600' : ''}`}>
              <input type="text" className='w-3/4 bg-transparent outline-none h-3 px-1 text-xs tracking-widest text-zinc-400'
                value={customMinWidth}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateState(parseInt(e.target.value), 'custom');
                  } else {
                    updateState(0, 'custom');
                  }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateState(customMinWidth+1,'custom')}} />
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (customMinWidth > 0) updateState(customMinWidth-1,'custom')}} />
              </div>
            </div>
          </div>
        </> 
      }
      
    </>
  )
}

export default MinWidth