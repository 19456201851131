import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';

export const useLogout = () => {
    const  {setAuth} = useAuth();
    const navigate = useNavigate();
    const logOut = async () => {
        try {
          const resp = await axios.post('/auth/logout');
          console.log(resp);
        } catch (err) {
          console.log(err?.message);
        }
        setAuth({});
        navigate('/');
    }
    return logOut;
}
