import React, { useEffect, useRef } from 'react'

const DialogBox = ({appear, setAppear, cb, children}) => {
  const dialogRef = useRef(null);
  const hanldeClickOutsideBox = (e) => {
    if (dialogRef.current && !dialogRef.current.contains(e.target)) {
      setAppear(false);
      if (typeof(cb) == 'function') {
        cb();
      }
    }
    
  }
  useEffect(()=>{
    if (appear) {
      document.addEventListener('mousedown', hanldeClickOutsideBox);
    } else {
      document.removeEventListener('mousedown', hanldeClickOutsideBox);
    }

    return ()=>{document.removeEventListener('mousedown', hanldeClickOutsideBox)}
  }, [appear])
  return ( 
    <>
      {appear && 
        <div ref={dialogRef}>
          {children}
        </div>
      }
    </>
  )
}

export default DialogBox