import React from 'react'

const Avatar = ({size, bgColor, textColor, children}) => {
  return (
    <div className={`${bgColor} ${textColor} rounded-full font-bold text-xs w-${size} h-${size} flex justify-center items-center`}>
      {children}
    </div>
  )
}

export default Avatar