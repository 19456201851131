import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import CanvasContext from '../../../../context/CanvasContext';
import { rgbaToHex } from '../../../../helpers/helpers';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';



const TextColor = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [textColor, setTextColor] = useState("");
  const colorInputRef = useRef(null);

  useEffect(()=>{
    if (selectedCompStyle?.color && (state?.style?.color || selectedCompStyle?.color !== 'rgba(0, 0, 0, 0)')) {
      setTextColor(rgbaToHex(selectedCompStyle.color));
    } else {
      setTextColor("");
    }
  },[selectedCompStyle, state])

  const updateCompTextColor = (color) => {
    if (!selectedState) {
      return;
    }
    const stateStyleObj = {...state.style, color:color};
    
    setState((prevState) => ({...prevState, style:  stateStyleObj}));
  }

  
  const handleColorChange = (e) => {
    updateCompTextColor(e.target.value);
    setTextColor(e.target.value);
  };

  const handleDivClick = () => {
    colorInputRef.current.click();
  };

  return (
  
    <>
      <p className='flex items-center text-zinc-400 text-xs'>Color</p>
      <div onClick={handleDivClick} className="col-span-2 propUnitBox w-full p-[6px] pl-3 flex items-center justify-between cursor-pointer">
        {textColor? <input className='text-zinc-400 bg-transparent outline-none w-16 font-sans' value={textColor} onChange={handleColorChange}/> : <span className='text-zinc-500 '>Add</span> }
        <div>
          <div
            className="w-5 h-5 rounded-full shadow-sm border-[0.5px] border-zinc-500"
            style={{ backgroundColor: textColor }}
          >
            <input
            ref={colorInputRef}
            type="color"
            value={textColor}
            onChange={handleColorChange}
            className="opacity-0"
          />
          </div>
        </div>
      </div>
    </>
  )
}

export default TextColor