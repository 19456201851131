import axios from '../api/axios';
import { useAuth } from './useAuth';

export const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        try {
            const response = await axios.get('/auth/refresh', { withCredentials: true});
            setAuth((prevAuth) => {
                return {
                    ...prevAuth,
                    roles: response?.data?.roles,
                    accessToken: response?.data?.accessToken
                };
            });

            return response?.data?.accessToken;
        } catch (error) {
            console.log(error);
            console.log("Session timed out, please login again.");
            setAuth({});
        }
    }
    return refresh;
}
