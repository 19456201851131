import React, { useCallback, useContext, useEffect, useState } from 'react'
import DialogBox from './DialogBox';
import CanvasContext from '../../../../context/CanvasContext';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoCheckmark } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { useParams } from 'react-router-dom';


const ScriptBox = ({page, scriptBoxAppear, setScriptBoxAppear}) => {
  const { siteId } = useParams();
  const {siteRecord} =  useContext(CanvasContext);

  const [scriptType, setScriptType] = useState("head");
  const [headScript, setHeadScript] = useState("");
  const [bodyScript, setBodyScript] = useState("");

  const [updated, setUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateErr, setUpdateErr] = useState("");

  const axiosPrivate = useAxiosPrivate();

  useEffect(()=>{
    if (siteRecord?.devPages && siteRecord.devPages[page]?.headScript) {
      setHeadScript(siteRecord.devPages[page]?.headScript);
    }
    if (siteRecord?.devPages && siteRecord.devPages[page]?.bodyScript) {
      setBodyScript(siteRecord.devPages[page]?.bodyScript);
    }
  },[siteRecord])

  const updateScript = async ()=>{
    setUpdated(false);
    setIsUpdating(true);
    setUpdateErr("");
    try {
      const pages = {};
      if (scriptType === "head") {
        pages[page] = {headScript};
      }
      if (scriptType === "body") {
        pages[page] = {bodyScript};
      }
      
      const resp = await axiosPrivate.patch("/website", {id: siteId, pages});
      if (resp.status == 200) {
        setUpdated(true)
      } else {
        setUpdateErr("Error");
      }
    } catch (err) {
      console.log(err);
      setUpdateErr("Error");
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <DialogBox appear={scriptBoxAppear} setAppear={setScriptBoxAppear}>
      <div className='absolute '>
        <div className='bg-black border border-zinc-800 flex flex-col gap-3 text-zinc-300 fixed  left-[515px] z-10 p-4 rounded-xl text-xs w-96'>
          <p className='font-semibold text-base flex items-center justify-between border-b border-zinc-800 pb-2'>
            Insert Script
            {updateErr &&
              <p className='text-red-900 opacity-100 text-xs flex font-semibold gap-1'>
                <RxCrossCircled className='w-4 h-auto'/>
                {updateErr}
              </p>
            }
            {updated &&
              <p className='text-lime-600 opacity-100 text-xs flex font-semibold gap-1'>
              <IoCheckmark className='w-4 h-auto'/>
                Script Updated
              </p>
            }
          </p>
          <div className='text-zinc-500 w-full flex flex-col gap-4 text-xs'>
            <div className='flex gap-4'>
              <div className='relative inline-block flex-1 '>
                <select value={scriptType} onChange={(e)=>setScriptType(e.target.value)} className="outline-none block appearance-none  px-3 py-2 rounded-md text-zinc-200 bg-zinc-700 w-full border border-zinc-700 hover:border-zinc-500">
                  <option value="head">
                    Head Script
                  </option>
                  <option value="body">Body Script</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              
              <button onClick={updateScript} className='py-2 rounded-md text-zinc-200 bg-lime-600 flex-1 font-semibold flex justify-center disabled:bg-lime-800' disabled={isUpdating}>{!isUpdating? "Update" : <BiLoaderAlt className='w-4 h-4 animate-spin'/>}</button>
            </div>
            {scriptType ==="head"?
              <textarea title='Write your scripts with <script> tags' placeholder='<script> valid script </script>' value={headScript} onChange={(e)=>setHeadScript(e.target.value)} rows="7" className='border border-zinc-800 text-md w-full outline-none bg-zinc-900 px-4 py-3 rounded-md resize-none text-zinc-300 placeholder:text-zinc-600'/>
              :
              <textarea title='Write your scripts with <script> tags' placeholder='<script> valid script </script>' value={bodyScript} onChange={(e)=>setBodyScript(e.target.value)} rows="7" className='border border-zinc-800 text-md w-full outline-none bg-zinc-900 px-4 py-3 rounded-md resize-none text-zinc-300 placeholder:text-zinc-600'/>
            }
          </div>
        </div>
      </div>
    </DialogBox>
  )
}

export default ScriptBox