import React, { useContext, useEffect, useRef, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { CgBorderAll, CgBorderTop, CgBorderRight, CgBorderBottom, CgBorderLeft } from "react-icons/cg";
import DialogBox from './DialogBox';
import chroma from "chroma-js";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const Border = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const [type, setType] = useState('all');
  const [borderStyle, setBorderStyle] = useState('solid');
  const [width, setWidth] = useState(0);
  const [color, setColor] = useState("");
  const colorInputRef = useRef(null);
  const [dialogBoxAppear, setDialogBoxAppear] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(()=>{
    // set border states
    if (type == 'all') {
      if (
        selectedCompStyle?.borderTopWidth &&
        selectedCompStyle?.borderTopWidth == selectedCompStyle?.borderBottomWidth
        && selectedCompStyle?.borderLeftWidth == selectedCompStyle?.borderRightWidth
        && selectedCompStyle?.borderTopWidth == selectedCompStyle?.borderRightWidth
      ) {
        setWidth(parseInt(selectedCompStyle?.borderTopWidth));
      } else {
        setWidth(0);
      }
      if (
        selectedCompStyle?.borderTopColor
        && selectedCompStyle?.borderTopColor == selectedCompStyle?.borderBottomColor
        && selectedCompStyle?.borderLeftColor == selectedCompStyle?.borderRightColor
        && selectedCompStyle?.borderTopColor == selectedCompStyle?.borderRightColor
      ) {
        setColor(chroma(selectedCompStyle?.borderTopColor).hex());
      } else {
        setColor("");
      }
      if (
        selectedCompStyle?.borderTopStyle
        && selectedCompStyle?.borderTopStyle == selectedCompStyle?.borderBottomStyle
        && selectedCompStyle?.borderLeftStyle == selectedCompStyle?.borderRightStyle
        && selectedCompStyle?.borderTopStyle == selectedCompStyle?.borderRightStyle
      ) {
        setBorderStyle(selectedCompStyle?.borderTopStyle);
      } else {
        setBorderStyle("");
      }
    }
    if (type == 't') {
      if (selectedCompStyle?.borderTopWidth) {
        setWidth(parseInt(selectedCompStyle?.borderTopWidth));
      } else {
        setWidth(0);
      }
      if (selectedCompStyle?.borderTopColor) {
        setColor(chroma(selectedCompStyle?.borderTopColor).hex());
      } else {
        setColor("");
      }
      if (selectedCompStyle?.borderTopStyle) {
        setBorderStyle(selectedCompStyle?.borderTopStyle);
      } else {
        setBorderStyle("");
      }
    }
    if (type == 'r') {
      if (selectedCompStyle?.borderRightWidth) {
        setWidth(parseInt(selectedCompStyle?.borderRightWidth));
      } else {
        setWidth(0);
      }
      if (selectedCompStyle?.borderRightColor) {
        setColor(chroma(selectedCompStyle?.borderRightColor).hex());
      } else {
        setColor("");
      }
      if (selectedCompStyle?.borderRightStyle) {
        setBorderStyle(selectedCompStyle?.borderRightStyle);
      } else {
        setBorderStyle("");
      }
    }
    if (type == 'b') {
      if (selectedCompStyle?.borderBottomWidth) {
        setWidth(parseInt(selectedCompStyle?.borderBottomWidth));
      } else {
        setWidth(0);
      }
      if (selectedCompStyle?.borderBottomColor) {
        setColor(chroma(selectedCompStyle?.borderBottomColor).hex());
      } else {
        setColor("");
      }
      if (selectedCompStyle?.borderBottomStyle) {
        setBorderStyle(selectedCompStyle?.borderBottomStyle);
      } else {
        setBorderStyle("");
      }
    }
    if (type == 'l') {
      if (selectedCompStyle?.borderLeftWidth) {
        setWidth(parseInt(selectedCompStyle?.borderLeftWidth));
      } else {
        setWidth(0);
      }
      if (selectedCompStyle?.borderLeftColor) {
        setColor(chroma(selectedCompStyle?.borderLeftColor).hex());
      } else {
        setColor("");
      }
      if (selectedCompStyle?.borderLeftStyle) {
        setBorderStyle(selectedCompStyle?.borderLeftStyle);
      } else {
        setBorderStyle("");
      }
    }
  },[selectedCompStyle, type])

  const updateWidth = (type, width) => {
    if (!selectedState) {
      return;
    }

    if (type == 'all') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopWidth: width, borderRightWidth: width, borderBottomWidth: width, borderLeftWidth: width}}));
    }
    if (type == 't') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopWidth: width}}));
    }
    if (type == 'r') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderRightWidth: width}}));
    }
    if (type == 'b') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderBottomWidth: width}}));
    }
    if (type == 'l') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderLeftWidth: width}}));
    }
    setWidth(width);
  }

  const updateColor = (type, color) => {
    if (!selectedState) {
      return;
    }
    if (type == 'all') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopColor: color, borderRightColor: color, borderBottomColor: color, borderLeftColor: color}}));
    }
    if (type == 't') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopColor: color}}));
    }
    if (type == 'r') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderRightColor: color}}));
    }
    if (type == 'b') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderBottomColor: color}}));
    }
    if (type == 'l') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderLeftColor: color}}));
    }
    setColor(color);
  }

  const updateBorderStyle = (type, borderStyle) => {
    if (!selectedState) {
      return;
    }
    if (type == 'all') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopStyle: borderStyle, borderRightStyle: borderStyle, borderBottomStyle: borderStyle, borderLeftStyle: borderStyle}}));
    }
    if (type == 't') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderTopStyle: borderStyle}}));
    }
    if (type == 'r') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderRightStyle: borderStyle}}));
    }
    if (type == 'b') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderBottomStyle: borderStyle}}));
    }
    if (type == 'l') {
      setState((prevState) => ({...prevState, style: {...prevState.style, borderLeftStyle: borderStyle}}));
    }
    setBorderStyle(borderStyle);
  }

  const handleBorderPropClick = () => {
    setDialogBoxAppear(!dialogBoxAppear);
  };

  const handleBorderColorClick = () => {
    colorInputRef.current.click();
  }

  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16'>Border</p>
      <div className='col-span-2 relative'>
        <div onClick={handleBorderPropClick} className=" propUnitBox w-full p-[6px] pl-3  flex items-center justify-between cursor-pointer">
          <span className={width? `text-zinc-400` : 'text-zinc-500'}>
            {
              width? width : "Add"
            }
          </span> 
          <div
            className="w-5 h-5 rounded-full shadow-sm border-[0.5px] border-zinc-500"
            style={{ backgroundColor: color }}
          ></div>
          
        </div>

        {/* dialog box */}
        <DialogBox appear={dialogBoxAppear} setAppear={setDialogBoxAppear}>
          <div className='propBoxDialog fixed top-[60%] border border-zinc-800'>
            <p className='flex items-center border-b border-zinc-800 pb-2'>Border</p>
            <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
              <button onClick={()=>{setType('all')}} className={'flex justify-center items-center py-1 w-[20%] rounded-md text-xs ' +`${type=='all'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <CgBorderAll className='w-5 h-5 text-zinc-400 hover:scale-110'/>
              </button>
              <button onClick={()=>{setType('t')}} className={'flex justify-center items-center py-1 w-[20%] rounded-md text-xs ' +`${type=='t'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <CgBorderTop className='w-5 h-5 text-zinc-400 hover:scale-110'/>
              </button>
              <button onClick={()=>{setType('r')}} className={'flex justify-center items-center py-1 w-[20%] rounded-md text-xs ' +`${type=='r'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <CgBorderRight className='w-5 h-5 text-zinc-400 hover:scale-110'/>
              </button>
              <button onClick={()=>{setType('b')}} className={'flex justify-center items-center py-1 w-[20%] rounded-md text-xs ' +`${type=='b'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <CgBorderBottom className='w-5 h-5 text-zinc-400 hover:scale-110'/>
              </button>
              <button onClick={()=>{setType('l')}} className={'flex justify-center items-center py-1 w-[20%] rounded-md text-xs ' +`${type=='l'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <CgBorderLeft className='w-5 h-5 text-zinc-400 hover:scale-110'/>
              </button>
            </div>

            <div className={"flex items-center gap-1 text-white propUnitBox pl-2 w-full "+`${isFocused? 'border border-lime-600' : ''}`}>
              <span className='font-bold'>W</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[70%] px-2 text-xs tracking-widest text-zinc-400'
                value={width}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateWidth(type, parseInt(e.target.value))
                  } else {
                    updateWidth(type, 0)
                  }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                />
              <div className='flex flex-col gap-0 justify-center items-center h-5 px-1'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateWidth(type, width+1)}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (width>0) updateWidth(type, width-1)}}/>
              </div>
            </div>

            <div onClick={handleBorderColorClick} className="col-span-2 propUnitBox w-full p-[6px] pl-3 flex items-center justify-between cursor-pointer">
              {color? <input className='text-zinc-400 bg-transparent outline-none w-16 font-sans' value={color} onChange={(e)=>{updateColor(type, e.target.value)}}/> : <span className='text-zinc-500 '>Add</span> }
              <div>
                <div
                  className="w-5 h-5 rounded-full shadow-sm border-[0.5px] border-zinc-500"
                  style={{ backgroundColor: color }}
                >
                  <input
                  ref={colorInputRef}
                  type="color"
                  value={color}
                  onChange={(e)=>{updateColor(type, e.target.value)}}
                  className="opacity-0"
                />
                </div>
              </div>
            </div>

            <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
              <button onClick={()=>{updateBorderStyle(type, 'none')}} className={'flex justify-center items-center py-1 w-[33%] rounded-md text-xs ' +`${borderStyle=='none'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <span className='text-xs'>none</span>
              </button>
              <button onClick={()=>{updateBorderStyle(type, 'solid')}} className={'flex justify-center items-center py-1 w-[33%] rounded-md text-xs ' +`${borderStyle=='solid'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <span className='text-xs'>solid</span>
              </button>
              <button onClick={()=>{updateBorderStyle(type, 'dashed')}} className={'flex justify-center items-center py-1 w-[33%] rounded-md text-xs ' +`${borderStyle=='dashed'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
                <span className='text-xs'>dashed</span>
              </button>
            </div>
          </div>
        </DialogBox>
      </div>
    </>
  )
}

export default Border