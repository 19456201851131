import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext'
import { SpaceContext } from 'react-zoomable-ui';

const DragHoverOverlay = () => {
  const {dropComponentRect} = useContext(CanvasContext);
  const context = useContext(SpaceContext);
  const [translatedRect, setTranslatedRect] = useState(null);

  useEffect(()=>{
    if (dropComponentRect) {
      setTranslatedRect(context.viewPort.translateClientRectToVirtualSpace(dropComponentRect));
    } else {
      setTranslatedRect(null);
    }
  }, [dropComponentRect])

  return (
    <div>
      {translatedRect && (
        <div style={{pointerEvents: "none", position: "absolute", top: `${translatedRect.top}px`, left: `${translatedRect.left}px`}}>
          <div className="border-2 border-yellow-600 " style={{position: "relative", width:`${translatedRect.width}px`, height: `${translatedRect.height}px`}}>
            <div className='w-full h-full bg-yellow-600 opacity-20'></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DragHoverOverlay