import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CanvasContext from '../context/CanvasContext';

const useRecoilStateToValues = () => {
  const {atomValues} = useContext(CanvasContext); 

  const atomValuesRef = useRef(atomValues);

  // Update the ref each time the state changes
  useEffect(() => {
    atomValuesRef.current = atomValues;
  }, [atomValues]);

  const recoilStateToJsonObj = (state) => {
    if (!atomValuesRef.current || !state || !state.key) 
      return;
    const stateValues = atomValuesRef.current[state.key];
    return {...stateValues, children: stateValues?.children?.map(child => {
      if (typeof child === 'string') {
        return child;
      } else {
        return recoilStateToJsonObj(child);
      }
    })}
  };

  return recoilStateToJsonObj;
}

export default useRecoilStateToValues