import React, { useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LogIn from "./components/LogIn";
import AppLayout from "./components/app/workspace/layout/AppLayout";
import RequireAuth from "./components/RequireAuth";
import { PersistLogin } from "./components/PersistLogin";
import MissingPage from "./components/MissingPage";
import AccountLayout from "./components/app/account/layout/AccountLayout";
import WorkSpace from "./components/app/workspace/WorkSpace";
import { Helmet } from "react-helmet";
import HtmlPreview from "./components/app/HtmlPreview";
import Subscription from "./components/app/account/Subscription";
import Templates from "./components/app/account/Templates";
import Community from "./components/app/account/Community";
import Sites from "./components/app/account/Sites";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 500,
      delay: 300
    });
    // Optionally: Configure AOS for animations such as duration, easing, etc.
    // AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Helmet>
        <script src="https://cdn.tailwindcss.com/3.4.3"/>
      </Helmet>
      <Routes>
        {/* <Route path="/"> */}
          {/* <Route path="landing" element={<LandingPage/>}/> */}
          {/* protected routes */}
          <Route path="/" element={<PersistLogin />}>
            <Route path="login" element={<LogIn/>}/>
            <Route element={<RequireAuth allowedRoles={['User','Admin','Basic','Pro', 'Enterprise']}/>}>
              <Route element={<AccountLayout/>}>
                <Route path='subscription' element={<Subscription/>}/>
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={['Admin']}/>}>
              <Route element={<AccountLayout/>}>
                <Route path='admin' element={<Sites />}/>
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={['Admin', 'Basic', 'Pro', 'Enterprise']}/>}>
              <Route element={<AccountLayout/>}>
                <Route index element={<Sites />}/>
                <Route path='templates' element={<Templates/>}/>
                <Route path='help' element={<Community/>}/>
              </Route>
              <Route path="workspace/:siteId" element={<AppLayout/>}>
                <Route index element={<WorkSpace/>}/>
              </Route>
              <Route path="preview/:siteId" element={<HtmlPreview/>}/>
            </Route>
          </Route>
        {/* </Route> */}
        <Route path='*' element={<MissingPage /> } status={404}/>
      </Routes>
    </>
  );
}

export default App;
