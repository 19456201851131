import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { RiTwitterXLine } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { useAuth } from "../hooks/useAuth";
import { BASE_URL } from "../api/axios";
import { IoIosArrowRoundBack } from "react-icons/io";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri"; // Import the eye icons

const EmailLogin = ({
  setEmailLogin,
  setUserDetails,
  userDetails,
  handleLogin,
  handleSignup,
  isLogin,
  setIsLogin,
  initialUserDet,
}) => {
  const [showPass, setShowPass] = useState(false);

  const handleDetails = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  return (
    <div className="border border-zinc-800 rounded-2xl px-10 py-20 flex flex-col items-center gap-5">
      <div className="self-stretch flex justify-between">
        <IoIosArrowRoundBack
          onClick={() => setEmailLogin(false)}
          size={26}
          className="self-start cursor-pointer"
        ></IoIosArrowRoundBack>
        <p
          onClick={() => {
            setIsLogin(!isLogin);
            setUserDetails(initialUserDet);
          }}
          className="cursor-pointer underline"
        >
          {isLogin ? "Signup" : "Login"}
        </p>
      </div>

      <div className="flex border-t- font- items-center gap-2">
        <Link
          to="/"
          className="flex items-center gap-1 text-white text-2xl font-bold font-webstitch"
        >
          <img src="/icon.webp" className="h-9 w-9" />
          Makeweb.ai
        </Link>
      </div>
      <p className="text-md mt-5">
        {isLogin ? "Login with email" : "Sign up with email"}
      </p>
      {!isLogin ? (
        <>
          {" "}
          <input
            name="firstName"
            placeholder="Enter first name"
            className="bg-zinc-900 text-lg border border-zinc-800 rounded-md min-w-[22rem] py-2 pl-4 pr-0 text-zinc-300 tracking-tighter hover:bg-zinc-800 duration-300"
            value={userDetails.firstName}
            onChange={handleDetails}
          />
          <input
            name="lastName"
            placeholder="Enter last name"
            className="bg-zinc-900 text-lg border border-zinc-800 rounded-md min-w-[22rem] py-2 pl-4 pr-0 text-zinc-300 tracking-tighter hover:bg-zinc-800 duration-300"
            value={userDetails.lastName}
            onChange={handleDetails}
          />
        </>
      ) : (
        ""
      )}
      <input
        name="email"
        placeholder="Enter email"
        className="bg-zinc-900 text-lg border border-zinc-800 rounded-md min-w-[22rem] py-2 pl-4 pr-0 text-zinc-300 tracking-tighter hover:bg-zinc-800 duration-300"
        value={userDetails.email}
        onChange={handleDetails}
      />
      <div className="relative w-full">
        <input
          type={showPass ? "text" : "password"}
          name="password"
          placeholder="Enter password"
          className="min-w-[22rem] bg-zinc-900 text-lg border border-zinc-800 rounded-md w-full py-2 pl-4 pr-12 text-zinc-300 tracking-tighter hover:bg-zinc-800 duration-300"
          value={userDetails.password}
          onChange={handleDetails}
        />
        <div
          className="md:pr-4 absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer text-zinc-300"
          onClick={() => setShowPass(!showPass)}
        >
          {showPass ? <RiEyeOffLine size={20} /> : <RiEyeLine size={20} />}
        </div>
      </div>
      <button
        onClick={isLogin ? handleLogin : handleSignup}
        className="bg-lime-700 text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-100 tracking-tighter flex items-center gap-2 hover:bg-lime-600 duration-300 cursor-pointer"
      >
        {isLogin ? "Login" : "Sign up"}
      </button>
    </div>
  );
};

export default EmailLogin;
