import React from 'react'
import { MdOutlineMailOutline } from 'react-icons/md'
import { PiTelegramLogoBold } from 'react-icons/pi'
import useDocTitle from '../../../hooks/useDocTitle';


const Community = () => {
  useDocTitle('Community | MagicWeb.ai');
  return (
    <div className="flex flex-col gap-10">
      <div className='flex flex-col gap-2'>
        <p className="text-xl font-semibold text-zinc-200">Help</p>
      </div>
      {/* <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col'>
        <span className='flex flex-col gap-5'>
          <p className='font-bold text-lg'>Telegram</p>
          <div className='flex justify-between flex-col gap-5 lg:flex-row lg:gap-10'>
            <span>
              We invite you to join our exclusive Telegram group, a dedicated space for all our users. Here, you can easily get assistance with any aspect of our app, report bugs, or suggest new features you'd love to see. It's also a great place to connect with fellow users and share insights. Your input helps us grow and improve, so we're excited to see you there!
            </span>
            <a target='_blank' href="https://t.me/+W68OB6LyacUzNzhl" className='bg-lime-600 text-zinc-200 px-4 h-10 w-fit rounded-lg flex justify-center items-center gap-2'>
              <PiTelegramLogoBold className='w-5 h-5'/>
              Open
            </a>
          </div>
          
        </span>
      </div> */}
      <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col'>
        <span className='flex flex-col gap-5'>
          <p className='font-bold text-lg'>Email</p>
          <div className='flex justify-between  flex-col gap-5 lg:flex-row lg:gap-10'>
            <span>Pleas email us at <span className='font-semibold text-zinc-100'>hello@magicweb.ai</span> for any doubts or assistance with the application.</span>
            <a target='_blank' href="https://t.me/+W68OB6LyacUzNzhl" className='bg-lime-600 text-zinc-200 px-4 py-2 w-fit rounded-lg flex justify-center items-center gap-2'>
              <MdOutlineMailOutline className='w-5 h-5'/>
              Open
            </a>
          </div>
        </span>
      </div>
    </div>
  )
}

export default Community