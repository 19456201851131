import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Space, suppressBrowserZooming } from 'react-zoomable-ui';
import domToImage from 'dom-to-image';
import CanvasPreview from './CanvasPreview';
import CanvasContext from '../../../context/CanvasContext';
import useRecoilStateToValues from '../../../hooks/useRecoilStateToValues';
import useUndo from '../../../hooks/useUndo';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useAuth } from '../../../hooks/useAuth';
import { jsonObjToRecoilState } from '../../../componentStates/atoms';
import useCopy from '../../../hooks/useCopy';
import usePaste from '../../../hooks/usePaste';

const WorkSpace = () => {
  const { siteId } = useParams();
  
  const {auth} = useAuth();
  const { siteRecord, setSiteRecord, canvasRef, setSelectedState, pageState, setPageState, pagesState, setPagesState, dummyAtom, isCanvasLoading, setIsCanvasLoading, setSelectedParentState, setSelectedParentChildIndex, setSelectedComponentRect, editMode, atomValues} = useContext(CanvasContext);
  const [searchParams] = useSearchParams();
  const [websiteLoaded, setWebsiteLoaded] = useState(false);
  // update title on siteRecord update
  useEffect(()=>{
    if (siteRecord?.name) {
     document.title = siteRecord.name + ' - MagicWeb.ai'
    }
  }, [siteRecord])
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // fetch website data on auth completion
  useEffect(()=>{
    const fetchSite = async () => {
      setIsCanvasLoading(true);
      try {
        const resp = await axiosPrivate.get(`/website/${siteId}`);
        if (resp.data && resp.data._id === siteId) {
          setSiteRecord(resp.data);
          setWebsiteLoaded(true);
        } else {
          throw Error("Unexpected Response from server");
        }
      } catch (err) {
        navigate('/');
      }
    }
    if (auth.accessToken) {
      fetchSite();
    }
  },[auth])

  // function to update pageState based on current values of siteRecord and pagePath
  const updateCanvas = useCallback((pagePath) => {
    try {
      if (typeof siteRecord == 'object' && Object.keys(siteRecord).length &&  Object.keys(siteRecord.devPages).length) {
        // setting the start timestamp to avoid any unnecessary initial save
        setCurrTimestamp(Math.floor(Date.now()/saveDelay));

        if (pagesState[pagePath]) {
          setPageState(pagesState[pagePath]);
        } else if (siteRecord.devPages[pagePath]?.code) {
          setIsCanvasLoading(true);
          const pageRecoilState = jsonObjToRecoilState(siteRecord.devPages[pagePath].code);
          setPagesState(curr => {
            const newPagesState = {...curr};
            newPagesState[pagePath] = pageRecoilState;
            return newPagesState;
          })
          setPageState(pageRecoilState);
        } else {
          navigate(`/workspace/${siteId}?page=`);
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [siteRecord, searchParams]);
  
  // update canvas only when pagePath changes
  useEffect(()=>{
    if (websiteLoaded) {
      updateCanvas(searchParams.get("page") || "/");
    }
  },[searchParams, websiteLoaded])
  
  useEffect(suppressBrowserZooming);
  const resetSelectedState = (e) => {
    e.stopPropagation();
    if (editMode) {
      setSelectedState(pageState);
      setSelectedParentState(pageState);
      setSelectedParentChildIndex(0);
      setSelectedComponentRect(null);
    }
  }

  const recoilStateToJsonObj = useRecoilStateToValues();
  const [currTimestamp, setCurrTimestamp] = useState(0)
  const saveDelay = 3000;
  const captureCanvas = useCallback(async () => {
    if (canvasRef.current) {
      // Capture the full canvas as an image
      const dataUrl = await domToImage.toJpeg(canvasRef.current, { quality: 0.1, cacheBust: true, useCORS: true });
  
      // Create an image element
      const image = new Image();
      image.src = dataUrl;
  
      // Draw the image onto the off-screen canvas when it loads
      return new Promise(resolve => {
        image.onload = () => {
          // Create an off-screen canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
      
          const requiredHeight = Math.round(image.width * 7/10);
          // Set dimensions for the off-screen canvas to the target width and temporary height
          canvas.width = image.width/2;  // Desired width
          // Height is set temporarily to a scaled value

          // Set the height of the off-screen canvas to the scaled height or the crop height, whichever is smaller
          canvas.height = Math.min(image.height, requiredHeight)/2;
  
          // Draw the image on canvas - resize width to 400px and use the scaled height
          ctx.drawImage(image, 0, 0, image.width, requiredHeight, 0, 0, image.width/2, requiredHeight/2);
  
          // Convert the visible part of the canvas (first 200px height) to a data URL
          const croppedImageUrl = canvas.toDataURL('image/webp', 0.2); // Adjust quality as needed
          resolve(croppedImageUrl);
        };
      });
    } else {
      return  "";
    }
  }, [canvasRef]);
  const saveThumbnail = async () => {          
    try {
      const canvasSnapshot = await captureCanvas();
      const resp = await axiosPrivate.patch("/website", {id: siteId, thumbnail:canvasSnapshot});
      if (resp.status !== 200) {
        throw Error("thumbnail save err from server");
      }
    } catch (err) {
      console.log(err);
    } finally {

    }
  }
  useEffect(()=>{
    const newTimestamp = Math.floor(Date.now()/saveDelay);
    // console.log("triggerred");
    if (Object.keys(atomValues).length > 0 && newTimestamp !== currTimestamp) {
      setTimeout(()=>{
        const saveCode = async () => {          
          try {
            const code = recoilStateToJsonObj(pageState);
            const pages = {};
            pages[searchParams.get("page") || "/"] = {code};
            const resp = await axiosPrivate.patch("/website", {id: siteId, pages});
            // console.log("resp:",resp);
          } catch (err) {
            console.log(err);
          } finally {
          }
        }
        saveCode();
        saveThumbnail();
      },saveDelay);
      setCurrTimestamp(newTimestamp);
    }
  },[atomValues]);
  useUndo();
  useCopy();
  usePaste();
  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }} onClick={resetSelectedState}>
      <Space
        className="bg-zinc-800"
        treatTwoFingerTrackPadGesturesLikeTouch
      >
        {pageState === dummyAtom || isCanvasLoading?
          <div className='w-[520px] h-80 bg-zinc-600 animate-pulse translate-x-[700px] translate-y-[200px]'></div>
          :
          <CanvasPreview />
        }
        
      </Space>
    </div>
  )
}

export default WorkSpace