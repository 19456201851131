import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../context/CanvasContext';
import CompEditable from '../CompEditable';
import { FaPlay } from "react-icons/fa";
import DialogBox from './comps/DialogBox';
import { FaAngleRight } from "react-icons/fa";
import SelectionOverlay from './overlays/SelectionOverlay';
import HoverOverlay from './overlays/HoverOverlay';
import DragHoverOverlay from './overlays/DragHoverOverlay';
import { NoPanArea } from 'react-zoomable-ui';

const TOP_BOTTOM_MARGIN = 70;

const CanvasPreview = () => {
  
  const { canvasRef, screenType, SCREEN, setScreenType, siteRecord, pageState, isCanvasLoading, setIsCanvasLoading, editMode } = useContext(CanvasContext);
  useEffect(()=>{
    setIsCanvasLoading(false);
  },[])

  const [screenTypeMenuAppear, setScreenTypeMenuAppear] = useState(false);

  // Calculate the translated X and Y coordinates to center the Preview
  const translateX = (((window.innerWidth - SCREEN[screenType].width*SCREEN[screenType].scale) / 2) + 256) / SCREEN[screenType].scale;
  const translateY = (((window.innerHeight - TOP_BOTTOM_MARGIN - SCREEN[screenType].height*SCREEN[screenType].scale) / 2) + TOP_BOTTOM_MARGIN) / SCREEN[screenType].scale;

  const [previewStyle, setPreviewStyle] = useState({
    width:SCREEN[screenType].width, 
    minHeight: SCREEN[screenType].height,
    transformOrigin: 'top left',
    transform: `scale(${SCREEN[screenType].scale}) translate(${translateX}px, ${translateY}px)`
  });

  useEffect(()=>{
    // Calculate the translated X and Y coordinates to center the Preview
    const translateX = ((window.innerWidth - SCREEN[screenType].width*SCREEN[screenType].scale) / 2 + 256) / SCREEN[screenType].scale;
    const translateY = (((window.innerHeight - TOP_BOTTOM_MARGIN - SCREEN[screenType].height*SCREEN[screenType].scale) / 2) + TOP_BOTTOM_MARGIN) / SCREEN[screenType].scale;

    setPreviewStyle({
      width:SCREEN[screenType].width, 
      transformOrigin: 'top left',
      transform: `scale(${SCREEN[screenType].scale}) translate(${translateX}px, ${translateY}px)`
    });
  },[screenType])

  const makeScreenTypeMenuAppear = () => {
    setScreenTypeMenuAppear(!screenTypeMenuAppear);
  };

  return (
    <>
      <div 
        style={previewStyle}
        className="flex flex-col relative"
      >
        {/* the top bar on the canvas render */}
        <div className='fixed top-[-110px] left-0 group bg-black w-full rounded-2xl p-6 text-xl tracking-widest flex justify-between items-center'>
          <button className='flex justify-center items-center gap-3 text-zinc-400 font-semibold capitalize'>
            <a href={`/preview/${siteRecord._id}`} target="_blank"  className='w-9 h-9 flex justify-center items-center bg-zinc-800 group-hover:bg-lime-600 group-hover:text-zinc-200 rounded-lg'><FaPlay className='w-3 h-3'/></a>
            <span className='group-hover:text-lime-200 text-2xl'>
              {isCanvasLoading? 'Loading...' : siteRecord.name}
            </span>
          </button>
          <div onClick={makeScreenTypeMenuAppear} className='text-zinc-300 bg-zinc-800 hover:bg-lime-600 duration-100 px-2 rounded-lg capitalize relative flex'>
            <div className='flex justify-center items-center gap-1 p-1'>{screenType} <FaAngleRight className='w-4 h-4'/></div>
            <DialogBox appear={screenTypeMenuAppear} setAppear={setScreenTypeMenuAppear}>
              <div className='absolute left-[115%] flex flex-col gap-2 bg-zinc-900 rounded-lg py-4 z-200 border border-zinc-700'>
                <span className='px-6 hover:bg-zinc-800' onClick={()=>{setScreenType('desktop')}}>desktop</span>
                <span className='px-6 hover:bg-zinc-800' onClick={()=>{setScreenType('tablet')}}>tablet</span>
                <span className='px-6 hover:bg-zinc-800' onClick={()=>{setScreenType('mobile')}}>mobile</span>
              </div>
            </DialogBox>
          </div>
        </div>
        {/* making this NoPanArea to allow for drag and drop */}
        <NoPanArea>
          {/* the website render */}
          <div ref={canvasRef} className='overflow-hidden'>
            {!isCanvasLoading && <CompEditable  recoilState={pageState} parentRecoilState={pageState} editable={editMode} sectionChildIndex={-1}/>}
          </div>
        </NoPanArea>
        {/* {image && <img src={image} className='w-32 h-auto'/>} */}
      </div>
      <HoverOverlay/>
      <SelectionOverlay/>
      <DragHoverOverlay/>
      {/* <DragCursorOverlay /> */}
    </>
  )
}

export default CanvasPreview