// import * as AllComponents from '@radix-ui/themes';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import { useRecoilState } from "recoil";
import StringWrapper from "./workspace/StringWrapper";
import CanvasContext from "../../context/CanvasContext";
import UserContext from "../../context/UserContext";
import { useDrag, useDrop } from "react-dnd";
import { useMergedRefs } from "../../hooks/useMergedRefs";
import { selfClosingHTMLTags } from "../../helpers/helpers";
import useTrackedSetRecoilState from "../../hooks/useTrackedSetRecoilState";

const CompEditable = ({
  recoilState,
  parentRecoilState,
  parentChildIndex,
  sectionChildIndex,
  editable,
}) => {
  const key = recoilState?.key;
  const [compState] = useRecoilState(recoilState);
  const setCompState = useTrackedSetRecoilState(recoilState);
  const compRef = useRef(null);
  const [isDragHover, setIsDragHover] = useState(false);
  const { user } = useContext(UserContext);

  const {
    SCREEN,
    screenType,
    setAtomValues,
    pageState,
    hoveredState,
    setHoveredState,
    selectedState,
    setSelectedState,
    setSelectedParentState,
    setSelectedParentChildIndex,
    setHoveredParentState,
    setHoveredParentChildIndex,
    setSelectedCompStyle,
    setSelectedComponentRect,
    setHoveredComponentRect,
    setDropComponentRect,
    dragImage,
  } = useContext(CanvasContext);
  const setPageAtomState = useTrackedSetRecoilState(pageState);
  const setParentAtomState = useTrackedSetRecoilState(parentRecoilState);

  const [, dropRef] = useDrop({
    accept: "component",
    drop: (item, monitor) => {
      setDropComponentRect(null);
      if (monitor.isOver({ shallow: true })) {
        console.log("before create function");
        const newCompState = item?.asset?.create(user);
        if (!item?.asset?.categ?.includes("sections")) {
          setCompState((prev) => ({
            ...prev,
            children: [...prev.children, newCompState],
          }));
        } else {
          if (key === pageState.key) {
            setPageAtomState((prev) => {
              const newChildren = [...prev.children];
              newChildren.push(newCompState);
              return { ...prev, children: newChildren };
            });
          } else {
            setPageAtomState((prev) => {
              const newChildren = [...prev.children];
              newChildren.splice(sectionChildIndex + 1, 0, newCompState);
              return { ...prev, children: newChildren };
            });
          }
        }
        setSelectedState(newCompState);
      }
    },
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        setIsDragHover(true);
      } else {
        setIsDragHover(false);
      }
    },
  });

  // drag drop hover state functions. it sets the current comp to the hovered drop zone
  const setDropZone = () => {
    if (compRef.current) {
      setDropComponentRect(compRef.current.getBoundingClientRect());
    }
  };
  const removeDropZone = () => {
    if (key == pageState.key) {
      setDropComponentRect(null);
    }
  };

  useEffect(() => {
    if (isDragHover) {
      setDropZone();
    } else {
      removeDropZone();
    }
  }, [isDragHover]);

  // DnD within parent
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: parentRecoilState.key,
      item: () => {
        preview(dragImage, { captureDraggingState: true });
        return { recoilState, parentChildIndex };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [recoilState, parentChildIndex, dragImage]
  );

  const [, dropRefParent] = useDrop({
    accept: parentRecoilState.key,
    drop: (item, monitor) => {
      if (parentChildIndex === item.parentChildIndex) {
        return;
      }
      setParentAtomState((prev) => {
        const newChildren = [...prev.children];
        newChildren.splice(item.parentChildIndex, 1);
        newChildren.splice(parentChildIndex, 0, item.recoilState);
        return { ...prev, children: newChildren };
      });
    },
    hover: (item, monitor) => {},
  });

  // this collects all the atom values inside a mapping to be used later
  // for converting atom to values in recursive fashion
  useEffect(() => {
    setAtomValues((prev) => {
      const newAtomValues = { ...prev };
      newAtomValues[recoilState.key] = compState;
      return newAtomValues;
    });
  }, [compState]);

  // this computes current comp style for selected comp as source of truth to display on props panel
  useEffect(() => {
    if (selectedState.key == key && compRef.current) {
      const style = window.getComputedStyle(compRef.current);
      setSelectedCompStyle(style);
    }
  }, [compRef, selectedState]);

  // functions for onMouseOver and onMouseLeave for hover and onClick for selection
  const setHoveredStateToCurrent = (e) => {
    e.stopPropagation();
    setHoveredState(recoilState);
  };

  const setHoveredStateToNull = (e) => {
    e.stopPropagation();
    setHoveredState(pageState);
  };

  const setSelectedCompToCurr = (e) => {
    e.stopPropagation();
    setSelectedState(hoveredState);
  };

  // changing state after selected comp changes
  useEffect(() => {
    if (selectedState.key == key) {
      if (parentRecoilState?.key) {
        setSelectedParentState(parentRecoilState);
        setSelectedParentChildIndex(parentChildIndex);
      }
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState.key == key) {
      setTimeout(() => {
        if (compRef.current) {
          setSelectedComponentRect(compRef.current.getBoundingClientRect());
        }
      }, 50);
    }
  }, [selectedState, compState, compRef]);

  useEffect(() => {
    if (hoveredState && hoveredState.key == key) {
      if (parentRecoilState?.key) {
        setHoveredParentState(parentRecoilState);
        setHoveredParentChildIndex(parentChildIndex);
      }
      if (compRef.current) {
        setHoveredComponentRect(compRef.current.getBoundingClientRect());
      }
    }
  }, [hoveredState]);

  const mergedRefs = useMergedRefs(compRef, dropRef, dragRef, dropRefParent);
  const mergedRefsForPageState = useMergedRefs(compRef, dropRef);

  const { compType, compName, compProps, style, className, children } =
    compState;
  const [filteredClassName, setFilteredClassName] = useState(className);

  useEffect(() => {
    let newClassName = className;
    if (screenType == "tablet") {
      // Remove classes starting with lg, xl, 2xl
      newClassName = newClassName.replace(
        /\blg:[^ ]*\b|\bxl:[^ ]*\b|\b2xl:[^ ]*\b/g,
        ""
      );
    } else if (screenType == "mobile") {
      // Remove classes starting with md, lg, xl, 2xl
      newClassName = newClassName.replace(
        /\bsm:[^ ]*\b|\bmd:[^ ]*\b|\blg:[^ ]*\b|\bxl:[^ ]*\b|\b2xl:[^ ]*\b/g,
        ""
      );
    }
    newClassName = newClassName.replace(/w-screen/g, "w-full");
    newClassName = newClassName.replace(
      /h-screen/g,
      `h-[${SCREEN[screenType].height}px]`
    );
    setFilteredClassName(newClassName);
  }, [className, screenType]);
  const [filteredCompProps, setFilteredCompProps] = useState(compProps);
  useEffect(() => {
    if (compName === "a") {
      const { href, ...compProps2 } = compProps;
      compProps2["data-aos"] = undefined;
      setFilteredCompProps(compProps2);
    } else {
      const { ...compProps2 } = compProps;
      if (compProps2["data-aos"]) compProps2["data-aos"] = null;
      setFilteredCompProps(compProps2);
    }
  }, [compProps, compName]);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  // // to skip render if the element name doesn't exist
  // if (compType === 'radix' && !AllComponents[compName]) {
  //   console.log(compName + ` not a valid ${compType} component. Skipped for rendering.`);
  //   return <></>;
  // }

  // const DynamicComponent = compType === 'jsx'? compName : AllComponents[compName];
  const DynamicComponent = compName === "form" ? "div" : compName;
  const selfClosing = selfClosingHTMLTags.includes(compName);

  return (
    <>
      {!selfClosing ? (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={filteredClassName}
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
        >
          {children.map((child, index) => {
            return typeof child === "string" ? (
              editable ? (
                <StringWrapper
                  key={child}
                  parentRecoilState={recoilState}
                  childListIndex={index}
                >{` ${child}`}</StringWrapper>
              ) : (
                child
              )
            ) : (
              <CompEditable
                key={child.key}
                recoilState={child}
                parentRecoilState={recoilState}
                parentChildIndex={index}
                sectionChildIndex={
                  sectionChildIndex === -1 ? index : sectionChildIndex
                }
                editable={editable}
              />
            );
          })}
        </DynamicComponent>
      ) : compName === "img" ? (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={
            isImgLoaded ? filteredClassName : "bg-zinc-400 " + filteredClassName
          }
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
          editable={editable ? "true" : "false"}
          onLoad={() => {
            setIsImgLoaded(true);
          }}
          onError={() => {
            setIsImgLoaded(true);
          }}
        />
      ) : (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={filteredClassName}
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
          editable={editable}
        />
      )}
    </>
  );
};

export default CompEditable;
