import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { FaRegSquare } from"react-icons/fa6";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const TextSize = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const [size, setSize] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  
  useEffect(()=>{
    if (selectedCompStyle?.fontSize) {
      setSize(parseInt(selectedCompStyle.fontSize));
    } else {
      setSize(0);
    }
    
  },[selectedCompStyle])

  const updateSize = (size) => {
    if (!selectedState) {
      return;
    }
    if (size || size === 0) {
      setSize(size);
      
      setState((prevState) => ({...prevState, style: {...prevState.style, fontSize: size+"px"}}));
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16'>Size</p>
      <div className={"col-span-2 flex items-center gap-1 text-white propUnitBox pl-2 w-full"+`${isFocused? ' border border-lime-600' : ''}`}>
        <FaRegSquare className='w-3 h-3 text-zinc-500'/>
        <input type="text" className='bg-transparent outline-none h-3 w-[70%] px-2 text-xs tracking-widest text-zinc-400'
          value={size}
          onChange={(e)=>{
            if(e.target.value) {
              updateSize(e.target.value);
            } else {
              updateSize(0);
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}  
        />
        <div className='flex flex-col gap-0 justify-center items-center h-5 px-1'>
          <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateSize(size+1)}}/>
          <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (size>0) updateSize(size-1);}}/>
        </div>
      </div>
    </>
  )
}

export default TextSize