import React from 'react'

const LoadingTile = () => {
  return (
    <div className='flex flex-col gap-3 animate-pulse w-full md:w-auto'>
        <div className='w-full md:w-96 h-48 md:h-60 rounded-xl bg-zinc-900 '></div>
        <div className="w-40 h-3 rounded-xl bg-zinc-900"></div>
        <div className="w-48 h-2 rounded-xl bg-zinc-900"></div>
    </div>
  )
}

export default LoadingTile