import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { RiTwitterXLine } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { useAuth } from "../hooks/useAuth";
import { BASE_URL, axiosPrivate } from "../api/axios";
import useDocTitle from "../hooks/useDocTitle";
import EmailLogin from "./EmailLogin";
import toast, { Toaster } from "react-hot-toast";

const LogIn = () => {
  useDocTitle("Login | Makeweb.ai");
  // const [emailUpdates, setEmailUpdates] = useState(true);
  const [emailLogin, setEmailLogin] = useState(false);

  const navigate = useNavigate();

  const initialUserDet = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const [userDetails, setUserDetails] = useState(initialUserDet);

  const [isLogin, setIsLogin] = useState(true);

  const { auth, setAuth } = useAuth();
  const location = useLocation();

  const handleLogin = async () => {
    if (!userDetails.email || !userDetails.password) {
      return toast.error("Email and password required");
    }

    try {
      const loginPayload = {
        email: userDetails.email,
        password: userDetails.password,
      };

      const resp = await axiosPrivate.post("/auth/signin", loginPayload);

      if (resp.status == 200) {
        toast.success("Login success");

        setTimeout(() => {
          setAuth((prevAuth) => {
            return {
              ...prevAuth,
              roles: resp?.data?.roles,
              accessToken: resp?.data?.accessToken,
            };
          });
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";
      toast.error(message);
    }
  };

  const handleSignup = async () => {
    if (
      !userDetails.firstName ||
      !userDetails.lastName ||
      !userDetails.email ||
      !userDetails.password
    )
      return toast.error("All fields are required");

    if (userDetails.password.length < 8)
      return toast.error("Password must be 8 plus characters.");

    try {
      const resp = await axiosPrivate.post("/register/singup", userDetails);

      toast.success(resp.data.message);

      setTimeout(() => {
        setIsLogin(true);
        setUserDetails(initialUserDet);
      }, 2000);
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";
      toast.error(message);
    }
  };

  return auth.accessToken ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <div className="bg-black text-white  min-h-screen font-sans flex justify-center items-center">
      {!emailLogin ? (
        <div className="border border-zinc-800 rounded-2xl px-10 py-20 flex flex-col items-center gap-5">
          <div className="flex border-t- font- items-center gap-2">
            <Link
              to="/"
              className="flex items-center gap-1 text-white text-2xl font-bold font-webstitch"
            >
              <img src="/icon.webp" className="h-9 w-9" />
              Makeweb.ai
            </Link>
          </div>
          <p className="text-md mt-5">Sign in to start the magic</p>
          {/* <button
            onClick={() => {
              setEmailLogin(true);
            }}
            href={`${BASE_URL}/auth/twitter`}
            className="bg-zinc-900 text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-300 tracking-tighter flex items-center gap-2 hover:bg-zinc-800 duration-300 cursor-pointer"
          >
            <MdOutlineMailOutline size={22} className="inline-block" />
            Continue with Email
          </button> */}
          <a
            href={`${BASE_URL}/auth/google`}
            className="bg-zinc-900 text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-300 tracking-tighter flex items-center gap-2 hover:bg-zinc-800 duration-300"
          >
            <FcGoogle className="inline-block" />
            Continue with Google
          </a>

          <button
            disabled
            href={`${BASE_URL}/auth/twitter`}
            className="bg-zinc-900 disabled:bg-black text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-300 tracking-tighter flex items-center gap-2 duration-300"
          >
            <RiTwitterXLine className="inline-block" />
            Continue with Twitter
          </button>
          {/* <div className='text-xs flex flex-wrap w-0'><input type='checkbox' value={emailUpdates} onChange={()=>{}}/> I acknowledge to receive product update and offers from Makeweb.ai</div> */}
        </div>
      ) : (
        <EmailLogin
          setEmailLogin={setEmailLogin}
          setUserDetails={setUserDetails}
          userDetails={userDetails}
          handleLogin={handleLogin}
          handleSignup={handleSignup}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
          initialUserDet={initialUserDet}
        />
      )}
      <Toaster />
    </div>
  );
};

export default LogIn;
