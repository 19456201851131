import { useMemo } from "react";

export const useMergedRefs = (...refs)=>{
  return useMemo(() => {
    if (refs.every(ref => ref == null)) {
      return null;
    }
    return node => {
      refs.forEach(ref => {
        if (ref) {
          if (typeof ref === 'function') {
              ref(node);
          } else {
              ref.current = node;
          }
        }
      });
    };
  }, [refs]);
}