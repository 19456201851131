import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SiteName = ({siteId, name, nameRef}) => {
  const axiosPrivate = useAxiosPrivate();
  const [isPatching, setIsPatching] = useState(false);
  const [patchError, setPatchError] = useState("");
  const updateSiteName = async (e) => {
    if (e.target.innerText !== name) {
      setIsPatching(true);
      setPatchError("");
      try {
        const resp = await axiosPrivate.patch("/website", {id: siteId, name: e.target.innerText});
        if (!(resp.status === 200 || resp.status === 204)) {
          throw Error("Unexpected response from server");
        }
      } catch (err) {
        console.log(err);
        setPatchError(err.message);
      } finally {
        setIsPatching(false);
      }
    }
  };

  useEffect(()=>{
    if (nameRef.current && document.activeElement === nameRef.current) {
      
    }
  },[nameRef])

  return (
    <div className='flex gap-2'>
      <span
        ref={nameRef}
        className='outline-none focus:text-zinc-100 focus:px-1 focus:cursor-text'
        contentEditable
        dangerouslySetInnerHTML={{ __html: String(name) }}
        onBlur={updateSiteName}
        onKeyDown={(e) => { if (nameRef.current && document.activeElement === nameRef.current && e.key === 'Enter') nameRef.current.blur()}}
      />
      {isPatching &&
        <AiOutlineLoading3Quarters className='w-4 h-auto animate-spin'/>
      }
    </div>
    
  )
}

export default SiteName