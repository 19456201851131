import React, { useCallback, useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { FaAngleDown } from "react-icons/fa6";
import classNames from 'classnames';

import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import DialogBox from './DialogBox';
import { classesToObj } from '../../../../helpers/helpers';
import { useRecoilValue } from 'recoil';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';


const MinHeight = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [minHeightType, setMinHeightType] = useState('');
  const [minHeight, setMinHeight] = useState('');
  const [tailwindDropdown, setTailwindDropdown] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const [customType, setCustomType] = useState('fixed');
  const [customMinHeight, setCustomMinHeight] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  
  
  const tailwindMinHeightClasses = [
    "min-h-full", "min-h-screen", "min-h-min", "min-h-max", "min-h-fit", "min-h-0", "min-h-1", "min-h-2", "min-h-3", "min-h-4", "min-h-5",
    "min-h-6", "min-h-7", "min-h-8", "min-h-9", "min-h-10", "min-h-11",
    "min-h-12", "min-h-14", "min-h-16", "min-h-20", "min-h-24", "min-h-28",
    "min-h-32", "min-h-36", "min-h-40", "min-h-44", "min-h-48", "min-h-52",
    "min-h-56", "min-h-60", "min-h-64", "min-h-72", "min-h-80", "min-h-96",
    "min-h-px", "min-h-0.5", "min-h-1.5", "min-h-2.5", "min-h-3.5",
    "min-h-svh", "min-h-lvh", "min-h-dvh",
    
  ];
  const tailwindMinHeightClassesResetObj = tailwindMinHeightClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{
    const customMinHeight = state.style?.minHeight;
    if (parseInt(customMinHeight) >= 0) {
      setMinHeightType('custom');
      if (customMinHeight.endsWith("%")) {
        setCustomType('rel');
      } else {
        setCustomType('fixed');
      }
      setCustomMinHeight(parseInt(customMinHeight));
    } else {
      const classNameObj = classesToObj(state.className)
      let found = '';
      tailwindMinHeightClasses.forEach((cls)=> {
        if (classNameObj[cls]) {
          found=cls;
        }
      })
      if (found) {
        setMinHeightType('tailwind');
        setMinHeight(found);
      } else {
        setMinHeightType('');
        setMinHeight('');
      }
      if (parseInt(selectedCompStyle.minHeight) >=0) {
        setCustomMinHeight(parseInt(selectedCompStyle.minHeight));
      }
      // ignoring w-[value]
    }
  },[state, selectedCompStyle])

  const updateState =  (minHeight, minHeightType)=>{
    if (minHeightType === 'tailwind') {
      setState((prevState) => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-h-\[[^\]]+\]\s?/g, ''); // removing any class with pattern min-h-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinHeightClassesResetObj};
        classObj[minHeight] = true;
        return {...prevState, className: classNames(classObj), style:{...prevState.style, minHeight:undefined}}
      });
    } else if (minHeightType === 'custom') {
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-h-\[[^\]]+\]\s?/g, ''); // removing any class with pattern min-h-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinHeightClassesResetObj}; // removing all other minHeight classes
        return {...prevState, style: {...prevState.style, minHeight: `${minHeight}${customType==='rel'? '%':'px'}`}, className: classNames(classObj)}
      })
    } else { // remove minHeight setting
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bmin-h-\[[^\]]+\]\s?/g, ''); // removing any class with pattern min-h-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindMinHeightClassesResetObj}; // removing all other minHeight classes
        return {...prevState, style: {...prevState.style, minHeight: undefined}, className: classNames(classObj)};
      })
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>MinHeight</p>
      <div className="col-span-2 flex flex-col gap-0 relative h-8">
        <button 
          className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full pl-3 pr-2"
          onClick={(e)=>{setTailwindDropdown(!tailwindDropdown)}}
        >
          <span className='h-8 flex items-center flex-grow text-left'>
            {minHeightType === '' && <span className="text-zinc-500">Select</span>}
            {minHeightType !== '' &&
            <div className='flex items-center justify-between pr-1 gap-1 w-full group'>
              <span className='w-24 '>{minHeightType==='tailwind'? minHeight : 'custom'}</span>
              <RxCross2 className='w-3 h-3 rounded-full bg-zinc-600 hidden group-hover:block' onClick={(e)=>{e.stopPropagation(); setMinHeightType('');setMinHeight('');setCustomMinHeight(0);updateState(0, '')}}/>
            </div> 
            }
          </span>
          <FaAngleDown className='w-3 h-3'/>
        </button>
        <DialogBox appear={tailwindDropdown} setAppear={setTailwindDropdown}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center border border-zinc-800'>
            <button onClick={()=>{setMinHeightType('custom');setTailwindDropdown(false)}} className={`hover:bg-zinc-700 ${minHeight == 'custom' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 `}>
              custom
            </button>
            {
              tailwindMinHeightClasses.map(minHeightCls => 
                <button
                  key={minHeightCls}
                  className={`hover:bg-zinc-700 ${minHeight == minHeightCls && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2`}
                  onClick={()=>{updateState(minHeightCls, 'tailwind')}}
                >
                  {minHeightCls}
                </button>
              )
            }
          </div>
        </DialogBox>
      </div>
      {minHeightType === 'custom' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className="h-8 flex flex-col text-white propUnitBox w-1/2">
              <button 
                className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full px-1"
                onClick={(e)=>{setCustomDropdown(!customDropdown)}}
              >
                <span className='h-8 flex items-center flex-grow text-left pl-1 capitalize'>
                  {customType}
                </span>
                <FaAngleDown className='w-3 h-3'/>
              </button>
              <DialogBox appear={customDropdown} setAppear={setCustomDropdown}>
                <div className='absolute bg-zinc-900 text-zinc-400 text-sm py-0 mt-1 max-h-32 overflow-y-auto rounded-md flex flex-col items-center z-50 border border-zinc-800'>
                  <button onClick={()=>{setCustomType('fixed'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'fixed' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    fixed
                  </button>
                  <button onClick={()=>{setCustomType('rel'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'rel' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    rel
                  </button>
                </div>
              </DialogBox>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox w-1/2 pl-1"+`${isFocused? ' border border-lime-600' : ''}`}>
              <input type="text" className='w-3/4 bg-transparent outline-none h-3 px-1 text-xs tracking-widest text-zinc-400'
                value={customMinHeight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateState(parseInt(e.target.value), 'custom');
                  } else {
                    updateState(0, 'custom');
                  }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateState(customMinHeight+1,'custom')}} />
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (customMinHeight > 0) updateState(customMinHeight-1,'custom')}} />
              </div>
            </div>
          </div>
        </> 
      }
      
    </>
  )
}

export default MinHeight