import React from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';

const Asset = ({asset}) => {
  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type: 'component',
    item: () => {
      return { asset }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }));

  return (
    <>
      {/* <DragPreviewImage connect={preview} src="/object.png" /> */}
      {/* <DragPreviewImage connect={preview} src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" /> */}
      {asset.thumbnail?
      <div className='flex flex-col gap-1'>
        <button src={asset.imgSrc} ref={dragRef}  className='bg-opacity-60 hover:bg-opacity-80 duration-200 rounded-2xl w-full h-auto flex justify-center items-center bg-zinc-800 cursor-grab'
          style={{
            transform: 'translate(0, 0)'
          }}
        >
          {asset.thumbnail}
        </button>
      </div>
        
        :
        asset.imgSrc?
        <img src={asset.imgSrc} ref={dragRef} className={`opacity-80 hover:opacity-100 duration-100 text-sm font-semibold tracking-widest rounded-3xl w-full h-auto flex justify-center items-center border-8 border-zinc-800 cursor-grab`}
          style={{
            transform: 'translate(0, 0)', // do not remove this. it helps in transparent drag preview
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          
        </img>
      :
        <button ref={dragRef} className={`opacity-70 hover:opacity-100 text-white bg-zinc-800  hover:bg-zinc-900 duration-100 text-sm font-semibold tracking-wide rounded-2xl w-full py-5 flex justify-center items-center cursor-grab`}
          style={{transform: 'translate(0, 0)'}} // do not remove this. it helps in transparent drag preview
        >
          {asset.name}
        </button>
      }
      
      
    </>
    
  )
}

export default Asset