import React, { useContext, useEffect, useState } from 'react';
import TextColor from './TextColor';
import PropOpen from './PropOpen';
import TextSize from './TextSize';
import { useRecoilValue } from 'recoil';
import CanvasContext from '../../../../context/CanvasContext';
import TextWeight from './TextWeight';

const Text = () => {
  const [open, setOpen] = useState(true);
  const {selectedState} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);

  const textComps = [
    'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'button', 'a',
    'span', 'label', 'title', 'textarea', 'blockquote', 'em', 'b', 'i', 'u',
    's', 'small', 'sub', 'sup'
  ]

  useEffect(()=>{
    setOpen(textComps.includes(state.compName))
  },[selectedState])
  return (
    <PropOpen title={"Text"} open={open} setOpen={setOpen}>
      <TextColor />
      <TextSize />
      <TextWeight />
    </PropOpen>
  )
}

export default Text