import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import NavPanel from './NavPanel'

const AccountLayout = () => {
  return (
    <div className="font-sans">
      {/* <He`ader /> */}
      <NavPanel />
      <div className='bg-black min-h-screen pl-16 md:pl-80'>
        <div className="pt-10 md:pt-24 px-10 md:px-24 2xl:px-52 pb-28 md:pb-40">
          <Outlet/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AccountLayout