import React, { useContext } from 'react'
import Avatar from './Avatar'
import UserContext from '../../../../context/UserContext';

const ChatUnit = ({chatContent, chatType}) => {
  const {userNameAbbr} = useContext(UserContext);
  return (
    <div className='flex gap-6'>
      <div className='w-6'>
        <Avatar size={8} bgColor={chatType == "AI" ? "bg-lime-600":"bg-zinc-800"}>
          { chatType == "AI" ? <svg xmlns="http://www.w3.org/2000/svg" width='18px' viewBox="0 0 24 24"  stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-mouse-pointer-square" fillOpacity="0"><path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"></path><path d="m12 12 4 10 1.7-4.3L22 16Z"></path></svg>
           : <span className='text-zinc-400 text-md'>{userNameAbbr}</span>}
        </Avatar>
      </div>
      
      <div className='text-zinc-500 text-[15px]'>
        <p className='font-bold mb-2 text-zinc-300'>{chatType == "AI" ? "AI" : "You"}</p>
        <p className='text-zinc-400 tracking-wide leading-relaxed'>
          { typeof chatContent === 'string'?
            chatContent.split('\n').map((line, index) => (
              // Render each line in a span, and put a <br> tag after each line except the last one
              <React.Fragment key={index}>
                {line}
                {index !== chatContent.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))
            :
            chatContent
          }
        </p>
      </div>
    </div>
  )
}

export default ChatUnit