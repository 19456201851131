import React, { useEffect, useState } from "react";
import SiteTile from "./SiteTile";
import { FiPlus } from "react-icons/fi";
import { useAuth } from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { BiLoaderAlt } from "react-icons/bi";
import LoadingTile from "./LoadingTile";
import useDocTitle from "../../../hooks/useDocTitle";
import { useLocation } from "react-router-dom";

const Sites = () => {
  useDocTitle("Your Websites | MagicWeb.ai");
  const { auth } = useAuth();
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(true);
  const [sitesError, setSitesError] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  useEffect(() => {
    const fetchSites = async () => {
      setSitesLoading(true);
      try {
        const resp = await axiosPrivate.get(
          `${location.pathname === "/admin" ? "/admin" : ""}/website`
        );
        if (resp.status == 200) {
          setSites(
            resp.data.map((site) => {
              site.id = site._id;
              return site;
            })
          );
          // console.log(resp.data);
        } else {
          throw Error("Unexpected response from server");
        }
      } catch (err) {
        setSitesError(err.mesaage);
      } finally {
        setSitesLoading(false);
      }
    };
    if (auth?.accessToken) {
      fetchSites();
    }
  }, [auth]);

  const [isCreating, setIsCreating] = useState(false);
  const createNewSite = async () => {
    setIsCreating(true);
    try {
      const resp = await axiosPrivate.post("/website", {
        code: {
          compName: "div",
          compProps: {},
          className:
            "w-screen min-h-screen bg-background text-foreground font-body flex flex-col items-center relative",
          children: [],
        },
      });
      if (resp.status == 201) {
        const newSite = resp.data;
        newSite.id = newSite._id;
        setSites((sites) => [...sites, newSite]);
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      // setSitesError(err.mesaage);
    } finally {
      setIsCreating(false);
    }
  };
  const deleteSiteFromSitesList = async (id) => {
    setSites((sites) => sites.filter((site) => site._id !== id));
  };

  return (
    <div className="flex flex-col gap-14">
      <div className="flex flex-col gap-12">
        <div className="flex justify-between items-center">
          <p className="text-xl font-semibold text-zinc-200">Your Websites</p>
          {/* <button className='px-3 py-2 rounded bg-lime-600 text-zinc-100'>+ New Site</button> */}
        </div>

        <div className="gap-20 flex flex-wrap">
          <button
            onClick={createNewSite}
            className="md:w-96 w-full flex flex-col gap-3 group"
            title="create a new site"
          >
            <div className="w-full bg-zinc-700 bg-opacity-50 group-hover:bg-opacity-70  border border-zinc-700 group-hover:border-lime-800 duration-100 rounded-lg h-48 md:h-60 text-3xl text-white flex flex-col gap-3 justify-center items-center relative">
              <div className="rounded-lg bg-lime-600 w-8 h-8 flex justify-center items-center group-hover:scale-110 duration-100">
                {isCreating ? (
                  <BiLoaderAlt className="w-5 h-5 animate-spin" />
                ) : (
                  <FiPlus className="w-5 h-5" />
                )}
              </div>
              <div className="text-sm text-zinc-300 font-medium capitalize">
                Create new website
              </div>
            </div>
            <div className="text-md  text-zinc-300 font-semibold">
              New Website
            </div>
          </button>
          {isCreating && <LoadingTile />}
          {sitesLoading
            ? [1, 2, 3, 4].map((num) => <LoadingTile key={num} />)
            : sites
                .sort(
                  (p1, p2) => new Date(p2.updatedAt) - new Date(p1.updatedAt)
                )
                .map((site) => (
                  <SiteTile
                    key={site._id}
                    site={site}
                    deleteSiteFromSitesList={deleteSiteFromSitesList}
                  />
                ))}
        </div>
      </div>
    </div>
  );
};

export default Sites;
