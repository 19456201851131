import React, { useContext, useEffect, useRef, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { MdOpenInNew } from "react-icons/md";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import DialogBox from "../workspace/comps/DialogBox";
import { useNavigate } from "react-router-dom";
import { IoIosPlay } from "react-icons/io";
import { LuCopyPlus } from "react-icons/lu";

import SiteName from "./SiteName";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { BiLoaderAlt } from "react-icons/bi";
import UserContext from "../../../context/UserContext";

const SiteTile = ({ site, deleteSiteFromSitesList }) => {
  const { user } = useContext(UserContext);

  const calculateTimeAgo = (timestamp) => {
    const now = new Date();
    const editedDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - editedDate) / 1000);

    if (diffInSeconds < 60) {
      return "just now";
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    }
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };
  // console.log(site);
  const [tileMenuAppear, setTileMenuAppear] = useState(false);
  const [confirmDelOpt, setConfirmDelOpt] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteErr, setDeleteErr] = useState(false);
  const deleteSite = async () => {
    setIsDeleting(true);
    try {
      const resp = await axiosPrivate.delete("/website", {
        data: { id: site._id },
      });
      if (resp.status === 200) {
        deleteSiteFromSitesList(site._id);
      } else {
        throw Error("Unexpected Response from Server!");
      }
    } catch (err) {
      console.log(err);
      setDeleteErr(err.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const navigate = useNavigate();
  const nameRef = useRef(null);

  const [isCopying, setIsCopying] = useState(false);
  const [copyErr, setCopyErr] = useState(false);
  const createCopy = async () => {
    setIsCopying(true);
    try {
      const resp = await axiosPrivate.post("/website/copy", {
        websiteId: site._id,
      });
      if (resp.status == 201) {
        const newPage = resp.data;
        setIsCopying(false);
        navigate(`/workspace/${newPage._id}`);
      } else {
        throw Error("Unexpected Response from Server!");
      }
    } catch (err) {
      console.log(err);
      setCopyErr(err.message);
    } finally {
      setIsCopying(false);
    }
  };

  const [isTemplate, setIsTemplate] = useState(false);
  useEffect(() => {
    setIsTemplate(site?.isTemplate);
  }, [site]);

  const [isTogglingTemplate, setIsTogglingTemplate] = useState(false);
  const toggleTemplate = async () => {
    setIsTogglingTemplate(true);
    try {
      const resp = await axiosPrivate.post("/admin/website/template", {
        websiteId: site._id,
        isTemplate: !isTemplate,
      });
      if (resp.status == 200) {
        setIsTemplate(!isTemplate);
      } else {
        throw Error("Unexpected Response from Server while template toggling!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsTogglingTemplate(false);
    }
  };

  return (
    <>
      {site && (
        <div
          className={`flex flex-col gap-3 group md:w-96 w-full ${
            isDeleting && "animate-pulse opacity-30"
          }`}
        >
          <div className="cursor-pointer bg-zinc-700 bg-opacity-50 group-hover:bg-opacity-60 border border-zinc-700 group-hover:border-lime-600 duration-200 rounded-lg w-full h-48 md:h-60 text-3xl text-white relative flex justify-center pt-8">
            {site.devTheme.thumbnail ? (
              <div
                title="click to open in canvas"
                onClick={() => {
                  navigate(`/workspace/${site.id}?page=`);
                }}
                className="relative w-52 md:w-80 h-auto overflow-hidden group-hover:scale-105 group-hover:translate-y-[-5px] duration-200"
              >
                <img
                  src={site.devTheme.thumbnail}
                  className="w-80 h-auto object-cover"
                />
              </div>
            ) : (
              <div
                title="click to open in canvas"
                onClick={() => {
                  navigate(`/workspace/${site.id}?page=`);
                }}
                className="w-52 md:w-80 h-36 md:h-40 bg-white group-hover:scale-105 duration-200"
              ></div>
            )}
            {confirmDelOpt && (
              <div className="flex flex-col justify-center items-center rounded-b-lg gap-2 absolute bottom-0 bg-zinc-700 bg-opacity-80 h-full w-full text-sm text-zinc-200 text-left">
                <span>Confirm delete?</span>
                <div className="flex flex-col items-center justify-center w-full  gap-3">
                  <button
                    className="w-40 py-2 bg-lime-600 text-white flex-1 rounded-full hover:bg-lime-500 duration-200"
                    onClick={() => deleteSite()}
                  >
                    Yes
                  </button>
                  <button
                    className="w-40 py-2 bg-zinc-800 text-white flex-1 rounded-full hover:bg-zinc-600 duration-200"
                    onClick={() => {
                      setConfirmDelOpt(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            )}

            <div className="absolute top-1 right-2 text-md">
              <BsThreeDots
                title="click to see options"
                onClick={() => setTileMenuAppear(!tileMenuAppear)}
                className="w-5 md:w-6 h-auto text-zinc-600 group-hover:text-zinc-400"
              />
              <DialogBox appear={tileMenuAppear} setAppear={setTileMenuAppear}>
                <div className="absolute flex flex-col bg-zinc-900 rounded-md border-[1px] border-zinc-800 shadow-lg z-50 text-sm text-left w-36 p-1">
                  <span
                    className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/workspace/${site.id}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <MdOpenInNew className="w-4 h-auto " /> Open
                  </span>
                  <span
                    className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/preview/${site.id}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <IoIosPlay className="w-4 h-auto " /> Preview
                  </span>
                  <span
                    className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                    onClick={() => {
                      if (nameRef.current) nameRef.current.focus();
                      setTileMenuAppear(false);
                    }}
                  >
                    <MdOutlineDriveFileRenameOutline className="w-4 h-auto " />{" "}
                    Rename
                  </span>
                  <span
                    className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                    onClick={() => {
                      createCopy();
                    }}
                  >
                    {isCopying ? (
                      <BiLoaderAlt className="w-4 h-auto animate-spin" />
                    ) : (
                      <LuCopyPlus className="w-4 h-auto " />
                    )}{" "}
                    Create Copy
                  </span>
                  <span
                    className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                    onClick={() => {
                      setTileMenuAppear(false);
                      setConfirmDelOpt(true);
                    }}
                  >
                    <MdDelete className="w-4 h-auto " /> Delete
                  </span>
                  {user?.roles?.Admin && (
                    <span
                      className="px-2 py-2 hover:bg-lime-600 hover:text-zinc-200 rounded-md flex gap-2 justify-start text-zinc-400"
                      onClick={toggleTemplate}
                    >
                      {isTogglingTemplate ? (
                        <BiLoaderAlt className="w-4 h-auto animate-spin" />
                      ) : (
                        <MdOutlineDriveFileRenameOutline className="w-4 h-auto " />
                      )}{" "}
                      {isTemplate
                        ? "Remove from Template"
                        : "Publish as Template"}
                    </span>
                  )}
                </div>
              </DialogBox>
            </div>
          </div>
          <div className="flex flex-col gap-0 w-full">
            <div className="text-md font-semibold group-hover:text-zinc-100 duration-200 text-zinc-300 text-left">
              <SiteName name={site.name} siteId={site._id} nameRef={nameRef} />
            </div>
            <div className="text-sm text-zinc-500 font-secondary text-left">
              Last edited {calculateTimeAgo(site.updatedAt)}
            </div>
            {site.email && (
              <div className="text-sm text-zinc-500 font-secondary text-left">
                {site.email}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SiteTile;
