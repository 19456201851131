import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import CanvasContext from "../../../../context/CanvasContext";
import useTrackedSetRecoilState from "../../../../hooks/useTrackedSetRecoilState";
import PropOpen from "./PropOpen";
import { MdFileUpload, MdOutlineCloudUpload } from "react-icons/md";

import DialogBox from "./DialogBox";
import { BiLoaderAlt } from "react-icons/bi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MdOutlineCloudDone } from "react-icons/md";

const Img = () => {
  const [open, setOpen] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const { selectedState } = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [url, setURL] = useState("");

  const [dialogBoxAppear, setDialogBoxAppear] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const fileInputRef = useRef(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploadingImgUrl, setUploadingImgUrl] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { siteRecord } = useContext(CanvasContext);

  useEffect(() => {
    if (state.compProps?.src) {
      setURL(state.compProps?.src);
      setOpen(true);
    } else {
      setURL("");
      setOpen(false);
    }
  }, [selectedState]);

  const updateCompImgSrc = (url) => {
    if (!selectedState) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      compProps: { ...prevState.compProps, src: `${url ? url : null}` },
    }));
    setURL(url);
  };

  const handleFileInput = async (e) => {
    setUploadUrl("");
    setUploadingImgUrl("");
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    console.log(file);
    setInputFile(file);
    try {
      const resp = await axiosPrivate.get(
        `/website/aws-s3/get-upload-url?websiteId=${siteRecord._id}&imgSize=${file.size}&imgType=${file.type}`
      );
      if (
        resp &&
        resp.status == 200 &&
        resp.data?.uploadUrl &&
        resp.data?.imgUrl
      ) {
        setUploadUrl(resp.data.uploadUrl);
        setUploadingImgUrl(resp.data.imgUrl);
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      console.log(err);
    }
    e.target.value = null;
  };

  async function uploadFile() {
    if (!inputFile || !uploadUrl || !uploadingImgUrl) {
      console.log("Missing file or upload URL");
      return;
    }
    setIsUploading(true);
    try {
      const resp = await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg", // or the correct MIME type of your file
        },
        body: inputFile,
      });

      if (resp.ok) {
        console.log("Upload successful");
        // console.log(resp);
        // Perform any action after successful upload
        updateCompImgSrc(uploadingImgUrl);
        setUploadSuccess(true);
        setInputFile(null);
        setUploadUrl("");
        setUploadingImgUrl("");
      } else {
        console.log("Upload failed", resp.statusText);
      }
    } catch (error) {
      console.error("Error during upload", error);
    }
    setIsUploading(false);
    // setUploadUrl(false);
  }

  console.log("statestate", state);

  return (
    <>
      {(state.compName === "img" || state.compName === "iframe") && (
        <PropOpen title="Media" open={open} setOpen={setOpen}>
          <p className="flex items-center text-zinc-400 text-xs w-16">URL</p>
          <div
            className={`col-span-2 propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer ${
              isFocused ? "border border-lime-600" : ""
            }`}
          >
            <input
              type="text"
              className="bg-transparent outline-none h-3 w-[100%] px-1 text-xs tracking-widest text-zinc-400 placeholder:text-zinc-600"
              value={url}
              onChange={(e) => updateCompImgSrc(e.target.value)}
              placeholder="example.com/yourimage.jpg"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          {state.compName === "img" && (
            <>
              <p className="flex items-center text-zinc-400 text-xs w-20">
                New File
              </p>
              <div className="col-span-2 flex">
                <div
                  onClick={() => {
                    setUploadSuccess(false);
                    setDialogBoxAppear(!dialogBoxAppear);
                  }}
                  className={`propUnitBox w-full p-[6px] pl-3 flex items-center justify-between cursor-pointer`}
                >
                  Upload
                  <MdFileUpload className="w-5 h-auto" />
                </div>
                <DialogBox
                  appear={dialogBoxAppear}
                  setAppear={setDialogBoxAppear}
                >
                  <div className="propBoxDialog w-80">
                    <p className="flex items-center border-b border-zinc-800 pb-2">
                      Upload Image
                    </p>
                    <div className="flex flex-col gap-4">
                      <div className="text-zinc-500 w-full flex flex-col gap-2 items-center my-4 text-sm">
                        {uploadSuccess ? (
                          <>
                            <MdOutlineCloudDone
                              className={`w-16 h-auto text-lime-700`}
                            />
                            <p>Image was successfully updated!</p>
                          </>
                        ) : (
                          <>
                            <MdOutlineCloudUpload
                              className={`w-16 h-auto ${
                                isUploading ? "animate-bounce" : ""
                              }`}
                            />
                            <p>Upload your image to the cloud!</p>
                          </>
                        )}
                      </div>
                      <div className="flex border text-[14px] border-zinc-800 text-md rounded-md">
                        <div
                          className={`w-fit flex-grow bg-zinc-900 py-2 px-3 text-xs overflow-x-scroll ${
                            inputFile ? "text-zinc-200" : "text-zinc-400"
                          }`}
                        >
                          {inputFile
                            ? inputFile.name
                            : "Choose a file to upload"}
                        </div>
                        <button
                          className="rounded-r-md bg-zinc-800 py-2 px-6 text-zinc-300 hover:bg-zinc-700 cursor-pointer"
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        >
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileInput}
                            style={{ display: "none" }}
                          />
                          Browse
                        </button>
                      </div>
                      <div className="w-full text-[14px] flex gap-3">
                        <button
                          onClick={() => setDialogBoxAppear(false)}
                          className="flex-1 bg-zinc-800 text-zinc-300 font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-zinc-600"
                        >
                          Later
                        </button>
                        <button
                          onClick={() => {
                            uploadFile();
                          }}
                          disabled={isUploading || !uploadUrl}
                          className="flex-1 flex justify-center bg-lime-600 text-white font-semibold px-2 py-2 rounded-lg duration-200 hover:bg-lime-500 disabled:bg-zinc-500"
                        >
                          {!isUploading ? (
                            "Upload"
                          ) : (
                            <BiLoaderAlt className="w-4 h-4 animate-spin" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogBox>
              </div>
            </>
          )}
        </PropOpen>
      )}
    </>
  );
};

export default Img;
