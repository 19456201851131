import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext'
import { SpaceContext } from 'react-zoomable-ui';
import { RxCross2 } from 'react-icons/rx';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const HoverOverlay = () => {
  const {pageState, setSelectedState, hoveredState, setHoveredState, hoveredComponentRect, hoveredParentState, hoveredParentChildIndex} = useContext(CanvasContext);
  const context = useContext(SpaceContext);
  const [translatedRect, setTranslatedRect] = useState({});
  const setHoveredParentRecoilState = useTrackedSetRecoilState(hoveredParentState);

  useEffect(()=>{
    if (hoveredComponentRect) {
      setTranslatedRect(context.viewPort.translateClientRectToVirtualSpace(hoveredComponentRect));
    }
  }, [hoveredComponentRect])

  const removeStateFromParent = (e) => {
    e.stopPropagation();
    setHoveredParentRecoilState(prev => {
      const newChildren = [...prev.children];
      newChildren.splice(hoveredParentChildIndex, 1);
      return {...prev, children:newChildren};
    });
    setHoveredState(pageState);
    setSelectedState(pageState);
  }
  return (
    <div>
      {hoveredState?.key != pageState?.key && hoveredState && (
        <div style={{pointerEvents: "none", position: "absolute", top: `${translatedRect.top}px`, left: `${translatedRect.left}px`}}>
          <div className="border border-blue-500" style={{position: "relative", width:`${translatedRect.width}px`, height: `${translatedRect.height}px`}}>
            <div className="px-1 w-fit text-[6px] bg-blue-500 text-white font-semibold" style={{position: "absolute", top: `-11px`, left: `-1px`}}>
              {hoveredState.key.split("_")[0]}
            </div>
          </div>
          <button
            className={`z-100 absolute text-[6px] w-fit bg-blue-500 text-white font-bold flex justify-center items-center`}
            style={{pointerEvents: "auto", top: '0px',right:'0px'}}
            onClick={removeStateFromParent}
            onMouseOver={(e)=>{e.stopPropagation();setHoveredState(hoveredState)}}
            onMouseLeave={(e)=>{e.stopPropagation();setHoveredState(pageState)}}
          >
            <RxCross2 className='w-3 h-3'/>
          </button>
        </div>
      )}
    </div>
  )
}

export default HoverOverlay