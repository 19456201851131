import { useEffect } from 'react'
import { useRefreshToken } from './useRefreshToken'
import { axiosPrivate } from '../api/axios';
import { useAuth } from './useAuth';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const reqInterceptor = axiosPrivate.interceptors.request.use(
        config => {
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
            }
            return config;
        },
        error => Promise.reject(error)
    )
    const responseInterceptor = axiosPrivate.interceptors.response.use(
        response => response,
        async (error) => {
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest?.sent) {
                prevRequest.sent = true;
                const newAccessToken = await refresh(); // refresh updates the auth with new access token
                // console.log(auth.accessToken);
                // console.log(newAccessToken);
                prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosPrivate(prevRequest);
            }
            return Promise.reject(error);
        }
    )

    return () => {
        axiosPrivate.interceptors.request.eject(reqInterceptor);
        axiosPrivate.interceptors.response.eject(responseInterceptor);
    }

  }, [auth, refresh]);
  return axiosPrivate;
}

export default useAxiosPrivate;