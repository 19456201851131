import React, { createContext, useEffect, useState } from 'react';

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [isUserLoading, setIsUserLoading] = useState(true);   
    const [userNameAbbr, setUserNameAbbr] = useState("");
    const [plan, setPlan] = useState(""); 
    useEffect(()=>{
      if (user?.roles?.Pro) {
        setPlan('pro');
      }
      if (user?.roles?.Enterprise) {
        setPlan('enterprise')
      }
    },[user])
    useEffect(()=>{
      if (user) {
        let name = "";
        if (user.fname && user.fname[0]) {
        name+= user.fname[0];
        }
        if (user.fname && user.lname[0]) {
        name+= user.lname[0];
        }
        setUserNameAbbr(name);
      }
    },[user])

    return (
        <UserContext.Provider value={{
          user, setUser,
          isUserLoading, setIsUserLoading,
          userNameAbbr, setUserNameAbbr,
          plan, setPlan
        }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;
