import React, { useState } from 'react'
import useTrackedSetRecoilState from '../../../hooks/useTrackedSetRecoilState';

const StringWrapper = ({parentRecoilState, childListIndex, children}) => {
  const setParentState = useTrackedSetRecoilState(parentRecoilState);
  const [editable, setEditable] = useState(false);
  const updateParentRecoilState = (e) => {
    setEditable(false);
    setParentState(prevState => {
      const newChildren = [...prevState.children];
      newChildren[childListIndex] = e.target.innerText;
      return {...prevState, children: newChildren};
    })
  };

  return (
    <span
      className={`outline-none ${editable? 'cursor-text':''}`}
      contentEditable={editable}
      dangerouslySetInnerHTML={{ __html: String(children) }}
      onBlur={updateParentRecoilState}
      onClick={()=>{setEditable(true)}}
    />
  )
}

export default StringWrapper