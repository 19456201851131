import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useRefreshToken } from "../hooks/useRefreshToken";
import { useAuth } from "../hooks/useAuth";

export const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { persist } = useAuth();
  const refresh = useRefreshToken();
  useEffect(() => {
    const executeRefreshToken = async () => {
      setIsLoading(true);
      try {
        await refresh();
      } catch (err) {
        console.log(err.message);
      }
      setIsLoading(false);
    };
    if (persist) {
      executeRefreshToken();
    }
  }, [persist]);
  return (
    <>
      {persist ? (
        isLoading ? (
          <div className='w-screen bg-zinc-600 min-h-screen animate-pulse'></div>
        ) : (
          <Outlet />
        )
      ) : (
        <Outlet />
      )}
    </>
  );
};
