import chroma from "chroma-js";
import colors from  "tailwindcss/colors";


export function rgbaToHex(rgba) {
  // Extract the numeric parts of the rgba string
  const parts = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)$/);

  // If the parsing failed, return a fallback or an error
  if (!parts) {
    console.error('Invalid RGBA color:', rgba);
    return '#000000'; // Fallback color
  }

  // Convert each color component into a hex string
  const r = parseInt(parts[1]).toString(16).padStart(2, '0');
  const g = parseInt(parts[2]).toString(16).padStart(2, '0');
  const b = parseInt(parts[3]).toString(16).padStart(2, '0');

  // Combine them and return the result
  return `#${r}${g}${b}`;
}

export const selfClosingHTMLTags = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"];

export const classesToObj = (className) => {
  const classes = className.split(' ');
  const classObject = {};

  classes.forEach(cls => {
    if (cls.trim().length > 0) {
      classObject[cls] = true;
    }
  });

  return classObject;
}

export const getHex = (twColor) => {
  if (twColor.startsWith("#")) {
    return twColor;
  } else {
    const [color, shade] = twColor.split("-");
    return shade? colors[color][Number(shade)] : colors[color];
  }
}

export const setColorProperty = (colorType, colorValue) => {
  let colorVal  = getHex(colorValue);
  if (colorType === 'grayshade') {
    const scale = chroma.scale(['white', colorVal, 'black']).mode('lab');
    for (let i = 1; i < 10; i++) {
      const shade = scale(i / 10).hex();
      document.documentElement.style.setProperty(`--grayshade-${i}00`, shade);
    }
  } else {
    const rgb = chroma(colorVal).rgb().join(' ');
    document.documentElement.style.setProperty(`--${colorType}`, rgb);
  } 
}