import React, { useState } from 'react';
import ThemeColors from './ThemeColors';
import ThemeFonts from './ThemeFonts';
import { FiMinus, FiPlus } from 'react-icons/fi';
import PropOpen from './PropOpen';

const Theme = () => {
  const [open, setOpen] = useState(true);
  return (
    <PropOpen title={"Theme"} open={open} setOpen={setOpen}>
      <ThemeColors/>
      <ThemeFonts/>
    </PropOpen>
  )
}

export default Theme