import { useState, useContext, useEffect } from "react";
import { useRecoilValue } from "recoil";
import CanvasContext from "../../../../context/CanvasContext";
import PropOpen from "./PropOpen";
import useTrackedSetRecoilState from "../../../../hooks/useTrackedSetRecoilState";
import { jsonToCode } from "../../../../componentStates/codeParser";
import useRecoilStateToValues from "../../../../hooks/useRecoilStateToValues";

const Form = () => {
  const { selectedState } = useContext(CanvasContext);
  const recoilStateToJsonObj = useRecoilStateToValues();
  const state = useRecoilValue(selectedState);

  const [open, setOpen] = useState(true);
  const [webhookVal, setWebhookVal] = useState("");
  const [isFocusedWebhook, setIsFocusedWebhook] = useState(false);
  const setState = useTrackedSetRecoilState(selectedState);

  function extractWebhookURL(scriptString) {
    const regex = /const webhookURL\s*=\s*'([^']*)';/;
    const match = scriptString.match(regex);
    return match ? match[1] : null;
  }

  useEffect(() => {
    const selectedCompJsonObj = recoilStateToJsonObj(selectedState);
    const code = jsonToCode(selectedCompJsonObj);
  }, [selectedState]);

  useEffect(() => {
    if (state) {
      const webhookVal = extractWebhookURL(state?.script);
      setWebhookVal(webhookVal);
    }
  }, [state]);

  function updateWebhookURL(scriptString, newWebhookURL) {
    const regex = /const webhookURL\s*=\s*'[^']*';/;
    const newWebhookString = `const webhookURL='${newWebhookURL}';`;
    const updatedScript = scriptString.replace(regex, newWebhookString);
    return updatedScript;
  }

  // const updateFromEmail = (email) => {
  //   setState((prevState) => {
  //     return prevState;
  //   });
  // };

  const updateWebhook = (webhook) => {
    setState((prevState) => {
      const updatedScript = updateWebhookURL(prevState.script, webhook);
      return {
        ...prevState,
        script: updatedScript,
      };
    });
    setWebhookVal(webhook);
  };

  return (
    <>
      {state?.children?.some((state) => state?.key?.startsWith("form")) ? (
        <PropOpen title={"Forms"} open={open} setOpen={setOpen}>
          {/* <p className="flex items-center text-zinc-400 text-xs">From</p>
          <div
            className={`relative col-span-2 propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer ${
              isFocusedEmail ? "border border-lime-600" : ""
            }`}
          >
            <input
              type="text"
              className="bg-transparent outline-none h-3 w-[100%] px-1 py-2 text-xs tracking-widest text-zinc-400 placeholder:text-zinc-500"
              // value={url}
              onChange={(e) => updateFromEmail(e.target.value)}
              placeholder="Email"
              onFocus={() => setIsFocusedEmail(true)}
              onBlur={() => {
                setTimeout(() => setIsFocusedEmail(false), 200);
              }}
            />
          </div> */}

          <p className="flex items-center text-zinc-400 text-xs">Webhook</p>
          <div
            className={`relative col-span-2 propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer ${
              isFocusedWebhook ? "border border-lime-600" : ""
            }`}
          >
            <input
              type="text"
              className="bg-transparent outline-none h-3 w-[100%] px-1 py-2 text-xs tracking-widest text-zinc-400 placeholder:text-zinc-500"
              value={webhookVal}
              onChange={(e) => updateWebhook(e.target.value)}
              placeholder="/submit-form"
              onFocus={() => setIsFocusedWebhook(true)}
              onBlur={() => {
                setTimeout(() => setIsFocusedWebhook(false), 200);
              }}
            />
          </div>
        </PropOpen>
      ) : (
        ""
      )}
    </>
  );
};

export default Form;
