import React, { useContext, useEffect, useRef, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";

import PropOpen from "./PropOpen";
import { BsThreeDots } from "react-icons/bs";
import { GoHomeFill } from "react-icons/go";
import { BiSolidPlusSquare } from "react-icons/bi";
import { FaFile } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import CanvasContext from "../../../../context/CanvasContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import DialogBox from "./DialogBox";
import Page from "./Page";

const Pages = () => {
  const { siteRecord, setSiteRecord, setPagesState } =
    useContext(CanvasContext);
  const [newPage, setNewPage] = useState("");
  const [initCreate, setInitCreate] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (initCreate && inputRef.current) {
      inputRef.current.focus();
    }
  }, [initCreate, inputRef]);
  const axiosPrivate = useAxiosPrivate();

  const [isCreating, setIsCreating] = useState(false);
  const createNewPage = async (pagePath) => {
    pagePath = pagePath.replace(/\s+/g, "-"); // replaces spaces with hyphen
    setIsCreating(true);
    try {
      const resp = await axiosPrivate.post("/website/page", {
        websiteId: siteRecord._id,
        path: pagePath,
        code: {
          compName: "div",
          compProps: {},
          className:
            "w-screen min-h-screen bg-background text-foreground font-body flex flex-col items-center relative",
        },
      });
      if (resp.status == 201 && resp.data?.code) {
        const { code, title, description } = resp.data;
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[pagePath] = { code, title, description };
          return { ...site, devPages };
        });
        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[pagePath] = undefined;
          return newStates;
        });
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      // setSitesError(err.mesaage);
    } finally {
      setIsCreating(false);
      setNewPage("");
      setInitCreate(false);
    }
  };
  // Handler for input changes
  const handleInputChange = (e) => {
    const value = e.target.value;
    const pattern = /^[a-zA-Z0-9 ]*$/; // Pattern to allow only letters and numbers

    // Check if the current input value matches the pattern
    if (pattern.test(value)) {
      setNewPage(value); // Update the state if the value is valid
    } else {
      // Optionally, you can provide feedback or handle invalid input here
      console.log("Invalid input: Only letters and numbers are allowed.");
    }
  };

  return (
    <div
      className="flex flex-col gap-5 tracking-wider pb-5 "
      title="Your website pages"
    >
      <div className="flex justify-between">
        <p className="text-zinc-200 font-semibold text-xs">Pages</p>
        <button
          onClick={() => setInitCreate(true)}
          title="Click to create a new page"
        >
          <FiPlus className="w-4 h-auto text-zinc-300" />
        </button>
      </div>
      <div className="flex flex-col w-full gap-0">
        {console.log("siteRecordsiteRecord", siteRecord)}
        {siteRecord?.devPages &&
          Object.keys(siteRecord.devPages)
            .sort()
            .map((page) => {
              if (siteRecord.devPages[page])
                return <Page key={page} page={page} />;
            })}
        {initCreate && (
          <div
            className={`col-span-3 text-zinc-400 text-xs w-full rounded-lg mt-2 py-2 pl-4 border border-lime-600`}
          >
            <div className="flex items-center gap-2">
              <FaFile className="w-3 h-auto" />
              /
              <input
                ref={inputRef}
                type="text"
                value={newPage}
                onChange={handleInputChange}
                placeholder="page-path"
                className="bg-transparent outline-none placeholder:text-zinc-500"
                onBlur={() => {
                  setInitCreate(false);
                  setNewPage("");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") createNewPage(newPage);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pages;
