import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import CanvasContext from '../../../../context/CanvasContext';
import chroma from "chroma-js";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';



const Fill = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [bgColor, setBgColor] = useState("");
  const colorInputRef = useRef(null);

  useEffect(()=>{
    if (selectedCompStyle?.backgroundColor && (state?.style?.backgroundColor || selectedCompStyle?.backgroundColor !== 'rgba(0, 0, 0, 0)')) {
      setBgColor(chroma(selectedCompStyle.backgroundColor).hex());
    } else {
      setBgColor(null);
    }
  },[selectedCompStyle, state])

  const updateCompFill = (color) => {
    if (!selectedState) {
      return;
    }
    const stateStyleObj = {...state.style, backgroundColor:color};
    
    setState((prevState) => ({...prevState, style:  stateStyleObj}));
  }

  
  const handleColorChange = (e) => {
    updateCompFill(e.target.value);
    setBgColor(e.target.value);
  };

  const handleDivClick = () => {
    colorInputRef.current.click();
  };

  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs'>Fill</p>
      <div onClick={handleDivClick} className="col-span-2 propUnitBox w-full p-[6px] pl-3 flex items-center justify-between cursor-pointer">
        {bgColor? <input className='text-zinc-400 bg-transparent outline-none w-16 font-sans' value={bgColor} onChange={handleColorChange}/> : <span className='text-zinc-500 '>Add</span> }
        <div>
          <div
            className="w-5 h-5 rounded-full shadow-sm border-[0.5px] border-zinc-500"
            style={{ backgroundColor: bgColor }}
          >
            <input
            ref={colorInputRef}
            type="color"
            value={bgColor}
            onChange={handleColorChange}
            className="opacity-0"
          />
          </div>
        </div>
      </div>
    </>
  )
}

export default Fill