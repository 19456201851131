import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { TbBorderSides } from "react-icons/tb";
import { FaRegSquare } from"react-icons/fa6";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';


const Margin = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const setState = useTrackedSetRecoilState(selectedState);
  const [type, setType] = useState('all');
  const [margin, setMargin] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const [marginRight, setMarginRight] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedT, setIsFocusedT] = useState(false);
  const [isFocusedR, setIsFocusedR] = useState(false);
  const [isFocusedB, setIsFocusedB] = useState(false);
  const [isFocusedL, setIsFocusedL] = useState(false);
  
  useEffect(()=>{
    if (selectedCompStyle?.marginTop) {
      setMarginTop(parseInt(selectedCompStyle.marginTop));
    } else {
      setMarginTop(0);
    }
    if (selectedCompStyle?.marginRight) {
      setMarginRight(parseInt(selectedCompStyle.marginRight));
    } else {
      setMarginRight(0);
    }
    if (selectedCompStyle?.marginBottom) {
      setMarginBottom(parseInt(selectedCompStyle.marginBottom));
    } else {
      setMarginBottom(0);
    }
    if (selectedCompStyle?.marginLeft) {
      setMarginLeft(parseInt(selectedCompStyle.marginLeft));
    } else {
      setMarginLeft(0);
    }
    if (
      selectedCompStyle.marginTop == selectedCompStyle.marginRight
      && selectedCompStyle.marginBottom == selectedCompStyle.marginLeft
      && selectedCompStyle.marginBottom == selectedCompStyle.marginTop
    ) {
      if (selectedCompStyle?.marginTop) {
        setMargin(parseInt(selectedCompStyle.marginTop));
      } else {
        setMargin(0);
      }
      setType('all');
    } else {
      setMargin(0);
      setType('ind');
    }
  },[selectedCompStyle])

  const updateCompMargin = (params) => {
    if (!selectedState) {
      return;
    }
    const {m, mt, mr, mb, ml} = params;

    if (m || m===0) {
      setMargin(m);
      setMarginTop(m);
      setMarginRight(m);
      setMarginBottom(m);
      setMarginLeft(m);
      setState((prevState) => ({...prevState, style: {...prevState.style, marginTop: m+"px", marginRight: m+"px", marginBottom: m+"px", marginLeft: m+"px"}}));
    } else if (mt || mt===0) {
      setMarginTop(mt);
      setState((prevState) => ({...prevState, style: {...prevState.style, marginTop: mt+"px"}}));
    } else if (mr || mr===0) {
      setMarginRight(mr);
      setState((prevState) => ({...prevState, style: {...prevState.style, marginRight: mr+"px"}}));
    } else if (mb || mb===0) {
      setMarginBottom(mb);
      setState((prevState) => ({...prevState, style: {...prevState.style, marginBottom: mb+"px"}}));
    } else if (ml || ml===0) {
      setMarginLeft(ml );
      setState((prevState) => ({...prevState, style: {...prevState.style, marginLeft: ml+"px"}}));
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16'>Margin</p>
      <div className="col-span-2 flex h-8 gap-3">
        <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
          <button onClick={()=>{setType('all')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='all'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-400 hover:scale-110'/>
          </button>
          <button onClick={()=>{setType('ind')}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${type=='ind'? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            <TbBorderSides className='w-4 h-4 text-zinc-400 hover:scale-110'/>
          </button>
        </div>
      </div>
      
      { type=='all' &&
        <>
          <div></div>
          <div className={"col-span-2 flex items-center gap-1 text-white propUnitBox pl-2 w-full"+`${isFocused? ' border border-lime-600' : ''}`}>
            <FaRegSquare className='w-3 h-3 text-zinc-500'/>
            <input type="text" className='bg-transparent outline-none h-3 w-[70%] px-2 text-xs tracking-widest text-zinc-400'
              value={margin}
              onChange={(e)=>{
                if(e.target.value) {
                  updateCompMargin({m:parseInt(e.target.value)});
                } else {
                  updateCompMargin({m:0});
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}  
            />
            <div className='flex flex-col gap-0 justify-center items-center h-5 px-1'>
              <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompMargin({pm:margin+1});}}/>
              <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (margin>0) updateCompMargin({m:margin-1});}}/>
            </div>
          </div>
        </>
      }
      { type=='ind' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedT? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">T</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={marginTop}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompMargin({mt:parseInt(e.target.value)});
                  } else {
                    updateCompMargin({mt:0});
                  }
                }}  
                onFocus={() => setIsFocusedT(true)}
                onBlur={() => setIsFocusedT(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompMargin({mt:marginTop+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if(marginTop>0) updateCompMargin({mt:marginTop-1})}}/>
              </div>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedR? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">R</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={marginRight}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompMargin({mr:parseInt(e.target.value)});
                  } else {
                    updateCompMargin({mr:0});
                  }
                }}  
                onFocus={() => setIsFocusedR(true)}
                onBlur={() => setIsFocusedR(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompMargin({mr:marginRight+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (marginRight > 0) updateCompMargin({mr:marginRight-1})}}/>
              </div>
            </div>
          </div>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedB? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">B</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={marginBottom}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompMargin({mb:parseInt(e.target.value)});
                  } else {
                    updateCompMargin({mb:0});
                  }
                }}  
                onFocus={() => setIsFocusedB(true)}
                onBlur={() => setIsFocusedB(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompMargin({mb:marginBottom+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (marginBottom > 0) updateCompMargin({mb:marginBottom-1})}}/>
              </div>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox flex-1 pl-1"+`${isFocusedL? ' border border-lime-600' : ''}`}>
              <span className="text-zinc-500 p-1">L</span>
              <input type="text" className='bg-transparent outline-none h-3 w-[50%] px-1 text-xs tracking-widest text-zinc-400'
                value={marginLeft}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateCompMargin({ml:parseInt(e.target.value)});
                  } else {
                    updateCompMargin({ml:0});
                  }
                }}
                onFocus={() => setIsFocusedL(true)}
                onBlur={() => setIsFocusedL(false)}
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateCompMargin({ml:marginLeft+1})}}/>
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (marginLeft > 0) updateCompMargin({ml:marginLeft-1})}}/>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Margin;