import React, { useContext, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import CanvasContext from '../context/CanvasContext';

const useTrackedSetRecoilState = (atom) => {
  const setState = useSetRecoilState(atom);
  const {setStateChangeHistory, setLastChangedState} = useContext(CanvasContext);
  const MAX_HISTORY_LENGTH = 50;
  const [currTimeStamp, setCurrTimeStamp] = useState(0) // each 1 sec time window

  return (newStateOrCallback) => {
    setState(prev => {
      // store the state once every second for each hook call
      const newTimestamp = Math.floor(Date.now()/1000);
      if (newTimestamp !== currTimeStamp) {
        setStateChangeHistory(hist => {
          const newHist = [...hist, {atom, prev}];
          if (newHist.length > MAX_HISTORY_LENGTH) {
            newHist.splice(0, 1);
          }
          return newHist;
        })
        setLastChangedState(atom);
        setCurrTimeStamp(newTimestamp);
      }
      
      // Determine whether newStateOrCallback is a function
      const newState = typeof newStateOrCallback === 'function'
      ? newStateOrCallback(prev)
      : newStateOrCallback;

      return newState;
    });
  }
}

export default useTrackedSetRecoilState