import React, { useCallback, useContext } from 'react'
import CanvasContext from '../context/CanvasContext';
import useTrackedSetRecoilState from './useTrackedSetRecoilState';
import AIChatContext from '../context/ChatContext';
import useRecoilStateToValues from './useRecoilStateToValues';
import useAxiosPrivate from './useAxiosPrivate';
import axios from 'axios';
import { jsonObjToRecoilState } from '../componentStates/atoms';
import { jsonToCode, parseHTMLToJson } from '../componentStates/codeParser';


const useNewChat = () => {
  const {siteRecord, pageState, selectedState, setSelectedState, selectedParentState, selectedParentChildIndex, setEditMode} = useContext(CanvasContext);
  const {setChatHistory, setErrMsg, setIsWorking, setCancelToken} = useContext(AIChatContext);
  const setParentState = useTrackedSetRecoilState(selectedParentState);
  
  const recoilStateToJsonObj = useRecoilStateToValues();
  const axiosPrivate = useAxiosPrivate();

  const handleNewChat = useCallback(async (inputText, setInputText, selectedFile, setSelectedFile) => {
    if (inputText) {
      setErrMsg("");
      setChatHistory(prevHistory => [{chatType:"user", content:inputText}, ...prevHistory]);
      if (setInputText) {
        setInputText("");
      }
    
      // console.log("selected file", selectedFile);
      
      const formData = new FormData();
      formData.append('image', selectedFile); // Append the image file
      // Append other data as a JSON string under a specific key
      let selectedComponentCode = "";
      if (selectedState?.key !== pageState?.key) {
        const selectedCompJsonObj = recoilStateToJsonObj(selectedState);

        const code = jsonToCode(selectedCompJsonObj);
        selectedComponentCode = `${code.html} ${code.script? `\n<script>${code.script}</script>` :""}${code.bodyStyle? `\n<style>${code.bodyStyle}</style>` :""}`
      }
      formData.append('data', JSON.stringify({
          reqPrompt: inputText,
          selectedComponentCode,
          websiteId: siteRecord._id,
          withImage: selectedFile? true : false
      }));

      const source = axios.CancelToken.source();
      setCancelToken(source);
      if (setSelectedFile) {
        setSelectedFile(null);
      }
      setIsWorking(true);
      setEditMode(false);
      try {
        const resp = await axiosPrivate.post('/chat', formData, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'multipart/form-data' // Important: Set the content type
          }
        });

        const {textResponse, html, script, style} = resp?.data;
        if (!html || !textResponse) {
          throw Error("GPT fucked up, please try again!")
        }
        setChatHistory(prevHistory => [{chatType:"AI", content:textResponse}, ...prevHistory]);
        const jsonObj = parseHTMLToJson(html)
        let newRecoilState;
        if (selectedState?.key !== pageState?.key) {
          newRecoilState = jsonObjToRecoilState({...jsonObj, script, bodyStyle:style});
          setParentState(prevState => {
            const newChildren = [...prevState.children];
            newChildren.splice(selectedParentChildIndex, 1, newRecoilState);
            return {...prevState, children: newChildren};
          });
        } else {
          jsonObj.className = (jsonObj.className).replace(/ ?\bw-[^ ]+|\b(?<!-)\bw-\[[^\]]+\]\s?/g, '').trim() + " w-full";
          newRecoilState = jsonObjToRecoilState({...jsonObj, script, bodyStyle:style});
          setParentState(prevState => {
            const newChildren = [...prevState.children, newRecoilState];
            return {...prevState, children: newChildren};
          });
        }
        // need to reset selected state as the prev one has been replaced.
        if (newRecoilState?.key) {
          setSelectedState(newRecoilState);
        } else {
          throw Error("Code conversion failed");
        }
      } catch (err) {
        console.log(err.message)
        if (axios.isCancel(err)) {
          setErrMsg(`${err.message}`);
        } else {
          // Handle other errors
          setErrMsg(`${err.message}`);
        }
      }
      setIsWorking(false);
      setEditMode(true);
    }
  }, [siteRecord, pageState, selectedState, selectedParentChildIndex, selectedParentState, setParentState]);
  return handleNewChat;
}

export default useNewChat