const parseHTMLToJson = (inputHTML) => {
  try {
    if (!inputHTML) {
      throw new Error('Empty or null input.');
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(`<body>${inputHTML.trim()}</body>`, 'text/html');

    if (doc.body.childNodes.length === 0 || doc.querySelector('parsererror')) {
      throw new Error('Error parsing HTML content.');
    }

    const extractAttributes = (node) => {
      const attrs = {};
      const eventAttrs = {};
      let className = "";
      let style = {};

      Array.from(node.attributes).forEach(attr => {
        const attrName = attr.name;
        let attrValue = attr.value;

        if (attrName.startsWith('on')) {
          eventAttrs[attrName] = attrValue;
        } else if (attrName === 'class' || attrName === 'classname') {
          className = attrValue;
        } else if (attrName === 'style') {
          style = parseStyleString(attrValue);
        } else {
          // Handle boolean attributes
          if (attrValue === '') {
            attrValue = true;
          }
          attrs[attrName] = attrValue;
        }
      });
      return { attrs, eventAttrs, className, style };
    };

    const parseStyleString = (styleString) => {
      const styleObj = {};
      const properties = styleString.split(';').map(prop => prop.trim()).filter(prop => prop);

      properties.forEach(property => {
        const colonIndex = property.indexOf(':');
        if (colonIndex !== -1) {
          let key = property.substring(0, colonIndex).trim();
          let value = property.substring(colonIndex + 1).trim();

          key = key.replace(/-./g, c => c.substr(1).toUpperCase()); // Convert to camelCase
          styleObj[key] = value;
        }
      });

      return styleObj;
    };

    const traverseDOM = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const { attrs, eventAttrs, className, style } = extractAttributes(node);
        const children = Array.from(node.childNodes).map(child => traverseDOM(child)).filter(node => node !== null);

        return {
          compName: node.tagName.toLowerCase(),
          compProps: attrs,
          eventProps: eventAttrs,
          className: className,
          style: style,
          children: children
        };
      } else if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent.trim();
        return text ? text : null;
      } else {
        return null; // Other node types are not processed
      }
    };

    const childNodes = Array.from(doc.body.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE);

    if (childNodes.length === 1) {
      return traverseDOM(childNodes[0]);
    } else {
      return {
        compName: 'div',
        compProps: {},
        eventProps: {},
        className: "",
        style: {},
        children: childNodes.map(node => traverseDOM(node))
      };
    }

  } catch (error) {
    throw new Error("GPT generated invalid code, you should try again!");
  }
}

const jsonToCode = (parsedObject) => {
  const convertStyleObjectToCSS = (styleObject) => {
    const convertCamelCaseToKebabCase = (str) => {
      return str.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
    };
  
    return Object.keys(styleObject).map((key) => {
      const cssProperty = convertCamelCaseToKebabCase(key);
      const cssValue = styleObject[key];
      return `${cssProperty}: ${cssValue};`;
    }).join(' ');
  };

  const convertProps = (compName, props, className, style) => {
    let propsString = '';
    if (className) {
      propsString += `class="${className}" `;
    }
    if (style && Object.keys(style).length > 0) {
      const styleString = convertStyleObjectToCSS(style);
      propsString += `style="${styleString}" `;
    }
    return propsString + Object.keys(props)
      .filter(key => props[key] !== null && props[key] !== undefined)
      .map(key => {
        if (typeof props[key] === 'boolean') {
          if (props[key]) {
            return `${key}`;
          } else {
            return "";
          }
        } else {
          if (key === 'src') {
            return `${key}="${props[key]}"`
          } else {
            return `${key}="${props[key]}"`
          }
        }
      }).join(' ');
  }

  function convertChildren(children) {
    if (!children) return { html: '', script: '', bodyStyle: '' };
    return children.map(child => {
      if (typeof child === 'string' || typeof child === 'number') {
        return { html: child.toString(), script: '', bodyStyle: '' }; // Convert to string and ensure script is defined
      }
      return jsonToCode(child); // Nested component
    }).reduce((prev, parsedChild) => {
      prev.html += parsedChild.html;    // Concatenate html
      if (parsedChild.script && !prev.script.includes(parsedChild.script))
        prev.script += '\n try{\n'+parsedChild.script+'\n} catch(err) {console.log(err)}';
      prev.bodyStyle += parsedChild.bodyStyle;  // Concatenate bodyStyle
      return prev;  // Return the accumulated object
    }, { html: '', script: '', bodyStyle:'' }); // Initial value with html and script properties
  }

  const { compName, compProps, eventProps, className, style, script, bodyStyle, children } = parsedObject;
  const propsString = convertProps(compName, {...compProps, ...eventProps}, className, style);
  const childrenString = convertChildren(children);

  const combinedScript = `${script? script:""} ${childrenString.script? childrenString.script:""}`.trim();
  const combinedStyle = `${bodyStyle? bodyStyle:""} ${childrenString.bodyStyle? childrenString.bodyStyle:""}`.trim();
  return {
    html:` <${compName} ${propsString.trim()}>${childrenString.html}</${compName}> `,
    script: combinedScript,
    bodyStyle: combinedStyle
  };
}

export { parseHTMLToJson, jsonToCode};
