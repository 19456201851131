import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import UserContext from '../context/UserContext';

const RequireAuth = ({allowedRoles}) => {
  const { auth } = useAuth();
  const { setIsUserLoading, setUser } = useContext(UserContext);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const axiosPrivate = useAxiosPrivate();

  useEffect(()=>{
    const fetchUser = async () => {
      setIsUserLoading(true);
      try {
        const resp = await axiosPrivate.get("/user");
        // console.log("user-resp:", resp);
        if (resp.status === 200) {
          setUser(resp.data);
          // console.log(resp.data);
          setIsUserLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (auth.accessToken) {
      fetchUser();
    }
  },[auth])

  return auth.accessToken? (allowedRoles.reduce(((prev, curr)=> auth.roles[curr] || prev), false) ? <Outlet/> : <Navigate to='/subscription' replace />) : <Navigate to='/login' state={{from: location}} replace />
}

export default RequireAuth