import { useContext, useEffect} from 'react';
import CanvasContext from '../context/CanvasContext';
import useTrackedSetRecoilState from './useTrackedSetRecoilState';
import { jsonObjToRecoilState } from '../componentStates/atoms';

const usePaste = () => {
  const {copiedStateJson, pageState, selectedState, selectedParentChildIndex, selectedParentState} = useContext(CanvasContext);
  const setParentState = useTrackedSetRecoilState(selectedParentState);
  const handleKeyDown = (event) => {
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && (event.metaKey || event.ctrlKey) && event.key === 'v') {
      event.preventDefault();
      if (copiedStateJson) {
        let newRecoilState;
        if (selectedState?.key !== pageState?.key) {
          newRecoilState = jsonObjToRecoilState(copiedStateJson);
        } else {
          const newClassName = (copiedStateJson.className).replace(/ ?\bw-[^ ]+|\b(?<!-)\bw-\[[^\]]+\]\s?/g, '').trim() + " w-full";
          newRecoilState = jsonObjToRecoilState({...copiedStateJson, className: newClassName});
        }
        setParentState(prevState => {
          const newChildren = [...prevState.children];
          newChildren.splice(selectedParentChildIndex+1, 0, newRecoilState);
          return {...prevState, children: newChildren};
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [copiedStateJson, selectedState, selectedParentChildIndex]); // this dependency array ensures the handleKeyDown is updated with correct `setState` at every change of state
};

export default usePaste;