import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import AppHeader from './AppHeader'
import AssetPanel from './AssetPanel'
import PropsPanel from './PropsPanel'
import ChatPanel from './ChatPanel'
import { AIChatProvider } from '../../../../context/ChatContext'
import { CanvasProvider } from '../../../../context/CanvasContext'
import SettingsBox from '../comps/SettingsBox'

const AppLayout = () => {
  return (
    <CanvasProvider>
      <AIChatProvider>
        <div className="font-sans">
          <AppHeader/>
          <PropsPanel/>
          <AssetPanel/>
          <ChatPanel/>
          <SettingsBox/>
          <Outlet/>
          {/* not using custom drag layer as the transparent Image drag preview does not consistently works always */}
        </div>
      </AIChatProvider>
    </CanvasProvider>
  )
}

export default AppLayout