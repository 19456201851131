import React, { createContext, useEffect, useRef, useState } from 'react';
import { cState } from '../componentStates/atoms';
import { getEmptyImage } from 'react-dnd-html5-backend';

const CanvasContext = createContext({});

export const CanvasProvider = ({ children }) => {
  const SCREEN = {
    'desktop': {width: 1280, height: 700, scale: '0.5'},
    'tablet': {width: 800, height: 1050, scale: '0.5'},
    'mobile':{width: 375, height: 680, scale: '0.6'},
    'current': {width: window.innerWidth, height: window.innerHeight, scale: '0.4'}
  }
  const dummyAtom = cState({compName:'dummy'});

  const [siteRecord, setSiteRecord] = useState({});
  const canvasRef = useRef(null);
  const [screenType, setScreenType] = useState('desktop');
  const [pageState, setPageState] = useState(dummyAtom);
  const [pagesState, setPagesState] = useState({});
  const [isCanvasLoading, setIsCanvasLoading] = useState(true);
  const [hoveredState, setHoveredState] = useState(pageState);
  const [selectedState, setSelectedState] = useState(pageState);
  const [selectedParentState, setSelectedParentState] = useState(pageState);
  const [selectedParentChildIndex, setSelectedParentChildIndex] = useState(0);
  const [hoveredParentState, setHoveredParentState] = useState(pageState);
  const [hoveredParentChildIndex, setHoveredParentChildIndex] = useState(0);
  const [selectedCompStyle, setSelectedCompStyle] = useState({});
  const [selectedAssetCateg, setSelectedAssetCateg] = useState(null);
  
  const [editMode, setEditMode] = useState(true);
  const [atomValues, setAtomValues] = useState({}); // atom.key to values  
  
  const [selectedComponentRect, setSelectedComponentRect] = useState(null);
  const [hoveredComponentRect, setHoveredComponentRect] = useState(null);
  const [dropComponentRect, setDropComponentRect] = useState(null);

  const [aiMode, setAiMode] = useState(false);

  useEffect(()=> {
    const aiModeStored = localStorage.getItem('aiMode'+siteRecord._id);
    setAiMode(aiModeStored? JSON.parse(aiModeStored): true)
  },[siteRecord])

  useEffect(()=> {
    localStorage.setItem('aiMode'+siteRecord._id, aiMode);
  },[aiMode, siteRecord])

  useEffect(()=>{
    if (pageState !== dummyAtom) {
      setSelectedState(pageState);
      setSelectedParentState(pageState);
      setSelectedParentChildIndex(0);
      setSelectedCompStyle({});
      setSelectedComponentRect(null);
      setDropComponentRect(null);
      setHoveredState(pageState);
      setIsCanvasLoading(false);
    }
  }, [pageState])
  
  const [dragImage, setDragImage] = useState(getEmptyImage);
  useEffect(()=>{
    const img = new Image();
    img.src = "/object.png";
    img.onload= () => setDragImage(img);
  },[])

  
  const [stateChangeHistory, setStateChangeHistory] = useState([]); // list of {atom, prev}
  const [lastChangedState, setLastChangedState] = useState(dummyAtom); // using dummy atom to avoid null error in useSetRecoilState in useUndo

  const [palette, setPalette] = useState({});
  const [hFont, setHFont] = useState('');
  const [bFont, setBFont] = useState('');
  const [themeLoaded, setThemeLoaded] = useState(false);
  useEffect(()=>{
    if (!themeLoaded && siteRecord.devTheme) {
      if (siteRecord.devTheme?.colorPalette) {
        setPalette(siteRecord.devTheme?.colorPalette);
      }
      if (siteRecord.devTheme?.headingFont) {
        setHFont(siteRecord.devTheme?.headingFont)
      }
      if (siteRecord.devTheme?.bodyFont) {
        setBFont(siteRecord.devTheme?.bodyFont)
      }
      setThemeLoaded(true);
    }
  },[siteRecord, themeLoaded])

  const [copiedStateJson, setCopiedStateJson] = useState(null);
  const [settingsBoxAppear, setSettingsBoxAppear] = useState(false);
  
  return (
    <CanvasContext.Provider value={{
      siteRecord, setSiteRecord,
      canvasRef, SCREEN, screenType, setScreenType,
      pageState, setPageState,
      pagesState, setPagesState,
      selectedState, setSelectedState,
      selectedParentState, setSelectedParentState,
      selectedParentChildIndex, setSelectedParentChildIndex,
      hoveredState, setHoveredState,
      hoveredParentState, setHoveredParentState,
      hoveredParentChildIndex, setHoveredParentChildIndex,
      selectedComponentRect, setSelectedComponentRect,
      hoveredComponentRect, setHoveredComponentRect,
      dropComponentRect, setDropComponentRect,
      selectedCompStyle, setSelectedCompStyle,
      isCanvasLoading, setIsCanvasLoading,
      selectedAssetCateg, setSelectedAssetCateg,
      aiMode, setAiMode,
      editMode, setEditMode,
      atomValues, setAtomValues,
      dragImage, setDragImage,
      stateChangeHistory, setStateChangeHistory,
      lastChangedState, setLastChangedState, dummyAtom,
      palette, setPalette,
      hFont, setHFont,
      bFont, setBFont,
      copiedStateJson, setCopiedStateJson,
      settingsBoxAppear, setSettingsBoxAppear
    }}>
      {children}
    </CanvasContext.Provider>
  );
}

export default CanvasContext;
