import React, { useContext } from 'react'
import ChatUnit from '../comps/ChatUnit';
import { FaRegStopCircle } from "react-icons/fa";
import ChatInput from '../comps/ChatInput';
import CanvasContext from '../../../../context/CanvasContext';
import AIChatContext from '../../../../context/ChatContext';
import { BiLoaderAlt } from 'react-icons/bi';

const ChatPanel = () => {
  
  const {chatHistory, isWorking, errMsg, cancelAxiosRequest, isChatLoading, fetchChat} = useContext(AIChatContext);
  const {aiMode} = useContext(CanvasContext);

  const handleScroll = (e) => {
    const target = e.target;
    if (target.clientHeight - target.scrollTop >= target.scrollHeight-10) {
      target.classList.add('elastic-scroll-down')
      setTimeout(() => {
        target.classList.remove('elastic-scroll-down');
      }, 500);
      fetchChat();
    }
    if (target.scrollTop >= -1) {
      target.classList.add('elastic-scroll-up')
      setTimeout(() => {
        target.classList.remove('elastic-scroll-up');
      }, 500);
    }
  };

  return (
    <div className={`${aiMode? 'z-10':'z-0'} fixed bg-black w-[511px] h-full bottom-0 flex flex-col gap-0 justify-end p-8 pr-0 pb-3`}>
      {chatHistory?.length > 0 &&
        <div className='flex-grow overflow-y-scroll customScroll text-white mt-4 pt-8 px-2 pb-8 flex flex-col-reverse gap-10 pr-12' onScroll={handleScroll}>
          {isWorking &&
            <div className='animate-pulse'>
              <ChatUnit chatContent={
                <div className='flex items-center gap-4'>
                  working...
                  <button onClick={cancelAxiosRequest}><FaRegStopCircle className='w-4 h-4'/></button>
                </div>
              } chatType="AI" className=""/>
            </div>

          }
          {errMsg && <div className='text-red-900 text-sm tracking tracking-wide w-fit px-2 py-1 '>{errMsg}</div>}
          {chatHistory.map((chat, ind) => 
            <ChatUnit key={ind} chatContent={chat.content} chatType={chat.chatType}/>
          )}
          {isChatLoading &&
            <div className='w-full flex justify-center'><BiLoaderAlt className='w-5 h-5 animate-spin text-zinc-300'/></div>
          }
        </div>
      }
      {!isChatLoading && chatHistory?.length === 0 &&
        <>
        <div className='text-zinc-400 flex flex-col items-center flex-grow justify-center gap-1 pr-8'>
          <div className='rounded-xl p-2 '>
            {/* <img src="/logo-svg.svg" className='h-10'/> */}
            <svg xmlns="http://www.w3.org/2000/svg" className="lucide lucide-mouse-pointer-square"  width='35px' viewBox="0 0 24 24"  stroke="#a1a1a1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fillOpacity="0"><path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"></path><path d="m12 12 4 10 1.7-4.3L22 16Z"></path></svg>
          </div>
          <span className='text-sm tracking-widest'>Start building with AI.</span>
        </div>
        {/* <div>
          example starting point boxes
        </div> */}
        </>
      }
      {/* chat input box */}
      <ChatInput />
      <div className='flex w-full justify-center text-xs text-zinc-600 mt-2 pr-8'>AI can make mistakes. Write detailed prompts for better results.</div>
    </div>
  )
}

export default ChatPanel