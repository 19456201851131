import React, { useContext, useEffect, useRef, useState } from 'react'
import { TbSquareRoundedArrowUpFilled } from "react-icons/tb";
import DialogBox from './DialogBox';
import AIChatContext from '../../../../context/ChatContext';
import { SiStackblitz } from "react-icons/si";
import useNewChat from '../../../../hooks/useNewChat';
import { BiLoaderAlt } from "react-icons/bi";

const AiInputSelected = () => {
  const [aiInputBox, setAiInputBox] = useState(false);
  const [inputText, setInputText] = useState("");

  const handleNewChat = useNewChat();
  const {errMsg, isWorking, setIsWorking, cancelAxiosRequest} = useContext(AIChatContext);
  
  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
        textarea.style.height = 'auto'; // Reset height to recalculate
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [inputText]); 

  useEffect(()=>{
    if (aiInputBox && textareaRef.current)
      textareaRef.current.focus();
  },[aiInputBox])

  return (
    <>
      {!isWorking &&
        <button
          className={`bg-lime-600 rounded-full w-6 h-6 flex justify-center items-center`}
          onClick={(e)=>{e.stopPropagation();setAiInputBox(!aiInputBox); }}
        >
          <SiStackblitz className='w-4 h-4 text-white'/>
        </button>
      }
      {isWorking &&
        <button
          className='bg-lime-600 hover:bg-lime-400 rounded-full w-6 h-6 flex justify-center items-center text-zinc-100'
          onClick={()=>{cancelAxiosRequest(); setIsWorking(false)}}
        >
          <BiLoaderAlt className='w-5 h-5 animate-spin'/>
        </button>
      }
      <DialogBox appear={aiInputBox} setAppear={setAiInputBox}>
        <div className='w-40 flex items-end bg-black text-white rounded-md px-1 py-1 border-2 border-lime-600 text-sm shadow-lg shadow-zinc-800'>
          <textarea
            ref={textareaRef}
            rows={1}
            value={inputText}
            onChange={(e)=> { if(e.target.value !== '\n') setInputText(e.target.value); }}
            onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) {setAiInputBox(false); handleNewChat(inputText, setInputText);} }}
            className='px-2 bg-black outline-none font-secondary text-sm placeholder:text-zinc-500 placeholder:text-[11px] resize-none max-h-24 customScroll'
            type='text'
            placeholder='type your pompt here..'
            onClick={(e)=>{e.stopPropagation()}}
          />
          {!isWorking &&
            <button
              className='disabled:text-zinc-500 text-zinc-100'
              onClick={()=>{setAiInputBox(false); handleNewChat(inputText, setInputText)}}
              disabled={!inputText? true:false}
            >
              <TbSquareRoundedArrowUpFilled className='w-5 h-5'/>
            </button>
          }
        </div>
      </DialogBox>
    </>
  )
}

export default AiInputSelected