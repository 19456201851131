import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import { classesToObj } from '../../../../helpers/helpers';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { RxCross2 } from 'react-icons/rx';
import { FaAngleDown } from 'react-icons/fa';
import DialogBox from './DialogBox';

const TextWeight = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [weight, setWeight] = useState('');
  const [tailwindDropdown, setTailwindDropdown] = useState(false);
  
  const tailwindWeightClasses = [
    'font-thin',       // Font weight 100
    'font-extralight', // Font weight 200
    'font-light',      // Font weight 300
    'font-normal',     // Font weight 400
    'font-medium',     // Font weight 500
    'font-semibold',   // Font weight 600
    'font-bold',       // Font weight 700
    'font-extrabold',  // Font weight 800
    'font-black',      // Font weight 900
  ];
  const tailwindWeightClassesResetObj = tailwindWeightClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{ 
    const classNameObj = classesToObj(state.className)
    let found = '';
    tailwindWeightClasses.forEach((cls)=> {
      if (classNameObj[cls]) {
        found=cls;
      }
    })
    if (found) {
      setWeight(found);
    } else {
      setWeight('');
    }

  },[state, selectedCompStyle])

  const updateState =  (weight)=>{
    setState((prevState) => {
      const classObj = {...classesToObj(prevState.className), ...tailwindWeightClassesResetObj};
      classObj[weight] = true;
      return {...prevState, className: classNames(classObj), style:{...prevState.style, fontWeight:undefined}}
    });
  }


  return (
    <>
    <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>Weight</p>
    <div className="col-span-2 flex flex-col gap-0 relative h-8">
      <button 
        className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full pl-3 pr-2"
        onClick={(e)=>{setTailwindDropdown(!tailwindDropdown)}}
      >
        <span className='h-8 flex items-center flex-grow text-left'>
          {weight === '' && <span className="text-zinc-500">Select</span>}
          {weight !== '' &&
          <div className='flex items-center justify-between pr-1 gap-1 w-full group capitalize'>
            <span>{weight.split("-")[1]}</span>
            <RxCross2 className='w-3 h-3 rounded-full bg-zinc-600 hidden group-hover:block' onClick={(e)=>{e.stopPropagation();setWeight('');updateState('')}}/>
          </div> 
          }
        </span>
        <FaAngleDown className='w-3 h-3'/>
      </button>
      <DialogBox appear={tailwindDropdown} setAppear={setTailwindDropdown}>
        <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center border-b border-zinc-600'>
          {
            tailwindWeightClasses.map(weightCls => 
              <button
                key={weightCls}
                className={`hover:bg-lime-500 hover:text-zinc-200 ${weight == weightCls && 'bg-lime-600 text-zinc-200'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}
                onClick={()=>{updateState(weightCls)}}
              >
                {weightCls.split("-")[1]}
              </button>
            )
          }
        </div>
      </DialogBox>
    </div>
  </>
  )
}

export default TextWeight