import React, { useState } from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';

const PropOpen = ({title, open, setOpen, children}) => {
  
  return (
    <div className='flex flex-col gap-3 tracking-wider pb-5 border-b border-zinc-800' title={title}>
      <div className='flex justify-between'>
        <p className='text-zinc-200 font-semibold text-xs'>{title}</p>
        <button onClick={()=>setOpen(curr => !curr)}>
          {
            open? <FiMinus className='w-3 h-3 text-zinc-300'/> :
            <FiPlus className='w-3 h-3 text-zinc-300'/>
          }
          
        </button>
      </div>
      { open &&
        <div className="pl-2 grid grid-cols-3 w-full gap-3">
          {children}
        </div>
      }
    </div>
  )
}

export default PropOpen