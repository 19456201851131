import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import CanvasContext from '../../../../context/CanvasContext';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import PropOpen from './PropOpen';

const Links = () => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const {selectedState, siteRecord} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [url, setURL] = useState("");
  const [newTab, setNewTab] = useState(false);

  const [suggestions, setSuggestions] = useState([]);
  useEffect(()=>{
    if (siteRecord?.devPages) {
      setSuggestions(Object.keys(siteRecord.devPages).map(page=>{return page==="/"? page:"/"+page}));
    }
    
  },[siteRecord])
  // Example static suggestions, replace with your own or fetch from a server
  

  useEffect(()=>{
    if (state.compName === 'a' && state.compProps.href) {
      setURL(state.compProps.href)
      if (state.compProps?.target === '_blank') {
        setNewTab(true);
      } else {
        setNewTab(false);
      }
      setOpen(true);
    } else if (state.compName !== 'a' && state.eventProps?.onclick) {
      const onclickString = state.eventProps.onclick;

      const urlAndTargetRegex = /window\.open\('([^']*)'(?:,\s*'(_blank|_self)')?\)/;

      const match = onclickString.match(urlAndTargetRegex);

      if (match?.length > 1 && match[1]) {
        setURL(match[1]);
        setNewTab(match[2] === '_blank'? true : false);
        setOpen(true);
      } else {
        setURL("");
        setOpen(false);
        setNewTab(false)
      }
    } else {
      setURL("");
      setOpen(false);
      setNewTab(false)
    }
  },[selectedState])

  const updateCompLink = (url, newTab) => {
    if (!selectedState) {
      return;
    }
    
    setState((prevState) => {
      if (prevState.compName === 'a') {
        return {...prevState, compProps: {...prevState.compProps, href:url, target:newTab? '_blank':''}}
      } else {
        return {...prevState, eventProps: {...prevState.eventProps, onclick:`${url? newTab? `window.open('${url}','_blank');` : `window.open('${url}','_self');` : null}`}, style: {...prevState.style, cursor:`${url? 'pointer' : 'default'}`}}
      }
    });
    setURL(url);
    setNewTab(newTab);
  };

  return (
    <PropOpen title="Link" open={open} setOpen={setOpen}>
      <p className='flex items-center text-zinc-400 text-xs'>Link To</p>
      <div className={`relative col-span-2 propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer ${isFocused? 'border border-lime-600' : ''}`}>
        <input type="text" className='bg-transparent outline-none h-3 w-[100%] px-1 py-2 text-xs tracking-widest text-zinc-400 placeholder:text-zinc-500'
          value={url}
          onChange={(e)=>updateCompLink(e.target.value, newTab)}
          placeholder='/page or URL...'
          onFocus={() => setIsFocused(true)}
          onBlur={() => {setTimeout(()=>setIsFocused(false),200)}}
        />
        {isFocused && suggestions.length > 0 && (
        <ul className="absolute top-6 left-0 bg-zinc-900 rounded-lg border border-zinc-800 shadow-md mt-3 max-h-40 p-1 w-full overflow-auto z-10">
          {suggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(url.toLowerCase())
          ).map(suggestion => (
            <li
              key={suggestion}
              className="py-2 pl-4 pr-2 w-full hover:bg-lime-500 rounded-md text-white cursor-pointer whitespace-nowrap overflow-clip overflow-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                updateCompLink(suggestion, newTab);
              }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      </div>
      <p className='flex items-center text-zinc-400 text-xs'>New Tab</p>
      <div className="col-span-2 flex h-8 gap-3">
        <div className="flex text-white gap-0 propUnitBox hover:bg-zinc-900  p-[3px] w-full">
          <button onClick={()=>{updateCompLink(url, true)}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${newTab==true? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            Yes
          </button>
          <button onClick={()=>{updateCompLink(url, false)}} className={'flex justify-center items-center py-1 w-[50%] rounded-md text-xs ' +`${newTab==false? 'bg-zinc-700 text-zinc-200':'text-zinc-400'}`}>
            No
          </button>
        </div>
      </div>
    </PropOpen>
  )
}

export default Links