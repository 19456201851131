import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { GoHomeFill } from "react-icons/go";
import { FaFile } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import CanvasContext from "../../../../context/CanvasContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import DialogBox from "./DialogBox";
import useRecoilStateToValues from "../../../../hooks/useRecoilStateToValues";
import ScriptBox from "./ScriptBox";
import UserContext from "../../../../context/UserContext";

const Page = ({ page }) => {
  const { user } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    siteRecord,
    setSiteRecord,
    pagesState,
    setPagesState,
    setSettingsBoxAppear,
  } = useContext(CanvasContext);

  const axiosPrivate = useAxiosPrivate();
  const recoilStateToJsonObj = useRecoilStateToValues();
  const [optionDropdownPage, setOptionDropdownPage] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [toDel, setToDel] = useState(false);

  const deleteThisPage = async () => {
    setIsUpdating(true);
    setToDel(false);
    try {
      const resp = await axiosPrivate.delete("/website/page", {
        data: { websiteId: siteRecord._id, pagePath: page },
      });
      if (resp.status == 200) {
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[page] = undefined;
          return { ...site, devPages };
        });
        if (searchParams.get("page") === page) {
          setSearchParams({ page: "/" });
        }
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const createPageCopy = useCallback(async () => {
    setIsUpdating(true);
    try {
      let pageCode = null;
      // copying the current code from the page if already loaded in canvas, otherwise from siteRecord
      if (pagesState[page]) {
        pageCode = recoilStateToJsonObj(pagesState[page]);
      } else {
        pageCode = siteRecord.devPages[page].code;
      }
      const copyPage = `copy-${page === "/" ? "of-home" : page}`;
      const resp = await axiosPrivate.post("/website/page", {
        websiteId: siteRecord._id,
        path: copyPage,
        code: pageCode,
        title: siteRecord.devPages[page].title,
        description: siteRecord.devPages[page].description,
      });

      if (resp.status == 201) {
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[copyPage] = { ...devPages[page], code: pageCode };
          return { ...site, devPages };
        });
        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[copyPage] = undefined;
          return newStates;
        });
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      // setSitesError(err.mesaage);
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  }, [pagesState, siteRecord]);
  const [newName, setNewName] = useState(page);
  const [initRename, setInitRename] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (initRename && inputRef.current) {
      inputRef.current.focus();
    }
  }, [initRename, inputRef]);
  // Handler for input changes
  const handleInputChange = (e) => {
    const value = e.target.value;
    const pattern = /^[a-zA-Z0-9 -]*$/; // Pattern to allow only letters and numbers

    // Check if the current input value matches the pattern
    if (pattern.test(value)) {
      setNewName(value); // Update the state if the value is valid
    } else {
      // Optionally, you can provide feedback or handle invalid input here
      console.log("Invalid input: Only letters and numbers are allowed.");
    }
  };
  const renamePage = async (newName) => {
    newName = newName.replace(/\s+/g, "-"); // replaces spaces with hyphen
    setIsUpdating(true);
    let pageCode = null;
    // copying the current code from the page if already loaded in canvas, otherwise from siteRecord
    if (pagesState[page]) {
      pageCode = recoilStateToJsonObj(pagesState[page]);
    } else {
      pageCode = siteRecord.devPages[page].code;
    }
    try {
      const resp = await axiosPrivate.post("/website/page/rename", {
        websiteId: siteRecord._id,
        oldPath: page,
        newPath: newName,
      });
      if (resp.status == 201) {
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[newName] = { ...devPages[page] };
          devPages[newName].code = pageCode;
          devPages[page] = undefined;
          return { ...site, devPages };
        });
        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[page] = undefined;
          return newStates;
        });
        if (searchParams.get("page") === page) {
          setSearchParams({ page: newName });
        }
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      // setSitesError(err.mesaage);
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const exportPageCode = async () => {
    if (siteRecord.subdomain) {
      try {
        const resp = await axiosPrivate.get(
          `${process.env.REACT_APP_SITE_PUBLISH_URL.replace(
            "://",
            `://${siteRecord.subdomain}.`
          )}/${page === "/" ? "" : page}`
        );

        if (resp.data) {
          const blob = new Blob([resp.data], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${page === "/" ? "index" : page}.html`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        if (err.response.status === 404) {
          console.log(
            "Page is not published yet. Please publish page to download"
          );
        } else {
          console.log(err);
        }
      }
    } else {
      console.log("Page not published yet.");
    }
  };

  const [scriptBox, setScriptBox] = useState(false);

  return (
    <div
      className={`relative text-zinc-400 text-xs w-full rounded-lg group ${
        page === "/" && "order-[-1]"
      } ${
        (page === "/" && !searchParams.get("page")) ||
        page === searchParams.get("page")
          ? "bg-zinc-900"
          : ""
      } ${initRename && "border border-lime-600"}`}
    >
      {
        <ScriptBox
          page={page}
          scriptBoxAppear={scriptBox}
          setScriptBoxAppear={setScriptBox}
        />
      }
      <div
        onClick={() => setSearchParams({ page })}
        className="py-3 px-4 flex items-center justify-between"
      >
        {page === "/" ? (
          <div className="flex items-center gap-2">
            <GoHomeFill className="w-3 h-auto" />
            Home
          </div>
        ) : initRename ? (
          <div className="flex items-center gap-2">
            <FaFile className="w-3 h-auto" />
            /
            <input
              ref={inputRef}
              type="text"
              value={newName}
              onChange={handleInputChange}
              placeholder="new-page-path"
              className="bg-transparent outline-none placeholder:text-zinc-500"
              onBlur={() => {
                setInitRename(false);
                setNewName("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") renamePage(newName);
              }}
            />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <FaFile className="w-3 h-auto" />
            <span className="w-32 whitespace-nowrap overflow-clip overflow-ellipsis">
              /{page}
            </span>
          </div>
        )}
        <div className="relative w-4 h-4">
          {isUpdating ? (
            <AiOutlineLoading3Quarters className="w-4 h-auto animate-spin" />
          ) : (
            <BsThreeDots
              onClick={(e) => {
                e.stopPropagation();
                setOptionDropdownPage(page);
              }}
              className="opacity-0 group-hover:opacity-60 hover:opacity-100 hover:cursor-pointer  w-4 h-auto"
            />
          )}
          {console.log("siteR123ecord", siteRecord)}
          <DialogBox
            appear={optionDropdownPage === page}
            setAppear={() => {
              setOptionDropdownPage(null);
            }}
          >
            <div className="absolute left-[-75px] bg-zinc-900 p-2 rounded-xl w-32 z-50">
              <div className="flex flex-col">
                <button
                  onClick={(e) => {
                    setSettingsBoxAppear(true);
                    setOptionDropdownPage(false);
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 rounded-lg text-white duration-200"
                >
                  Settings
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOptionDropdownPage(false);
                    createPageCopy();
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 rounded-lg text-white duration-200"
                >
                  Create Copy
                </button>
                <button
                  disabled={page === "/"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInitRename(true);
                    setOptionDropdownPage(false);
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 disabled:text-zinc-500 disabled:hover:bg-zinc-900 rounded-lg text-white duration-200"
                >
                  Rename
                </button>
                <button
                  disabled={page === "/"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setToDel(true);
                    setOptionDropdownPage(false);
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 disabled:text-zinc-500 disabled:hover:bg-zinc-900 rounded-lg text-white duration-200"
                >
                  Delete
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setScriptBox(true);
                    setOptionDropdownPage(false);
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 disabled:text-zinc-500 disabled:hover:bg-zinc-900 rounded-lg text-white duration-200"
                >
                  Insert Script
                </button>
                <button
                  disabled={user?.roles?.Pro ? !siteRecord.subdomain : true}
                  onClick={(e) => {
                    e.stopPropagation();
                    exportPageCode();
                    setOptionDropdownPage(false);
                  }}
                  className="pl-4 p-2 text-left hover:bg-lime-600 disabled:text-zinc-500 disabled:hover:bg-zinc-900 rounded-lg text-white duration-200"
                >
                  Export Code
                </button>
              </div>
            </div>
          </DialogBox>
        </div>
      </div>
      {toDel && (
        <div className="bg-red-950 text-white absolute top-0 left-0 w-full h-full rounded-lg flex justify-between items-center py-3 px-4">
          <div>Delete?</div>
          <div className="space-x-4">
            <button onClick={() => deleteThisPage()}>Yes</button>
            <button onClick={() => setToDel(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
