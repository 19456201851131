import React, { useState } from 'react';
import Fill from './Fill';
import Border from './Border';
import Radius from './Radius';
import Padding from './Padding';
import Margin from './Margin';
import Width from './Width';
import Height from './Height';
import MinWidth from './MinWidth';
import MinHeight from './MinHeight';
import PropOpen from './PropOpen';

const Styles = () => {
  const [open, setOpen] = useState(true);
  return (
    <PropOpen title={"Styles"} open={open} setOpen={setOpen}>
      <Padding/>
      <Margin/>
      <Width/>
      <MinWidth/>
      <Height/>
      <MinHeight/>
      <Fill />
      <Border />
      <Radius/>
    </PropOpen>
  )
}

export default Styles