import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext'
import { SpaceContext } from 'react-zoomable-ui';
import AiInputSelected from '../comps/ChatInputSelected';

const SelectionOverlay = () => {
  const {selectedState, selectedComponentRect, pageState} = useContext(CanvasContext);
  const context = useContext(SpaceContext);
  const [translatedRect, setTranslatedRect] = useState({});

  useEffect(()=>{
    if (selectedComponentRect) {
      setTranslatedRect(context.viewPort.translateClientRectToVirtualSpace(selectedComponentRect));
    }
  }, [selectedComponentRect])
  return (
    <div>
      {(selectedState?.key != pageState?.key && selectedComponentRect) && (
        <div style={{pointerEvents: "none", position: "absolute", top: `${translatedRect.top}px`, left: `${translatedRect.left}px`}}>
          <div className="border border-red-500 flex items-center" style={{position: "relative", width:`${translatedRect.width}px`, height: `${translatedRect.height}px`}}>
            <div className="px-1 w-fit text-[6px] bg-red-500 text-white font-semibold flex justify-center items-center" style={{position: "absolute", top: `-11px`, left: `-1px`}}>
              {selectedState.key.split("_")[0]}
            </div>
            <div style={{pointerEvents: "auto"}} className={`absolute left-[${translatedRect.width+2}px] flex gap-1 items-center`}>
              <AiInputSelected/>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectionOverlay