import React, { useContext, useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import CanvasContext from "../../../../context/CanvasContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FiSearch } from "react-icons/fi";
const gfonts = require("google-fonts-complete");


const ThemeFonts = () => {
  const {hFont, setHFont, bFont, setBFont, siteRecord} = useContext(CanvasContext);
  
  const [headingFontsAppear, setHeadingFontsAppear] = useState(false);
  const [bodyFontsAppear, setBodyFontsAppear] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const fontListFull = Object.keys(gfonts).map(font => {return {name: font, value: `'${font}', ${gfonts[font].category}`}});
  const [fontListFiltered, setFontListFiltered] = useState(fontListFull);

  useEffect(()=>{
    setFontListFiltered(fontListFull.filter(font => font.name.toLowerCase().includes(searchStr.toLowerCase())))
  },[searchStr])

  const clearSearchString = () => {
    setSearchStr("");
  }


  const setFontProperty = (fontName, fontValue) => {
    document.documentElement.style.setProperty(`--${fontName}-font`, fontValue);
  };

  useEffect(()=>{
    if (hFont) {
      setFontProperty('heading', hFont);
      loadFontInApp(hFont);
    }
  },[hFont])
  useEffect(()=>{
    if (bFont) {
      setFontProperty('body', bFont);
      loadFontInApp(bFont);
    }
  },[bFont])

  const axiosPrivate = useAxiosPrivate();
  const handleHeadingFontChange = async (font) => {
    try {
      const resp = await axiosPrivate.patch("/website", {id: siteRecord._id, headingFont: font});
    } catch (err) {
      console.log(err);
    }
    setHFont(font);
  }
  const handleBodyFontChange = async (font) => {
    try {
      const resp = await axiosPrivate.patch("/website", {id: siteRecord._id, bodyFont: font});
    } catch (err) {
      console.log(err);
    }
    setBFont(font);
  }

  const loadFontInApp = (fontValue) => {
    const fontName = String(fontValue.split(",")[0]).replaceAll("\'","");
    const weights = Object.keys(gfonts[fontName].variants.normal);
    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.split(" ").join("+")}:wght@${weights.join(';')}&display=swap`;
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    link.crossOrigin="anonymous";
    document.head.appendChild(link);
  };

  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>Fonts</p>
      <div className="col-span-2 relative gap-2 flex flex-col">
        <div className="w-full flex gap-2">
          <button className="propUnitBox w-1/2 font-heading text-lg font-semibold flex justify-center items-center" onClick={()=>setHeadingFontsAppear(!headingFontsAppear)}>
            Aa
          </button>
          <button className="propUnitBox w-1/2 font-body text-base flex justify-center items-center" onClick={()=>setBodyFontsAppear(!bodyFontsAppear)}>
            Aa
          </button>
        </div>

        <DialogBox appear={headingFontsAppear} setAppear={setHeadingFontsAppear} cb={clearSearchString}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 z-50 max-h-48 rounded-lg flex flex-col items-center'>
          <div className="flex items-center border-b border-zinc-800 pt-1">
              <FiSearch className="w-3 h-auto text-zinc-600"/>
              <input value={searchStr} onChange={(e)=>setSearchStr(e.target.value)} className=" w-[100px] px-2 py-1 flex bg-zinc-900 outline-none  focus:border-lime-600 placeholder:text-zinc-600 placeholder:text-sm" placeholder="Search" />   
            </div>
            <div className="flex flex-col w-full mt-2 overflow-y-auto px-1">
              {
                fontListFiltered.map(ifont => 
                  <button
                    key={ifont.name}
                    className={`text-white text-xs duration-200 hover:bg-lime-600 cursor-pointer hover:text-white ${hFont === ifont.value && 'bg-lime-600 text-zinc-200 -order-last'} pl-4 p-2 w-full justify-left text-left flex items-center gap-2 text-sm rounded-lg`}
                    style={{fontFamily: ifont.value}}
                    onClick={()=>handleHeadingFontChange(ifont.value)}
                  >
                    {ifont.name}
                  </button>
                )
              }
            </div>
          </div>
        </DialogBox>
        <DialogBox appear={bodyFontsAppear} setAppear={setBodyFontsAppear} cb={clearSearchString}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 z-50 max-h-48 rounded-lg flex flex-col items-center'>
            <div className="flex items-center border-b border-zinc-800 pt-1">
              <FiSearch className="w-3 h-auto text-zinc-600"/>
              <input value={searchStr} onChange={(e)=>setSearchStr(e.target.value)} className=" w-[100px] px-2 py-1 flex bg-zinc-900 outline-none  focus:border-lime-600 placeholder:text-zinc-600 placeholder:text-sm" placeholder="Search" />   
            </div>
            
            <div className="flex flex-col w-full mt-2 overflow-y-auto px-1">
              {
                fontListFiltered.map(ifont => 
                  <button
                    key={ifont.name}
                    className={`text-white text-xs duration-200 hover:bg-lime-600 cursor-pointer hover:text-white ${bFont === ifont.value && 'bg-lime-600 text-white -order-last'} pl-4 p-2 w-full justify-left text-left flex items-center gap-2 text-sm rounded-lg`}
                    style={{fontFamily: ifont.value}}
                    onClick={()=>handleBodyFontChange(ifont.value)}
                  >
                    {ifont.name}
                  </button>
                )
              }
            </div>
            
          </div>
        </DialogBox>
      </div>
    </>
  );
};

export default ThemeFonts;
