import React, { useCallback, useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { FaAngleDown } from "react-icons/fa6";
import classNames from 'classnames';

import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';
import DialogBox from './DialogBox';
import { classesToObj } from '../../../../helpers/helpers';
import { useRecoilValue } from 'recoil';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';


const Width = () => {
  const {selectedState, selectedCompStyle} = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [widthType, setWidthType] = useState('');
  const [width, setWidth] = useState('');
  const [tailwindDropdown, setTailwindDropdown] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const [customType, setCustomType] = useState('fixed');
  const [customWidth, setCustomWidth] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  
  
  const tailwindWidthClasses = [
    "w-auto", "w-full", "w-screen", "w-fit", "w-min", "w-max", "w-0", "w-px", "w-0.5","w-1",
    "w-1.5", "w-2", "w-2.5", "w-3", "w-3.5", "w-4", "w-5", "w-6", "w-7", "w-8",
    "w-9", "w-10", "w-11", "w-12", "w-14", "w-16", "w-20", "w-24", "w-28", "w-32", 
    "w-36", "w-40", "w-44", "w-48", "w-52", "w-56", "w-60", "w-64", "w-72", "w-80",
    "w-96", "w-1/2", "w-1/3", "w-2/3", "w-1/4", "w-2/4", "w-3/4", "w-1/5",
    "w-2/5", "w-3/5", "w-4/5", "w-1/6", "w-2/6", "w-3/6", "w-4/6", "w-5/6", "w-1/12",
    "w-2/12", "w-3/12", "w-4/12", "w-5/12", "w-6/12", "w-7/12", "w-8/12", "w-9/12", 
    "w-10/12", "w-11/12",  "w-svw", "w-lvw", "w-dvw"
  ];
  const tailwindWidthClassesResetObj = tailwindWidthClasses.reduce((prev, cls) => {
    const curr = {...prev};
    curr[cls] = false;
    return curr;
  }, {});

  useEffect(()=>{
    const customWidth = state.style?.width;
    if (parseInt(customWidth) >= 0) {
      setWidthType('custom');
      if (customWidth.endsWith("%")) {
        setCustomType('rel');
      } else {
        setCustomType('fixed');
      }
      console.log(selectedCompStyle?.width);
      setCustomWidth(parseInt(customWidth));
    } else {
      const classNameObj = classesToObj(state.className)
      let found = '';
      tailwindWidthClasses.forEach((cls)=> {
        if (classNameObj[cls]) {
          found=cls;
        }
      })
      if (found) {
        setWidthType('tailwind');
        setWidth(found);
      } else {
        setWidthType('');
        setWidth('');
      }
      if (parseInt(selectedCompStyle.width) >=0) {
        setCustomWidth(parseInt(selectedCompStyle.width));
      }
      // ignoring w-[value]
    }
  },[state, selectedCompStyle])

  const updateState =  (width, widthType)=>{
    if (widthType === 'tailwind') {
      setState((prevState) => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bw-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindWidthClassesResetObj};
        classObj[width] = true;
        return {...prevState, className: classNames(classObj), style:{...prevState.style, width:undefined}}
      });
    } else if (widthType === 'custom') {
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bw-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindWidthClassesResetObj}; // removing all other width classes
        return {...prevState, style: {...prevState.style, width: `${width}${customType==='rel'? '%':'px'}`}, className: classNames(classObj)}
      })
    } else { // remove width setting
      setState(prevState => {
        const prevClassName = (prevState.className).replace(/(?<!-)\bw-\[[^\]]+\]\s?/g, ''); // removing any class with pattern w-[value]
        const classObj = {...classesToObj(prevClassName), ...tailwindWidthClassesResetObj}; // removing all other width classes
        return {...prevState, style: {...prevState.style, width: undefined}, className: classNames(classObj)};
      })
    }
  }


  return (
    <>
      <p className='flex items-center text-zinc-400 text-xs w-16 h-8'>Width</p>
      <div className="col-span-2 flex flex-col gap-0 relative h-8">
        <button 
          className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full pl-3 pr-2"
          onClick={(e)=>{setTailwindDropdown(!tailwindDropdown)}}
        >
          <span className='h-8 flex items-center flex-grow text-left'>
            {widthType === '' && <span className="text-zinc-500">Select</span>}
            {widthType !== '' &&
            <div className='flex items-center justify-between pr-1 gap-1 w-full group'>
              <span>{widthType==='tailwind'? width : 'custom'}</span>
              <RxCross2 className='w-3 h-3 rounded-full bg-zinc-600 hidden group-hover:block' onClick={(e)=>{e.stopPropagation(); setWidthType('');setWidth('');setCustomWidth(0);updateState(0, '')}}/>
            </div> 
            }
          </span>
          <FaAngleDown className='w-3 h-3'/>
        </button>
        <DialogBox appear={tailwindDropdown} setAppear={setTailwindDropdown}>
          <div className='absolute bg-zinc-900 text-zinc-400 w-full text-sm py-0 mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center'>
            <button onClick={()=>{setWidthType('custom');setTailwindDropdown(false)}} className={`hover:bg-zinc-700 ${width == 'custom' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 `}>
              custom
            </button>
            {
              tailwindWidthClasses.map(widthCls => 
                <button
                  key={widthCls}
                  className={`hover:bg-zinc-700 ${width == widthCls && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2`}
                  onClick={()=>{updateState(widthCls, 'tailwind')}}
                >
                  {widthCls}
                </button>
              )
            }
          </div>
        </DialogBox>
      </div>
      {widthType === 'custom' &&
        <>
          <div></div>
          <div className='col-span-2 flex items-center gap-2 w-full'>
            <div className="h-8 flex flex-col text-white propUnitBox w-1/2">
              <button 
                className="flex justify-center items-center text-zinc-400 propUnitBox text-sm w-full px-1"
                onClick={(e)=>{setCustomDropdown(!customDropdown)}}
              >
                <span className='h-8 flex items-center flex-grow text-left pl-1 capitalize'>
                  {customType}
                </span>
                <FaAngleDown className='w-3 h-3'/>
              </button>
              <DialogBox appear={customDropdown} setAppear={setCustomDropdown}>
                <div className='absolute bg-zinc-900 text-zinc-400 text-sm py-0 mt-1 max-h-32 overflow-y-auto rounded-md flex flex-col items-center z-50 border border-zinc-800'>
                  <button onClick={()=>{setCustomType('fixed'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'fixed' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    fixed
                  </button>
                  <button onClick={()=>{setCustomType('rel'); setCustomDropdown(false)}} className={`hover:bg-zinc-700 ${customType == 'rel' && 'bg-zinc-800'} rounded-md px-3 py-1 w-full justify-left flex items-center gap-2 capitalize`}>
                    rel
                  </button>
                </div>
              </DialogBox>
            </div>
            <div className={"h-8 flex items-center text-white propUnitBox w-1/2 pl-1"+`${isFocused? ' border border-indigo-600' : ''}`}>
              <input type="text" className='w-3/4 bg-transparent outline-none h-3 px-1 text-xs tracking-widest text-zinc-400'
                value={customWidth}
                onChange={(e)=>{
                  if(e.target.value) {
                    updateState(parseInt(e.target.value), 'custom');
                  } else {
                    updateState(0, 'custom');
                  }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}  
              />
              <div className='flex flex-col gap-0 justify-center items-center h-5'>
                <RiArrowUpSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{updateState(customWidth+1,'custom')}} />
                <RiArrowDownSFill className='w-3 h-3 text-zinc-500 hover:text-zinc-300' onClick={()=>{if (customWidth > 0) updateState(customWidth-1,'custom')}} />
              </div>
            </div>
          </div>
        </> 
      }
      
    </>
  )
}

export default Width