import React, { useContext, useState } from 'react'
import { RiLogoutCircleRLine } from 'react-icons/ri';
import DialogBox from './DialogBox';
import { useLogout } from '../../../../hooks/useLogout';
import UserContext from '../../../../context/UserContext';
import { MdSettings } from "react-icons/md";
import CanvasContext from '../../../../context/CanvasContext';

const UserAvatarMenu = () => {
  const {isUserLoading, userNameAbbr} = useContext(UserContext);
  const {setSettingsBoxAppear} = useContext(CanvasContext);
  const logOut = useLogout();
  const [accMenuAppear, setAccMenuAppear] = useState(false);
  const makeAccountMenuAppear = (e)=> {
    setAccMenuAppear(prev => !prev);
  }
  
  return (
    <>
      {isUserLoading?
        <div className="bg-zinc-700 rounded-full w-8 h-8 animate-pulse"></div>
        :
        <>
          <button
            onClick={makeAccountMenuAppear}
            className={`bg-lime-600/80 duration-200 rounded-full text-white font-bold text-xs w-8 h-8 flex justify-center items-center hover:bg-lime-500 duration uppercase`}
          >
            {userNameAbbr? userNameAbbr : "U"}
          </button>
          <DialogBox appear={accMenuAppear} setAppear={setAccMenuAppear}>
            <div className='absolute bg-zinc-800 text-white text-[13px] overflow-hidden mt-2 w-40 p-2 rounded-xl flex flex-col items-center'>
              <button onClick={()=>{setSettingsBoxAppear(true);setAccMenuAppear(false)}} className='hover:bg-lime-600 hover:text-white duration-200 px-2 py-2 rounded-lg w-full justify-left flex items-center gap-2'>
                <MdSettings className='w-4 h-auto '/> Settings
              </button>
              <button onClick={logOut} className='hover:bg-lime-600 hover:text-white duration-200 px-2 py-2 rounded-lg w-full justify-left flex items-center gap-2'>
                <RiLogoutCircleRLine className='w-4 h-auto'/>
                Logout
              </button>
            </div>
          </DialogBox>
        </>
      }
      
    </>
  )
}

export default UserAvatarMenu